import React from 'react'
import NotificationsController from '../../../NotificationsController';
import PayedComisionhHelper from '../../../../helpers/reports/reports.helper'
import TableController from '../../../reports.ReactTableController';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class payedComisionReport extends React.Component{
    constructor(props){
    super(props);
    
    var dateObj = new Date();
    var dateString = ''.concat(dateObj.getFullYear().toString(),'-',String(dateObj.getMonth() + 1).padStart(2, '0'), '-',String(dateObj.getDate()).padStart(2, '0'));

    this.state = {
        PayedComisionData : [],
        InitialDate :  dateString,
        FinalDate :  dateString,
        mensajeTabla : ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.searchPayedComisionData = this.searchPayedComisionData.bind(this);
    }
    componentDidMount(){
        this.PayedComisionData();
    }
    async handleChange(event) {
        event.preventDefault();
        this.setState({
           [event.target.name]: event.target.value
         });
      }

    async searchPayedComisionData(event){
        event.preventDefault();
        this.PayedComisionData();
    }
    async PayedComisionData() {
        const initialDate = this.state.InitialDate
        const finalDate = this.state.FinalDate
        try{
            this.setState({
                mensajeTabla : 'Cargando . . .'
            });
            const PayedComisionResponse = await new PayedComisionhHelper().getVendorCommisions(localStorage.token,initialDate, finalDate);
            if(PayedComisionResponse.data.length === 0)
            {
                this.setState({
                    mensajeTabla : 'No hay datos para mostrar'
                });
            }
            else 
            {
            this.setState({
                InitialDate :initialDate,
                FinalDate : finalDate,
                PayedComisionData : PayedComisionResponse.data,
                mensajeTabla : ''
            });
        }
            new NotificationsController().createNotification(PayedComisionResponse.data.message,PayedComisionResponse.status.toString())
        }catch(err){
            new NotificationsController().createNotification(err.response.data.message,err.response.status.toString() );
        }
    }
    displaymensaje(){
        if (this.state.mensajeTabla === "Cargando . . ."  ){
            return(<div className="spinner-border spinner-border-sm" role="status" />);
        }
        else{
           return( <h3>{this.state.mensajeTabla}</h3>);
        }
    }
    setView (){
        if( this.state.mensajeTabla.length > 0 )
        {return (
                    <div className="d-flex justify-content-center aling-items-center ">
                    {this.displaymensaje()}
                    </div>
               );}
        else
      return(  <TableController  data = {this.state.PayedComisionData} 
                Header = {"Busqueda de comisiones"} 
                columns = {
                            [
                                {
                                    Header: 'Vendedor codigo',
                                    accessor: 'vendor.code',
                                },
                                {
                                    Header: 'Vendedor nombre',
                                    accessor: 'vendor.firstName',
                                },
                                {
                                    Header: 'Vendedor apellido',
                                    accessor: 'vendor.lastName',
                                },
                                {
                                    Header: 'Folio pago',
                                    accessor: 'paymentCode',
                                },
                                {
                                    Header: 'Fecha de pago',
                                    accessor: 'paymentDate',
                                },
                                {
                                    Header: 'Codigo terreno',
                                    accessor: 'landCode',
                                },
                                {
                                    Header: 'Comision',
                                    accessor: 'commissionQuantity',
                                },
                                {
                                    Header: 'Balance actual',
                                    accessor: 'actualBalance',
                                }
                                ] } 

                                ></TableController>);
    }
    render(){
        return (
            <main className = "content">
            <div className="container-fluid p-0">
                
            <div className="card">
            <div className="card-header">
       <h5 className="card-title">Comisiones confirmadas</h5>
     </div>
            <div className="card-body">
            <form >
               <div className="form-row">
         
               <div className="form-group col-md-3">
                   <label className="form-label">Fecha inicial</label>
                   <input name="InitialDate" type="date" className="form-control datetimepicker-input" value={this.state.InitialDate} onChange = {this.handleChange}/>        
               </div> 
               <div className="form-group col-md-3">
                   <label className="form-label">Fecha final</label>
                   <input name="FinalDate" type="date" className="form-control datetimepicker-input" value={this.state.FinalDate} onChange = {this.handleChange}/>
               </div> 

               <div className="form-group col-md-6">
                        <div className = "form-group">
               <div className="vertical-center">
               <a className="btn btn-primary" onClick={ this.searchPayedComisionData}>	
                    <i className="align-middle mr-2 fas fa-search" data-feather="sliders" />{' '}
                    <span className="align-middle">Buscar</span></a>
               <ExcelDownloadButton PayedComisionData = {this.state.PayedComisionData}></ExcelDownloadButton>

               </div>  </div> 
               </div>  
               </div> 
           </form>

           {this.setView()} 
                                </div>
                                </div>
                              </div>
                              </main>
        );
    }

} export default payedComisionReport



class ExcelDownloadButton extends React.Component {

    render() {
       
        return (

            <ExcelFile filename = "Comisiones pagadas" element={
            <a className="btn btn-success">
            <i className="align-middle mr-2 fas fa-file-excel" data-feather="sliders" />{' '}
					<span className="align-middle">Descargar</span></a>}>


                <ExcelSheet data={this.props.PayedComisionData} name="Comisiones">
                    <ExcelColumn label="Codigo vendedor" value="vendorCode"/>
                    <ExcelColumn label="Folio pago" value="paymentCode"/>
                    <ExcelColumn label="Fecha de pago" value="paymentDate"/>
                    <ExcelColumn label="Codigo terreno" value="landCode"/>
                    <ExcelColumn label="Comision" value="commissionQuantity"/>
                    <ExcelColumn label="Balance actual" value="actualBalance"/>
                </ExcelSheet> 
            </ExcelFile>
        );
    }
}
