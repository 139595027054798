import axios from 'axios';
import constants from '../../config/constants';

class AuthenticateHelper {
	async makeCall(method, url, data, token = null) {
		return axios({
			method,
			url,
			data,
			headers: {
				'x-access-token': token
			}
		});
	}

	async loginUser(body) {
            const method = 'POST';
            const url = `${constants.BACKEND_URL}/authenticate`;
            return this.makeCall(method, url, body, {});
	}

	async verifyUser(token) {
		const method = 'GET';
		const url = `${constants.BACKEND_URL}/verifyUser`;
		return this.makeCall(method, url, {}, token);
	};
}

export default AuthenticateHelper;
