import React from 'react';
class landForm extends React.Component {
    constructor(props){
        super (props);
        this.state = {
            lots : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
            blocks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
            statusDisabled: ["Apartado", "Vendido", "Pagado","Cancelado"],
            statusEnabled : ["Disponible","Reservado" ]
        }
    }
      
    render(){
        const { formErrors } = this.props;
    return (
        <main className="content">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                        <div className="card-header">
                            <h1 className="card-title">Creacion de terrenos</h1>
                            {/* <h6 className="card-subtitle text-muted">Bootstrap column layout.</h6> */}
                        </div>

                        <div className="card-body">
                        <form onSubmit={this.props.handleSubmit}>
                        
                        <div className="form-row">
                        <div className="form-group col-md-3">
                            <label> Codigo: </label>

                            <input 
                                type="text" 
                                className= {formErrors.code.length > 0 ? "form-control is-invalid" :"form-control" }
                                placeholder="Codigo de terreno " 
                                value={this.props.code} 
                                name="code" 
                                onChange={this.props.handleChange} />
                                 {formErrors.code.length > 0 && (<span className="text-danger">{formErrors.code}</span>)}
                           </div>

                           <div className="form-group col-md-3">
                                <label> Manzana: </label>
                                
                                <select  className={formErrors.block.length > 0 ? "form-control is-invalid" : "custom-select mb-3"}   name="block"  value={this.props.block} onChange={this.props.handleChange} >
                                    <option value = {0} selected>Seleccione una manzana</option>
                                    {this.state.blocks.map((block) => <option> { block} </option>) }
                                </select>
                                {formErrors.block.length > 0 && (<span className="text-danger">{formErrors.block}</span>)}
                            </div>

                            <div className="form-group col-md-3">
                                <label> Lote: </label>

                                <select  className={formErrors.lot.length > 0 ? "form-control is-invalid" : "custom-select mb-3"}  name="lot"  value={this.props.lot} onChange={this.props.handleChange} >
                                    <option value = {0} selected>Seleccione un lote</option>
                                    {this.state.lots.map((lot) => <option> { lot} </option>) }
                                </select>
                                {formErrors.lot.length > 0 && (<span className="text-danger">{formErrors.lot}</span>)}
                            </div>

                            <div className="form-group col-md-3">
                                <label> Estatus: </label>

                                <select  className= "custom-select mb-3"  name="status"  value={this.props.status} onChange={this.props.handleChange} >
                                        <optgroup label="Disponibles para cambio">
                                        {this.state.statusEnabled.map((statu) => <option > { statu} </option>) }
                                        </optgroup>
                                        <optgroup label="No disponibles para cambio">
                                        {this.state.statusDisabled.map((statu) => <option disabled> { statu} </option>) }
                                        </optgroup>
                                    </select>
                                  
                                </div>
                            </div>

                         <div className="form-row">
                         {/* <div className="form-group col-md-2">
                            <label> Largo: </label>
                                
                            <input 
                                type="number" 
                                className= {formErrors.height.length > 0 ? "form-control is-invalid" :"form-control" }
                                name="height" 
                                value={this.props.height} 
                                onChange={this.props.handleChange} />
                                  {formErrors.height.length > 0 && (<span className="text-danger">{formErrors.height}</span>)}
                        </div>

                        <div className="form-group col-md-2">
                            <label> Ancho:</label>
                            
                            <input 
                                type="number" 
                                className= {formErrors.width.length > 0 ? "form-control is-invalid" :"form-control" }
                                name="width" 
                                value={this.props.width} 
                                onChange={this.props.handleChange} />
                                {formErrors.width.length > 0 && (<span className="text-danger">{formErrors.width}</span>)}
                        </div> */}
                       
                        <div className="form-group col-md-2">
                            <label> Precio por M2: </label>
                            
                            <input
                                type="number"
                                className= {formErrors.pricePerM2.length > 0 ? "form-control is-invalid" :"form-control" }
                                name="pricePerM2"
                                value={this.props.pricePerM2}
                                onChange={this.props.handleChange}/>
                                {formErrors.pricePerM2.length > 0 && (<span className="text-danger">{formErrors.pricePerM2}</span>)}
                        </div>
                              
                        <div className="form-group col-md-2">
                            <label> Superficie M2: </label>
                        
                            <input 
                                type="number" 
                                className="form-control" 
                                name="surfaceM2" 
                                value= {(this.props.surfaceM2 * 1).toFixed(2)}
                                onChange = {this.props.handleChange}
                                />
                                
                        </div>

                        <div className="form-group col-md-2">
                            <label> Costo total: </label>

                            <div className="input-group mb-3">
						    <div className="input-group-prepend">
						        <span className="input-group-text">$</span>
						    </div>
                            <input
                                type="number"
                                className="form-control" 
                                name="totalPrice"
                                value={(this.props.pricePerM2 * (this.props.surfaceM2)).toFixed(2)}
                                onChange = {this.props.handleChange}
                                disabled/>
						    </div>
						</div>

                        </div>
                            <button type="submit" className="btn btn-primary">Agregar</button>
                        </form>
                        <div>
                     </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
    );}
}

export default landForm;