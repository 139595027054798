import React from 'react';
import ClientForm from './clientForm';
import ClientHelper from '../../helpers/client/client.request';
import NotificationsController from '../NotificationsController';

class editClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          id : this.props.id,
            code: this.props.code,
            firstName: this.props.firstName,
            lastName : this.props.lastName,
            email : this.props.email,
            rfc : this.props.rfc,
            zipCode: this.props.zipCode,
            phoneNumber :this.props.phoneNumber,
            address : this.props.address,
            formErrors:  {
              code: '',
              firstName: '',
              lastName: '',
              email: '',
              rfc: '',
              zipCode: '',
              phoneNumber: ''
            }
         
          };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async handleChange(event) {
        this.setState({
           [event.target.name]: event.target.value
         });
      }

    async handleSubmit(){
  
        try {
            const body = {
                id  : this.state.id,
                code: this.state.code,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                rfc: this.state.rfc,
                zipCode: this.state.zipCode,
                phoneNumber: this.state.phoneNumber,
                address : this.state.address
              };
           
       const response =  await new ClientHelper().editClient(body, localStorage.token);
            new NotificationsController().createNotification( response.data.message, response.status.toString());
        } catch (err) {
            throw new Error(err.message);
          }
    }
    
    render() {
		return (
        
            <ClientForm  handleSubmit={this.handleSubmit} handleChange ={this.handleChange}
                      
                         code ={this.state.code}
                         firstName  = {this.state.firstName}
                         lastName = {this.state.lastName}
                         email = {this.state.email}
                         rfc = {this.state.rfc}
                         zipCode= {this.state.zipCode}
                         phoneNumber={this.state.phoneNumber}
                         formErrors = {this.state.formErrors}
                         address = {this.state.address}
            > </ClientForm>);
     
        }
}

export default editClient;