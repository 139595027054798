import React from 'react';
import DrawHelper from '../../helpers/draw/draw.request'
import NotificationsController from '../NotificationsController'
class drawpaymentList extends React.Component{
  constructor(props){
        super(props);
        this.state = {
            land: this.props.land,
      
            drawPayments : [],
            hasError: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleManualPayment = this.handleManualPayment.bind(this);

  }

  async componentDidMount() {
    const drawPaymentResponse = await new DrawHelper().getDrawPayments(localStorage.token, this.state.land);
    this.setState({
        drawPayments : drawPaymentResponse.data,
    });
  }

  async handleManualPayment(){
    try{
        const body = {
            land : this.state.land
        }
        const response = await new DrawHelper().addManualPayment(body, localStorage.token);
        this.state.drawPayments.push(response.data);
        this.setState({});

    }catch(error){
        new NotificationsController().createNotification(error.response.data.message, error.response.status.toString())	
   
    }
  }


   async handleSubmit(drawPayments){
     try {
        const body = {
            id : drawPayments.id,
            amount : drawPayments.amount,
            land : drawPayments.land,
            processType : this.props.processType
        };

        const response = await new DrawHelper().paySavedLand(body, localStorage.token)
        new NotificationsController().createNotification( response.data.message, response.status.toString())
       
        this.componentDidMount();

     } 
    catch(err){
        new NotificationsController().createNotification(err.response.data.message,err.response.status.toString());
    }
   }

   setButtons(){
        if (this.props.lookButtons){
            return (<div className="text-right">
          <button type="submit" className="btn btn-primary" onClick={() => this.handleManualPayment()} >Agregar pago</button> 
          <button type="submit" className="btn btn-success" onClick={() => this.props.handleClosePayments()} >Cerrar apartado</button> 
        </div>)
        }
   }

setView(){
    return ( 
    

            <div className="card">
            <div className="card-header">
                <br></br>
            <div className="row">
                <div className="col-md-6">
                <h5 className="card-title" >Pagos de apartado</h5>
                </div>
                
                <div className="col-md-6">
                    
                    {this.setButtons()}

                </div>
                </div>
            </div>
            <div className="card-body">
                
                    <div className="table-responsive text-nowrap">
            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Pago efectivo</th>
                                        <th>Fecha pago</th>
                                        <th>Fecha vencimiento</th>
                                        <th>Estatus</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    {this.state.drawPayments.map((drawPayment, index) => (
                                        <tr key={drawPayment.id} >
                                         <td>{drawPayment.saveType}</td>
                                         <td>
                                         <input type="text" className="form-control" name="payment" placeholder={drawPayment.payment} value={drawPayment.name} 
                                         onChange = {(e) => {  drawPayment.name = e.target.value; 
                                                             let oldArr = [...this.state.drawPayments]; 
                                                             oldArr[index].payment = e.target.value;
                                                             this.setState({
                                                                drawPayments : oldArr
                                                             })
                                                            }
                                                    } disabled = {(drawPayment.paid)? "disabled" : ""}/> 
                                            </td>
                                         <td>{drawPayment.paymentDate.substring(0,10)}</td>
                                         <td>{drawPayment.expirationDate.substring(0,10)}</td>
                                         <td>
                                            <button
                                            type="submit"
                                            className="btn btn-success btn-sm"
                                             onClick={() => this.handleSubmit({id :drawPayment.id, amount: drawPayment.payment, NoPayment : drawPayment.NoPayment, land : drawPayment.land })}
                                            >
                                            {(drawPayment.paid ? 'Pagado' : 'Presione para pagar')}
                                            </button>
                                         </td>
                                         </tr>
                                    ))}
                                </tbody> 
                            </table>
                            </div>
          
                           
            </div>
            </div>
           );
}
    render()
    {
      return( <div> {this.setView()}</div>)
     
    };
}
export default drawpaymentList;   