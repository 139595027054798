import React from 'react'

class saleContractForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            paymentMethods : ["Pago en una sola exhibicion", "Pago en parcialidades o diferido"],
            recipts: []
        }
    }
  
    render(){
        return(
                <div>
                

                <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Datos generales</h5>
                    {/* <h6 className="card-subtitle text-muted">Bootstrap column layout.</h6> */}
                </div>
                <div className="card-body">
                <form >

                    <div className="form-row">
                        <div className="form-group col-md-3">
                                <label> Terreno </label>
                                {/* value={this.props.block}  */}
                                    <select className="custom-select mb-3" name="land" onChange={this.props.handleLand} value={this.props.land} > 
                                    <option selected>Seleccione un terreno</option> 
                                    {this.props.lands.map((land) => <option value={land.id}> {land.Lands[0].code} </option>) }
                                    </select>
                        </div>

                        <div className="form-group col-md-2">
                        <label >Contrato</label>
                        <input type="text" className="form-control" name="contractNumber" value = {this.props.contractNumber}  onChange = {this.props.handleChange} placeholder="Numero de contrato" />
                        </div>

                        <div className="form-group col-md-2">
                        <label className="form-label">Fecha de venta</label>
                        <input name="creationDate" type="date" className="form-control datetimepicker-input" value={this.props.creationDate}  onChange = {this.props.handleChange}/>
                        
                    </div> 

                    {/* {this.props.showButton ?  null : 
                    <div className="form-group col-md-1">
                        
                     <label>Actualizar</label>
                         <br></br>
                     <a className="btn btn-primary" onClick={ this.props.updateSaleContractDate}>
                     <span className="align-middle">Fecha venta</span></a>
                     </div>
                } */}
                    <div className="form-group col-md-3">
                                <label> Forma de pago </label>
                          
                                    <select className="custom-select mb-3" name="paymentMethod" value = {this.props.paymentMethod} onChange = {this.props.handleChange}  > 
                                  
                                    {this.state.paymentMethods.map((paymentMethod) => <option> {paymentMethod} </option>) }
                                    </select>
                    </div>
                    
                    <div className=  "form-group col-md-2">
                        <label for="monthlyPayment">Monto por mes</label>
                        <input type="text" className="form-control" name="monthlyPayment" value = {this.props.monthlyPayment} onChange = {this.props.handleNumberOfMonths} placeholder="Pago mensual" />
                    </div>

                        </div> 
                   
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label>Vendedor</label>
                            <input type="text" value={this.props.vendor} className="form-control" name="vendor" placeholder="Vendedor" disabled/>
                        </div>
                        <div className="form-group col-md-4">
                            <label >Cliente</label>
                            <input type="text" className="form-control" name="client" value= {this.props.client} placeholder="Cliente" disabled/>
                        </div>
                        <div className="form-group col-md-2">
                            <label>Manzana / Lote</label>
                            <input type="text" className="form-control" name="BlockAndLot" value = {this.props.BlockAndLot} placeholder="Manzana / lote" disabled/>
                        </div>
                        <div className="form-group col-md-2">
                            <label for="surfaceM2">Superficie M2</label>
                            <input type="text" className="form-control" name="surfaceM2" value = {this.props.SurfaceM2} placeholder="SuperficieM2" disabled/>
                        </div>
                        
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-3">
                        <label>Costo terreno</label>
                        <input type="text" className="form-control" name="LandTotalCost" value = {this.props.LandTotalCost} placeholder="Costo total del terreno" disabled/>
                    </div>
                    <div className="form-group col-md-3">
                        <label >Enganche</label>
                        <input type="text" className="form-control" name="Advance" value = {this.props.Advance} placeholder="Enganche" disabled/>
                    </div>
                    <div className="form-group col-md-3">
                        <label for="currentCost">Costo actual</label>
                        <input type="text" className="form-control" name="currentCost" value = {this.props.currentCost} placeholder="Costo actual" disabled/>
                    </div>
                    <div className="form-group col-md-3">
                        <label>Meses</label>
                        <input type="text" className="form-control" name="numberOfMonths" value = {this.props.numberOfMonths} placeholder="total de meses" disabled/>
                    </div>
                    </div>
                   
                    <br></br>
                 
                 {/* <button type="submit" onClick={this.props.handleSubmit} className="btn btn-primary">Agregar</button> */}
              
                    {/* <button  className="btn btn-primary" onClick={ this.searchMonthlyCashData}>Buscar</button> */}
                    <a className="btn btn-primary" onClick={ this.props.handleSubmit}>	
                    {/* <i className="align-middle mr-2 fas fa-search" data-feather="sliders" />{' '} */}
                    <span className="align-middle">Agregar</span></a>
                   
                    
                </form>
                </div>
                </div>
            </div>
            );

    }
}
export default saleContractForm;