import axios from 'axios';
import constants from '../../config/constants';

class LandHelper {
	async makeCall(method, url, data, token = null) {
		return axios({
			method,
			url,
			data, 
			headers: {
				'x-access-token': token
			}
		});
	}

	async addLand(body, token) {
		const method = 'POST';
		const url = `${constants.BACKEND_URL}/land`;
		return this.makeCall(method, url, body, token);
	}

	async getLands(token) {
		const method = 'GET';
		const url = `${constants.BACKEND_URL}/land`;
		return this.makeCall(method, url, {}, token);
	}

	async editLand(body, token){
		const method = 'PUT';
		const url = `${constants.BACKEND_URL}/land`;
		return this.makeCall(method, url, body, token)
	}
	async deleteLand(id, token){
	
		const method = 'DELETE';
		const url = `${constants.BACKEND_URL}/land/${id}`
		return this.makeCall(method, url, {}, token)
	}
}
export default LandHelper;
