import axios from 'axios';
import constants from '../../config/constants';
class extraChargeHelper{
    async makeCall(method, url, data, token = null) {
		return axios({
			method,
			url,
			data, 
			headers: {
				'x-access-token': token
			}
		});
	}
    async addExtraCharge(token, body){
        const method = 'POST';
		const url = `${constants.BACKEND_URL}/extraCharge`;
		return this.makeCall(method, url, body, token);
    }
    async updateExtraCharge(token, id ){
        const method = 'DELETE'
        const url = `${constants.BACKEND_URL}/extraCharge/deleteExtraCharge/${id}`

        return this.makeCall(method, url, {}, token);
    }
	
	async getExtraChargesHeaders(token){
        const method = 'GET'
        const url = `${constants.BACKEND_URL}/extraCharge/extraChargeHeaders`
        return this.makeCall(method, url, {}, token);
    }
    	
	async getExtraChargeDetails(token, land, client){
        const method = 'GET'
        const url = `${constants.BACKEND_URL}/extraCharge/extraChargeDetails/${land}/${client}`
        return this.makeCall(method, url, {}, token);
    }
    	
	async getlLndForClientRelation(token){
        const method = 'GET'
        const url = `${constants.BACKEND_URL}/extraCharge/landForClientRelation`
        return this.makeCall(method, url, {}, token);
    }
    	
	async getAllClients(token){
        const method = 'GET'
        const url = `${constants.BACKEND_URL}/extraCharge/AllClients`
        return this.makeCall(method, url, {}, token);
    }
    async getClientInfo(token, id){
        const method = 'GET'
        const url = `${constants.BACKEND_URL}/client/${id}`
        return this.makeCall(method, url, {}, token);
    }
    async deleteExtraCharges(token, id){
        const method= 'GET'
        const url = `${constants.BACKEND_URL}/extraCharge/deleteExtraCharge/${id}`
        return this.makeCall(method, url, {}, token);

    }
}
export default extraChargeHelper;
