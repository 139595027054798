import axios from 'axios';
import constants from '../../config/constants';

class UserHelper {
	async makeCall(method, url, data, token = null) {
		return axios({
			method,
			url,
			data,
			headers: {
				'x-access-token': token
			}
		});
	}

	async getUsers(token) {
		const method = 'GET';
		const url = `${constants.BACKEND_URL}/user`;
		return this.makeCall(method, url, {}, token);
	}

	async registerUser(body, token) {
		const method = 'POST';
		const url = `${constants.BACKEND_URL}/user`;
		return this.makeCall(method, url, body, token);
	}

	async deleteUser(id, token) {
		const method = 'DELETE';
		const url = `${constants.BACKEND_URL}/user/id`;
		return this.makeCall(method, url, id, token);
	}
}

export default UserHelper;
