import React from 'react'
import NotificationsController from '../NotificationsController';
import MonthlyPaymentsHelper from '../../helpers/monthlyPayments/monthlyPayments.request';
import AddMonthlyPayments from './addMonthlyPayments';
import MonthlyPayment from '../reports.ReactTableController'

class listMonthlyPayments extends React.Component{
constructor(props){
    super(props);
    this.state = {
        monthlyPayments : [],
    }
    this.handleEdit =  this.handleEdit.bind(this);
};

componentDidMount(){
    this.getMonthlyPayments();
}

async handleEdit(cell){
        this.setState({
            land : cell.row.original.land,
            clientName : ''.concat(cell.row.original.Clients.firstName, ' ',cell.row.original.Clients.lastName) ,
            clientAddress : cell.row.original.Clients.address,
            showEdit : true
        });
    }

getMonthlyPayments = async () => {
    try {
       
        const response = await new MonthlyPaymentsHelper().getMonthlyPayments(localStorage.token);
        this.setState({ showEdit: false,
            monthlyPayments : response.data
        });
        new NotificationsController().createNotification("Registro encontrados", "201")
    } catch(error){
        new NotificationsController().createNotification(error.message, "400")
    }
}

setView(){
    if (this.state.showEdit){
      return(<AddMonthlyPayments land = {this.state.land} clientName = { this.state.clientName}
      clientAddress = {this.state.clientAddress} ></AddMonthlyPayments>);

    }
    else 
    {
        if(this.state.monthlyPayments.length === 0)
        {return (<div className="box">
                    <div className="d-flex justify-content-center aling-items-center ">
                    <div className="spinner-border" role="status" />
                    </div>
                </div>);}
        return (
              <main className="content">
        
        <div className="container-fluid p-0">
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                         <br></br>
                        {/* <h4 className="card-subtitle text-muted"></h4> */}
                    </div>
                    <div className="card-body">
            <MonthlyPayment 
                data = {this.state.monthlyPayments} 
                Header = {"Busqueda de mensualidades"} 
                columns = {
                            [
                                {
                                    Header: 'Terreno',
                                    accessor: 'Lands.code',
                                },
                                {
                                    Header: 'Cliente nombre',
                                    accessor: 'Clients.firstName',
                                },
                                {
                                    Header: 'Cliente Apellido',
                                    accessor: 'Clients.lastName',
                                },
                                {
                                    Header: 'Contrato',
                                    accessor: 'contractNumber',
                                },
                                {
                                    Header: 'Pago mensual',
                                    accessor: 'monthlyPayment',
                                },
                               
                                {
                                    Header : 'Accion',
                                    accessor: '[row identifier to be passed to button]',
                                    Cell: ({cell}) => (
                                    <button className="btn btn-primary btn-sm" 
                                    onClick={() =>this.handleEdit(cell)}>Agregar pago
                                    </button>
                                        )
                                }
    
                            ]
                         }
            ></MonthlyPayment>
            </div>
            </div>
                    </div>
                    </div>
                    </div>
                    </main>);
    }

}

    render() {
        return <div> {this.setView()} </div>
    }
}
export default listMonthlyPayments;