import React, { Fragment } from 'react';
import {NotificationContainer} from 'react-notifications';
import AuthenticateHelper from '../helpers/authenticate/authenticate.request';
import Login from './Login';
import Landing from './Landing';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import ListUsers from './ListUsers';
import Register from './Register';
import ListClients from './Clients/listClients'
import AddClient from './Clients/addClient'
import ListLands from './Lands/listLands'
import AddLand from './Lands/AddLands'
import ListDraw from './Draws/ListDraw'
import AddDraw from './Draws/addDraw'
import AddVendor from './Vendors/addVendor'
import ListVendor from './Vendors/listVendor'
import ListSaleContract from './SaleContracts/listSaleContract'
import AddSaleContract from './SaleContracts/addSaleContract'
import AddMonthlyPayments from './MontlyPayments/addMonthlyPayments'
import ListMonthlyPayments from './MontlyPayments/listMonthlyPayments'
import MonthlyPaymentList from './Reports/noUsar_resportlist'
import BankReconciliationForm from './bankReconciliation/bankReconciliationForm'
import VendorCommissionLists from './vendorComisions/vendorComissionList'

import MonthlyPayments from './Reports/ExcelReports/0MonthlyPaymentsResume/MonthlyPaymentResume'
import MonthlyCash from './Reports/ExcelReports/1Banks/monthlyCashList'
import ClientsWBalanceDue from './Reports/ExcelReports/2ClientsWBalanceDue/ClientsWBalanceDueList'
import LandInventory from './Reports/ExcelReports/3LandInventory/LandInventoryList'
import PayedComisions from './Reports/ExcelReports/4PayedComisions/PayedComisionsList'
import MisingPayment from './Reports/ExcelReports/5LandMisingPayment/LandMisingPaymentList'
import Unauthorized from '../401-unauthorized'
import ListExtraChargers from './ExtraChargers/listExtraChargers'
import AddExtraChargers from './ExtraChargers/addExtraChargers'
import ReportTest from './Reports/Cancellations/CancellationReport'
import LandTransferIndex from '../components/LandTransfer/LandTransferIndex'
// import PermissionHelper from '../helpers/permissions/permissions.helper'
import Cancellation from './Cancellations/cancellationList'
import ConfirmModal from './ConfirmModal'
class Main extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			status : 200
		};
		this.verifyUser = this.verifyUser.bind(this);
	}

	async componentDidMount() {
		 await this.verifyUser();
	}

	clickClass()
	{	
		this.refs.landing.clickClass();
	}
	
 async verifyUser() {
		
		if (localStorage.token) {
			const user = await new AuthenticateHelper().verifyUser(localStorage.token);
			this.setState({ user,
				status: user.status
			});
		} else {
			this.setState({ user: null });
		}
	}

	setView() {
	
		if (this.state.status === 200 && localStorage.token) {
			return (
				<Fragment>
					<div className="wrapper">
						
						<Landing ref="landing" />
						
						<div className="main">
							<nav className="navbar navbar-expand navbar-light navbar-bg">
								<a className="sidebar-toggle" onClick={() => this.clickClass()}>
									<i className="hamburger align-self-center" />
								</a>
							</nav>
	
							<Router>
								<Switch>
	
									<Route 
									exact 
									path = "/unauthorized"
									render = {(props) => true ? < Unauthorized /> : null}
									/>
									
									<Route
										exact
										path="/Usuarios"
										render={(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" ? <ListUsers /> : <Redirect to="/unauthorized" /> )}/>
									
									<Route
										exact
										path="/Register"
										render={(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" ? <Register /> : <Redirect to="/unauthorized" /> )}/>
									
									<Route
										exact
										path="/Clientes"
										render={(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" ? <ListClients /> : <Redirect to="/unauthorized" /> )}/>
									
									<Route
										exact
										path="/agregarCliente"
										render={(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" ? <AddClient /> : <Redirect to="/unauthorized" /> )}/>
									
									<Route 
										exact
										path = "/Vendedores"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" ? <ListVendor/> : <Redirect to = "/unauthorized" /> )} />
																
									<Route  
										exact
										path ="/Terrenos"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" ? <ListLands /> : <Redirect to="/unauthorized" /> )}/>
									
									<Route  
										exact
										path ="/agregarTerreno"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista"  ? <AddLand /> : <Redirect to="/unauthorized" /> )}/>
									
									<Route 
										exact
										path = "/agregarVendedor"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista"  ? <AddVendor/>:<Redirect to = '/unauthorized' /> ) } />
								
									{/* new PermissionHelper().getPermission(localStorage.token,"d14cb8f8-a20c-42f4-bea5-6c0f82632d51", 1) */}
									<Route
										exact
										path = "/Apartados"
										render = {(props) => ((localStorage.rol === "Supervisor" || ( localStorage.rol === "Capturista"  ) )? <ListDraw/> : <Redirect to ="/unauthorized" />)}/>
		
									<Route
										exact
										path = "/agregarApartado"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista"  ? <AddDraw/> : <Redirect to ="/unauthorized" />)}/>
								
	
	
									<Route 
										exact
										path = "/Contratos"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista"  ? <ListSaleContract/> : <Redirect to ="/unauthorized" />)}/>
										
									<Route 
										exact 
										path = "/agregarContrato"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista"  ? <AddSaleContract/> : <Redirect to = "/unauthorized" />) }/>
	
									<Route
										exact 
										path = "/Mensualidades"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista"  ? <ListMonthlyPayments/> : <Redirect to = "/unauthorized" /> )}/>
										
									<Route 
										exact
										path = "/addMonthlyPayments"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista"  ? <AddMonthlyPayments /> : <Redirect to = "/unauthorized" />)}/> 
										
									<Route 
										exact
										path = "/MonthlyPaymentList"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista"  ? <MonthlyPaymentList /> : <Redirect to = "/unauthorized" />)}/>
	
									<Route 
										exact 
										path = "/Comisiones"
										render = {(props) => (localStorage.rol === "Supervisor" ? <VendorCommissionLists/> : <Redirect to ="/unauthorized" />)}/>
	
									<Route
										exact 
										path = "/Conciliacion"
										render = {(props) => ( localStorage.rol === "Supervisor"? <BankReconciliationForm /> : <Redirect to = "/unauthorized" />)}/>
								
									<Route 
									exact 
									path = "/CobrosExtra"
									render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista"   ? <ListExtraChargers /> : <Redirect to ="/unauthorized" /> )}/>
								
									<Route
									exact 
									path = "/AgregarCobroExtra"
									render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista"  )? <AddExtraChargers /> : <Redirect to ="/unauthorized" /> }/>

									<Route
										exact
										path = '/ReporteMensualidades'
										render = {(props) => ( localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" || localStorage.rol === "Reporteador" ? <MonthlyPayments />: <Redirect to="/unauthorized" />)} 
									/>
	
									<Route 
										exact 
										path = "/ReporteCorteDeCaja"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" || localStorage.rol === "Reporteador" ? <MonthlyCash/> : <Redirect to="/unauthorized" />)} />
									
									<Route 
										exact 
										path = "/ReporteCobrosVencidos"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" || localStorage.rol === "Reporteador"? <ClientsWBalanceDue/> : <Redirect to="" />)} />
									
									<Route 
										exact 
										path = "/ReporteInventarioDeTerrenos"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" || localStorage.rol === "Reporteador"? <LandInventory/>: <Redirect to="" />)} />
									
									<Route 
										exact 
										path = "/ReporteComisionesConfirmadas"
										render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" || localStorage.rol === "Reporteador"? <PayedComisions />: <Redirect to="" />)} />
									
									<Route
									exact
									path = "/ReporteResiduoDePago"
									render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" || localStorage.rol === "Reporteador" ? <MisingPayment/> : <Redirect to ="" />)} />
									
								 <Route
									exact
									path = "/ReportTest"
									render = {(props) =><ReportTest/> } />	

									<Route 
									exact 
									path = "/Cancelaciones"
									render = {(props) => (localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" || localStorage.rol === "Reporteador" ? <Cancellation/>: <Redirect to ="" />)} />
									<Route
									exact
									path = "/ConfirmModal"
									render = {(props) =><ConfirmModal />}
									/>
									<Route
									exact
									path ="/TransferenciaTerreno"
									render={(props) => <LandTransferIndex/>}
									/>
								</Switch>
							</Router>
	
						</div>
						<NotificationContainer/>
					</div>
				</Fragment>
			);
		
		}
		
		return (
			<div>
				<Login />
			</div>
		);
	
	}

	render() {
		return <Fragment>{this.setView()}</Fragment>;
	}

}

export default Main;
