import React from 'react';
import { Button, Modal } from 'react-bootstrap';
class CristianModal extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
       
      }
    }

    render(){
        return(
            <Modal show={this.props.showModal} as="section">
            <Modal.Header as="span">
            <Modal.Title as="h4">Autorizacion de usuario supervisor </Modal.Title>
             </Modal.Header>
            <Modal.Body as="section"> 
            <form   autoComplete="off">
              <div className="form-group">
                <label>Correo:</label>
                <input
                className="form-control form-control-lg"
                type="email"
                name="email"
                autoComplete="off"
                placeholder="Correo electronico"
                value={this.props.email}
                onChange={this.props.handleChange}
                onKeyDown= { (e) => {
                  if(e.key === 'Enter'){
                     this.props.handleSubmit()
                  }
                }
              }/>
              </div>

              <div className="form-group">
                <label>Contraseña:</label>
                <input
                autoComplete="off"
                className="form-control form-control-lg"
                type="password"
                name="password"
                placeholder="Contraseña"
                value={this.props.password}
                onChange={this.props.handleChange}
                onKeyDown= { (e) => {
                  if(e.key === 'Enter'){
                     this.props.handleSubmit()
                  }
                }
              }
                />
              
              </div>
            </form>

            </Modal.Body>
          <Modal.Footer as="footer">
                <Button type="button" className="btn btn-primary btn-sm" onClick={() => this.props.handleSubmit()}>Accesar</Button>
                <Button type="button" className="btn btn-danger btn-sm" onClick={() => this.props.hideModal()}>Cerrar</Button>
          </Modal.Footer>
      </Modal>
        );
    } 
}
     export default CristianModal;