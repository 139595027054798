import React from 'react'
import Pagination from '../Pagination'
 import MonthlyPaymentReceipt  from '../Reports/MonthlyPaymentReceipt/MonthlyPaymentReceipt'

class paymentReciptsList extends React.Component{
constructor(props){
    super(props)
    this.state = {
         currentPayments : [],
         showEdit : false,
         dremer : ''
    }
   
}
async handlePrint(){
    this.setState({showEdit : true});
    this.setView();
}

    onPageChanged = data => {

    const { payments } = this.props;
  
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentPayments = payments.slice(offset, offset + pageLimit);
  
    this.setState({ currentPage, currentPayments, totalPages });
  }

    render(){
        const { payments, BlockAndLot} = this.props;
        const { currentPayments } = this.state;
        const totalpayments = payments.length;
        
         if (totalpayments === 0) return null;
 
        return(
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Mensualidades pagadas</h5>
            </div>
            <div className="card-body">
            <Pagination totalRecords={totalpayments} pageLimit={10} pageNeighbours={1} onPageChanged={this.onPageChanged} />
            <div className="table-responsive text-nowrap">
                <table className = "table table-sm">
                <thead>
                    <tr>
                        <th>Folio</th>
                        <th>Fecha de pago</th>
                        <th>Metodo de pago</th>
                        <th>Abono</th>
                        <th>En letra</th>
                        <th>Descuento</th>
                        <th>Penalizacion</th>
                        <th>Pago total</th>
                        <th>Saldo previo</th>
                        <th>Saldo actual</th>
                        <th>Modificar</th>
                        <th>Imprimir</th>
                    </tr>
                </thead> 
            <tbody> 
                    {currentPayments.map((payment) => (
                    <tr key={payment.id}>
                    <td>{payment.code}</td>
                    <td>{payment.createdAt.substring(0,10)}</td>
                    <td>{payment.methodOfPayment}</td>
                    <td>{payment.amount}</td>
                    <td>{payment.amountWord}</td>
                    <td>{payment.discount}</td>
                    <td>{payment.penalty}</td>
                    <td>{payment.realAmount}</td>
                    <td>{payment.previousBalance}</td>
                    <td>{payment.actualBalance}</td>
                    {/* <td>{payment.paymentMethod}</td> */}
                    <td><button className="btn btn-success" onClick= {(e) => this.props.modifyReceipts(e, payment.code)} > Modificar</button></td>
               
                    <td> 
                        <MonthlyPaymentReceipt 
                            code = {payment.code}  
                            createdAt = {payment.createdAt.substring(0,10)}
                            amount = {payment.amount } 
                            amountWord = {payment.amountWord} 
                            discount = {payment.discount}
                            penalty = {payment.penalty}
                            realAmount = {payment.realAmount}
                            previousBalance = {payment.previousBalance}
                            actualBalance = {payment.actualBalance}
                            currency = {payment.currency}
                            currencyExchange = {payment.currencyExchange}
                            BlockAndLot = {BlockAndLot}
                            clientName = {this.props.clientName}
                            clientAddress = {this.props.clientAddress}
                            paymentMethod = {payment.paymentMethod}/>
                    </td>
                    </tr>
                    ))}
                    </tbody> 
                </table>
                </div>
            </div>
       
        </div>
)
    }
}
export default paymentReciptsList
