import React from 'react';
import ClientHelper from '../../helpers/client/client.request';
import EditClient from './editClient';
import NotificationsController from '../NotificationsController'
import ReactTable from '../reports.ReactTableController'
class ListClients extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			clients: [], 
			showEdit: false
		};
		// this.handleChange = this.handleChange.bind(this);
		// this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
	}
	
	async handleDelete(id) {
		try {
	
		this.setState({
			clients : this.state.clients.filter(client => client.id !== id)
		});

 	await new ClientHelper().deleteClient(id, localStorage.token);

	//  this.setView();

} catch (error) {
	new NotificationsController().createNotification(error.message, "400")
		}
	}

	componentDidMount() {
		this.getClients();
	}

	handleEdit(clients) {
		
		this.setState({
			showEdit: true,
			id : clients.id,
			code : clients.code,
			firstName : clients.firstName,
			lastName : clients.lastName,
			rfc : clients.rfc,
			email : clients.email,
			zipCode : clients.zipCode,
			phoneNumber : clients.phoneNumber,
			address : clients.address 
		});
	
		 this.setView();
	}

	getClients = async () => {
		try {

			const response = await new ClientHelper().getClients(localStorage.token);
			this.setState({
				clients: response.data
			});
	
			new NotificationsController().createNotification("Registro encontrados", "201");
		} catch (error) {
			new NotificationsController().createNotification(error.message, "400")
		}
	};

	
	setView() {
	
		if (this.state.showEdit) {
			return(<EditClient 
				id = {this.state.id} 
				code = {this.state.code}
				firstName = {this.state.firstName} 
				lastName = {this.state.lastName}
				
				rfc = {this.state.rfc}
				email = {this.state.email}
				zipCode = {this.state.zipCode}
				phoneNumber = {this.state.phoneNumber}
				address = {this.state.address}
				>
				</EditClient>);

		} else 
		{
		return (
			
			<main className="content">
			
				<div className="container-fluid p-0">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h5 className="card-title">Lista de clientes</h5>
									<h6 className="card-subtitle text-muted">
										En el siguiente link puede agregar clientes nuevos{' '}
										<a href="/agregarCliente" rel="noopener noreferrer nofollow">
											Aquí
										</a>
									</h6>
								</div>
								<div className="card-body">
								<div className="table-responsive text-nowrap">
								<ReactTable
                data = {this.state.clients}
                Header = {"Clientes"}
                columns = {
                    [
                    
                        {
							Header : "Nombre",
                            accessor : "firstName"
                        }, 
						
                        {
							Header : "Apellido",
                            accessor : "lastName"
                        },
						{
							Header : "RFC",
                            accessor : "rfc"
                        },
						{
							Header : "Correo electronico",
                            accessor : "email"
                        },
						{
							Header : "Codigo postal",
                            accessor : "zipCode"
                        },
						{
							Header : "Numero telefonico",
                            accessor : "phoneNumber"
                        },
						{
							Header : "Direccion",
                            accessor : "address"
                        },
						{
							Header : 'Editar',
                            accessor: 'button1',
                            Cell: ({cell}) => (
                            <button className="btn btn-primary btn-sm" 
                            onClick={() =>this.handleEdit(cell.row.original)}> Editar
                            </button>
                                )
                        },
						// {
						// 	Header : 'Eliminar',
                        //     accessor: 'button2',
                        //     Cell: ({cell}) => (
                        //     <button className="btn btn-danger btn-sm" 
                        //     onClick={() =>this.handleDelete(cell.row.original.id)}> Editar
                        //     </button>
                        //         )
                        // },

					
				]
			}></ReactTable>
			</div>
								{/* <div className="table-responsive text-nowrap">
									<table className="table table-sm">
										<thead>
											<tr>
												<th>Nombre</th>
												<th>Apellidos</th>
												<th>RFC</th>
												<th>Correo electronico</th>
												<th>Codigo postal</th>
												<th>Numero telefonico</th>
												<th>adress</th>
												<th>Acción</th>
											</tr>
										</thead>
										<tbody> 
											{this.state.clients.map((client) => (
												<tr key={client.id}>
													<td>{client.firstName}</td>
													<td>{client.lastName}</td>
													<td>{client.rfc}</td>
													<td>{client.email}</td>
													<td>{client.zipCode}</td>
													<td>{client.phoneNumber}</td>
													<td>{client.address}</td>
													<td>
														<button
															type="submit"
															className="btn btn-primary btn-sm"
															onClick={() => this.handleEdit(client)
															}
														>
															Editar
														</button>
														<button
															type="submit"
															className="btn btn-danger btn-sm"
															onClick={() => this.handleDelete(client.id)}
														>
															Eliminar
														</button>
													</td>
												</tr>
											))}
										</tbody> 
									</table> */}
									{/* </div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			
			
			</main>
			);
		}
			}
			render() {
				return <div>{this.setView()}</div>
	}
}

export default ListClients;
