import React from 'react';
import DrawHelper from '../../helpers/draw/draw.request';
import DrawForm from './DrawForm';
import NotificationsController from '../NotificationsController';
import EditDraw from './EditDraw'
const formValid = formErrors => {
	let valid = true;
    
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});
    return valid;
}
class addDraw extends React.Component{
    constructor(props) {
        super(props);
        var dateObj = new Date();
        var dateString = ''.concat(dateObj.getFullYear().toString(),'-',String(dateObj.getMonth() + 1).padStart(2, '0'), '-',String(dateObj.getDate()).padStart(2, '0'));
        this.state = {
            lands: [],
            clients: [],
            vendors: [],
            processType : 'Normal',
            land : '',
            client: '',
            vendor: '',
            creationDate : dateString,
            observations : '', 

            drawPayments : [],
            totalPrice : 0, 
            manzanaLote: '',
            superficieM2: '',
            formErrors: {
                land: '',
                client: '',
                vendor: ''
            }
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleLand = this.handleLand.bind(this);
    }


    async handleLand(event){
    
        this.handleChange(event);
    
        const value = event.target.value;
        const landvalue = this.state.lands.filter(land => land.id === value);

        if (landvalue.length > 0){
         this.setState({
             totalPrice : landvalue[0]["totalPrice"],
             manzanaLote: 'Manzana: '.concat(landvalue[0]["block"],' / Lote: ',  landvalue[0]["lot"]),
             superficieM2 : landvalue[0]["surfaceM2"],
            });   
        }
        else{
            this.setState({
                totalPrice : 0,
                manzanaLote: '',
                superficieM2 : ''
            });
        }
    }
    
    async handleChange(event){
    
        
        var name = event.target.name
		var value = event.target.value
        let formErrors = this.state.formErrors;

        switch(name){
            case "land":
                formErrors.land = value === "0" ? "Valor no admitido" : ""
                break;
            case "client":
                formErrors.client = value === "0" ? "Valor no admitido" : ""
                break;
            case "vendor":  
                formErrors.vendor = value === "0" ?  "Valor no admitido" : ""
                break;
            default: 
                break;
        }

            this.setState({
                [event.target.name] : event.target.value    
            });

            this.setState({formErrors, name: value})
    }
 
    async handleSubmit(event) {
        event.preventDefault();
        try {
             if (formValid(this.state.formErrors)){
                
                const body = {
                    land: this.state.land,
                    client: this.state.client,
                    vendor : this.state.vendor,
                    creationDate : this.state.creationDate,
                    observations : this.state.observations,
                    advance : 0,
                    processType : this.state.processType
                };
                
                const response =  await new DrawHelper().addDraw(body, localStorage.token);
                this.setState({
                    showEdit : true,
                    id : response.data.id
                });

                 new NotificationsController().createNotification(response.data.message, response.status.toString());
                 this.setView();

			}
			else {
				new NotificationsController().createNotification( 'Faltan errores por corregir', "400");
			}
		} catch (err) {
			new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	
		}
	}


componentDidMount()
{  
    this.getInitialiValues()
}

getInitialiValues = async () => {
    
    try{
       
        const landresponse = await new DrawHelper().getLands(localStorage.token);
        const vendorresponse = await new DrawHelper().getVendor(localStorage.token);
        const clientresponse = await new DrawHelper().getClients(localStorage.token);
      
        this.setState({
          
            lands : landresponse.data,
            vendors : vendorresponse.data,
            clients : clientresponse.data
        });

    }
    catch(err){
        console.error(err.message);
    }
}

setView(){
    if(this.state.showEdit)
    {
        return(
            <div>  
                <EditDraw
                    id = {this.state.id}
                    client = {this.state.client}
                    land = {this.state.land}
                    advance = {this.state.advance}
                    vendor = {this.state.vendor}
                    creationDate = {this.state.creationDate}
                    observations = {this.state.observations}
                    paid = { false}  
                     processType = {this.state.processType}
                ></EditDraw>
            </div>
            );
    }
    else{
        return(
            <main className="content">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-md-12">
    
                        <DrawForm handleSubmit ={this.handleSubmit} 
                                handleChange = {this.handleChange}  
                                handleLand = {this.handleLand}
                                client = {this.state.client}
                                land = {this.state.land}
                                vendor = {this.state.vendor}
                                creationDate = {this.state.creationDate}
                                observations  = {this.state.observations}
                                manzanaLote = {this.state.manzanaLote}
                                totalPrice = {this.state.totalPrice}
                                superficieM2 = {this.state.superficieM2}
                                processType = {this.state.processType}
                                lands = {this.state.lands}
                                clients= { this.state.clients}
                                vendors= {this.state.vendors}
                                showButton = {true} 
                                formErrors = {this.state.formErrors}></DrawForm>
                    </div>
                </div>
            </div>
            </main>
        );
    }
}
render(){
  return <div> {this.setView()}</div>
}
}
export default addDraw;

