import React from 'react';
import NotificationsController from '../NotificationsController';
import MonthlyPaymentsForm from './monthlyPaymentsForm';
import MonthlyPaymentsHelper from '../../helpers/monthlyPayments/monthlyPayments.request'

import AuthenticateHelper from '../../helpers/authenticate/authenticate.request';
import CristianModal from '../CristianModal'
const convertir = require('numero-a-letras');
const formValid = formErrors => {
	let valid = true;

	//validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});
    return valid;
} 
class AddMonthlyPayments extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            lands : [],

            reciptsData : [],
            codesOfRecipts : [],
            
            code : '',
            land : '',
            contractNumber : '',
            totalPrice : '',
            manzanaLote : '',
            surfaceM2 : '',
            methodOfPayment : 'Efectivo',
            expirationDate : '',
            paymentDate : '',
            reciptId : '', 
            currency : 'MEX',
            currencyExchange : 1,
            amount : 0,
            amountWord : '',
            discount : 0,
            penalty : 0,
            realAmount : 0,
            previousBalance : '',
            actualBalance : '',
            observations : '',
            payments : [],
            formErrors:  {
                reciptId : '',
                currencyExchange : '',
                amount : '',
                amountWord : ''
            },
            showModal : false,
            message : '',
            email: '',
            password: '',
            receiptCode : '',
            paymentMethod : 'Pago en una sola exhibicion'
            
        }
        this.handleLand = this.handleLand.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRecipts = this.handleRecipts.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.modifyReceipts = this.modifyReceipts.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
    }

    componentDidMount() {
        this.getInitialValues();
    }
    
    getInitialValues = async () => {
        try{
            const response = await new MonthlyPaymentsHelper().getSoldLands(localStorage.token);
            this.setState({
                lands : response.data,
                land : this.props.land
            });    
           
           this.landInfo(this.state.land) 
           this.getPayments(this.state.land);
        }catch(error){
          console.error(error.message);
        }
    }

    async updateMonthlyPayment(code) {
        try {
        const body = {
            land : this.state.land, 
            code
        }
        const response =  await new MonthlyPaymentsHelper().updateMonthlyPayment(localStorage.token, body);
        new NotificationsController().createNotification( response.data.message, response.status.toString());
        this.manageRecipts(this.state.land);
        this.setState({
            showModal : false,
            reciptId : 0,
            payments : this.state.payments.filter(payment => payment.code !== code )
        });
        
        this.refs.form.clickFormPayment();

        }catch(err)
        {
            new NotificationsController().createNotification(err.response.data.message,err.response.status.toString());	
        }
    }
  

    async modifyReceipts(event, receiptCode ){
        event.preventDefault();    
        
        try {
            if( receiptCode === "0000"){
                new NotificationsController().createNotification("No se pueden modificar los folios de apartados","400")
            }
            else{
                if(localStorage.rol !== "Supervisor"){
                    this.setState({
                        showModal : true,
                        receiptCode 
                    });
                }
                else 
                {
                    this.updateMonthlyPayment(receiptCode);
                }
            }
        }catch(err){
            new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	    
        }
     }

     async handleSignIn(){
        try {
           
            const body = {
                email: this.state.email,
                password: this.state.password
            };
            
            const response = await new AuthenticateHelper().loginUser(body);
      
            if (response.data && response.data.token) {
                
               if (response.data.rol === "Supervisor") 
               {
                    this.updateMonthlyPayment(this.state.receiptCode);
               }
               else
               {
                    new NotificationsController().createNotification("Son necesarias las credenciales del supervisor.", '401');
               };
            }
        }
        catch(err){
              new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	;	
        }
    }

     async hideModal(){
        this.setState({
            showModal : false
        });
    }

    async handleSubmit(event){
        try {
            event.preventDefault();
         
            if (formValid(this.state.formErrors)) {
            const body ={ 
                id : this.state.reciptId,
                land : this.state.land,
                amount: this.state.amount,
                methodOfPayment: this.state.methodOfPayment ,
                currency : this.state.currency, 
                currencyExchange : this.state.currencyExchange, 
                discount : this.state.discount, 
                penalty : this.state.penalty,
                observations : this.state.observations,
                createdAt : this.state.paymentDate,
                previousBalance : this.state.previousBalance,
                actualBalance : this.state.previousBalance - this.state.amount,
                paymentMethod : this.state.paymentMethod
            };
            const response =  await new MonthlyPaymentsHelper().addMonthlyPayment(localStorage.token, body);
            new NotificationsController().createNotification( response.data.message, response.status.toString());
         
            this.state.payments.push({code : this.state.code, 
            createdAt : this.state.paymentDate,
            amount : this.state.amount, 
            amountWord : this.state.amountWord,
            discount :  this.state.discount,
            methodOfPayment : this.state.methodOfPayment,
            penalty :  this.state.penalty,
            realAmount :((this.state.amount - parseFloat(this.state.discount)) + parseFloat(this.state.penalty )) ,
            previousBalance : this.state.previousBalance,
            actualBalance : this.state.previousBalance - this.state.amount,
            currency : this.state.currency,
            currencyExchange : this.state.currencyExchange,
            BlockAndLot : this.state.BlockAndLot,
            paymentMethod : this.state.paymentMethod
        })      

        this.refs.form.clickFormPayment();

        if (response.data.status === "Pagado"){
            this.setState({
                codesOfRecipts :  this.state.codesOfRecipts.filter( code => code.id !== this.state.reciptId),
                paymentDate: '',
                expirationDate : ''
            });
        }
        
          this.setState({
              previousBalance : this.state.previousBalance - this.state.amount,
        
              amount : 0,
              amountWord : '',
              penalty : 0,
              discount : 0
            });
            
        


        }else {
				new NotificationsController().createNotification( 'Faltan errores por corregir', "400");
			}
            }catch (err){
                new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	
            }
        };
        
    async landInfo(value){
        const landvalue = this.state.lands.filter(land => land.id === value);
                
        if (landvalue.length > 0){

            this.setState({
                contractNumber : landvalue[0].SaleContracts[0].contractNumber,
                BlockAndLot : 'Manzana: '.concat(landvalue[0]["block"],' / Lote: ',  landvalue[0]["lot"]),
                totalPrice : landvalue[0].totalPrice,
                surfaceM2 : landvalue[0].surfaceM2,
            });
        }
        else {
            this.setState({
                contractNumber : '',
                BlockAndLot : '',
                totalPrice :'',
                surfaceM2 : ''
            });
        }
        this.manageRecipts(value);
        this.getPayments(value);
    }

    async handleLand(event) {
        this.handleChange(event);
        this.landInfo(event.target.value);
    }

    async handleRecipts(event){
        this.handleChange(event);
      
        const value = event.target.value;
        const reciptValue = this.state.reciptsData.filter(code => code.id === value);

        const balanceResponse = await new MonthlyPaymentsHelper().getBalance(localStorage.token, this.state.land, reciptValue[0].code)
        const actualBalance = balanceResponse.data.actualBalance
        
        if (reciptValue.length > 0 ) {
           var expirationDateWithOutFormat = new Date(reciptValue[0].expirationDate);
           var expirationDate = ''.concat(expirationDateWithOutFormat.getFullYear().toString(),'-',String(expirationDateWithOutFormat.getMonth() + 1).padStart(2, '0'), '-',String(expirationDateWithOutFormat.getDate()).padStart(2, '0'));
         
           var todayDateWithOutFormat = new Date();
           var todayDate =  ''.concat(todayDateWithOutFormat.getFullYear().toString(),'-',String(todayDateWithOutFormat.getMonth() + 1).padStart(2, '0'), '-',String(todayDateWithOutFormat.getDate()).padStart(2, '0'));

           this.setState({
               amount : reciptValue[0].amountQuantity,
               amountWord : reciptValue[0].amountWord,
               expirationDate : expirationDate,
               paymentDate : todayDate,
               penalty : expirationDateWithOutFormat < todayDateWithOutFormat ? 90 : 0,
               discount : 0,
               code : reciptValue[0].code,
               previousBalance : actualBalance
            });
        }
        else{
            this.setState({
                amount : 0,
                amountWord : '',
                expirationDate : '',
                paymentDate : '',
                penalty : 0,
                discount : 0,
                code : '' ,
                previousBalance :''
            });
        }
    }

    async manageRecipts(land){
        const reciptResponse = await new MonthlyPaymentsHelper().getRecipts(localStorage.token, land)
      
        var recipts =[]
        
        for(let recipt of reciptResponse.data)
        {
            recipts.push({id : recipt.id, code: recipt.code});
        }

        this.setState({
            codesOfRecipts : recipts,
            reciptsData : reciptResponse.data,
        });
    }

    async getPayments(land) {
        try{
      
            const response = await new MonthlyPaymentsHelper().getPayments(land, localStorage.token);
            this.setState({
                payments : response.data
            });
             new NotificationsController().createNotification("Registro encontrados","201");
            
        }catch(error){
            new NotificationsController().createNotification(error.message,"400");
        }
    };
 
 
    async handleChange(event) {
        event.preventDefault();
        var value = event.target.value
        let formErrors = this.state.formErrors;

        switch(event.target.name){
            case 'reciptId': 
                formErrors.reciptId =  value === "0"  ? "Seleccione un recibo para continuar" : ""
            break;
            case 'currencyExchange':
                formErrors.currencyExchange = value < 1 ? "El tipo de cambio no puede ser 0 o inferior" : ""
            break;
            case 'amount':
                this.setState({
                    amountWord :  convertir.NumerosALetras(event.target.value)
                })
                formErrors.amount = value < 1  ? "El abono no puede ser 0 o inferior": ""
                break;
            case 'amountWord':
                formErrors.amountWord = value.length < 1 ? "No puede dejar la cantidad en letra vacía" : ""
            break;
            default : 
            break;
          }
      
   
        this.setState({
           [event.target.name]: event.target.value
         });

         if  (event.target.name === "paymentDate") {
            this.setState({
               penalty : new Date(this.state.expirationDate) < new Date(event.target.value) ? 90 : 0,
            });
        }
      
         this.setState({formErrors, name: value});
    }

    render(){
        return(
            <main className = "content">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-md-12">
                        <CristianModal showModal={this.state.showModal} handleSubmit = {this.handleSignIn} hideModal={this.hideModal} handleChange = {this.handleChange} />
                
                            <MonthlyPaymentsForm  ref="form" 
                                handleLand = {this.handleLand}
                                handleRecipts = {this.handleRecipts} 
                                handleChange = {this.handleChange}
                                handleSubmit = {this.handleSubmit}
                                
                                calculateBalances = {this.calculateBalances}

                                showButton = {true}
                                lands = {this.state.lands}
                                land = {this.state.land}
                                contractNumber = {this.state.contractNumber}
                                BlockAndLot = {this.state.BlockAndLot}
                                totalPrice = {this.state.totalPrice}
                                surfaceM2 = {this.state.surfaceM2 }
                                codesOfRecipts = {this.state.codesOfRecipts}
                                observations = {this.state.observations}
                                reciptId = {this.state.reciptId}
                                amount = {this.state.amount}
                                methodOfPayment = {this.state.methodOfPayment}
                                paymentMethod = {this.state.paymentMethod}
                                currency = {this.state.currency}
                                currencyExchange = {this.state.currencyExchange}
                                amountWord = {this.state.amountWord}
                                discount = {this.state.discount}
                                penalty = {this.state.penalty}
                                paymentDate = {this.state.paymentDate}
                                expirationDate = {this.state.expirationDate}
                                realAmount = {this.state.realAmount}
                                previousBalance = {this.state.previousBalance}
                                formErrors = {this.state.formErrors}
                                payments = {this.state.payments}
                                modifyReceipts = {this.modifyReceipts}
                                clientName = {this.props.clientName}
                                clientAddress = {this.props.clientAddress}
                                ></MonthlyPaymentsForm>
                                        
                              
                        </div>
                    </div>
                </div>
            </main>
             );
    }

}
export default AddMonthlyPayments;