import React from 'react';
import CancelationHelper from '../../helpers/cancellation/cancel.request';
import NotificationsController from '../NotificationsController';
import CancellationReport from '../Reports/Cancellations/CancellationReport';
import CristianModal from '../CristianModal';
import AuthenticateHelper from '../../helpers/authenticate/authenticate.request';
import ConfirmModel from '../ConfirmModal'
class AddCancellation extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            cancellationId : '',
            bankReference : '',
            observations : '',
            showModal : false,
            email: '',
            password: '' ,
            showConfirmModal : false,
        }
        this.handlepayCancellation = this.handlepayCancellation.bind(this);
        this.handleCancelCancellation = this.handleCancelCancellation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateCancelCancellation = this.updateCancelCancellation.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.confirmOperation = this.confirmOperation.bind(this);
        this.hideConfirmation = this.hideConfirmation.bind(this);
    }

    componentDidMount(){

         this.setState({
             cancellationId : this.props.id,
             land : this.props.land,
             clientName : this.props.clientName,
             clientAddress : this.props.clientAddress,
             amount : this.props.amount,
             amountWord : this.props.amountWord,
             landId : this.props.landId,
             clientId :this.props.clientId,
             status : this.props.status,
             code : this.props.code,
             createdAt : this.props.createdAt,
             bankReference : this.props.bankReference ,
             observations : this.props.observations,
             typeOfCancellationPayment : this.props.typeOfCancellationPayment,
             operationalCost : this.props.operationalCost,
             sumMonthlyPayment  : this.props.sumMonthlyPayment,
             totalLandPrice : this.props.totalLandPrice
         })
    }

    async handleSignIn(){
        try {

            const body = {
                email: this.state.email,
                password: this.state.password
            };

            const response = await new AuthenticateHelper().loginUser(body);

            if (response.data && response.data.token) {

               if (response.data.rol === "Supervisor")
               {
                this.handleCancelCancellation();
               }
               else
               {
                    new NotificationsController().createNotification("Son necesarias las credenciales del supervisor.", '401');
               };
            }
        }
        catch(err){

              new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	;
        }
    }

async updateCancelCancellation(event){
    event.preventDefault();
    try {
   if(localStorage.rol !== "Supervisor"){
       this.setState({
           showModal : true,

       });
   }
   else
   {
       this.handleCancelCancellation(event);
   }

}catch(err){

    new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())
}
}
  async handleCancelCancellation(){
     try{
     const {cancellationId} = this.state

     const response  = await new CancelationHelper().landCancelCancellation(cancellationId,localStorage.token);
     new NotificationsController().createNotification(response.data.message, response.status.toString());
    window.location.reload();
    }catch (err){
      new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())
  }
  }
  async confirmOperation(){
    this.setState({
        showConfirmModal : true,
    });

}
async hideConfirmation(){
    this.setState({
        showConfirmModal : false
    });
}
  async hideModal(){
    this.setState({
        showModal : false
    });
}
  async handleChange(event) {
    event.preventDefault();
    this.setState({
        [event.target.name]: event.target.value
      });
  }
  async handlepayCancellation(){
      try{
      const body = {
        id : this.state.cancellationId,
        bankReference : this.state.bankReference,
        observations : this.state.observations
      }

      const response  = await new CancelationHelper().payCancellations(localStorage.token, body);
      new NotificationsController().createNotification(response.data.message, response.status.toString());

      this.setState({
          status : response.data.status
      });

    }catch (err){
        new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())
    }
  }

render (){
  return(  <main className = "content">
  <div className="container-fluid p-0">
      <div className="row">
          <div className="col-md-12">
          <CristianModal showModal={this.state.showModal} handleSubmit = {this.handleSignIn} hideModal={this.hideModal} handleChange = {this.handleChange} />
          < ConfirmModel showModal={this.state.showConfirmModal}  handleSubmit = {this.handleCancelCancellation} hideModal={this.hideConfirmation} />

          <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">Cancelaciones</h5>
                    </div>
                    <div className="card-body">
                    <div className="form-row">
                        <div className="form-group col-md-2">
                            <label>Terreno</label>
                            <input type="text" className="form-control" name="land" value= {this.state.land} placeholder="Terrenos" disabled />
                        </div>
                        <div className="form-group col-md-3">
                            <label>Cliente</label>
                            <input type="text" className="form-control" name="clientName" value= {this.state.clientName} placeholder="Cliente" disabled />
                        </div>
                        <div className="form-group col-md-1">
                            <label>Pago</label>
                            <input type="text" className="form-control" name="amount" value= {this.state.amount} placeholder="Cobro" disabled />
                        </div>
                        <div className="form-group col-md-4">
                            <label>En letra</label>
                            <input type="text" className="form-control" name="amountWord" value= {this.state.amountWord} placeholder="En letra" disabled />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Tipo</label>
                            <input type="text" className="form-control" name="land" value= {this.state.typeOfCancellationPayment} placeholder="Tipo" disabled />
                        </div>

                    </div>


                    <div className="form-row">
                    <div className="form-group col-md-12">
                    <label >Referencia bancaria</label>
                    <input type="text" className="form-control" name="bankReference" value= {this.state.bankReference}  onChange={this.handleChange} placeholder="Referencia bancaria" disabled = {(this.state.status === 'Pagado')}  />
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-12">
                        <label>Observaciones</label>
                        <input type="text" className="form-control" name="observations" value = {this.state.observations} onChange={this.handleChange} placeholder="Observacion" disabled = {(this.state.status === 'Pagado')}/>
                    </div>
                    </div>

                    {this.state.status === 'Pagado' ? <CancellationReport    land = {this.state.land}
                      clientName = {this.state.clientName}
                      clientAddress = {this.state.clientAddress}
                      code=  {this.state.code.toString().padStart(4,"0")}
                      amount = {this.state.amount}
                      amountWord = {this.state.amountWord}
                      bankReference ={this.state.bankReference}
                      observations ={this.state.observations}
                      createdAt = {this.state.createdAt}
                      operationalCost = {this.state.operationalCost}
                      sumMonthlyPayment  = {this.state.sumMonthlyPayment}
                      totalLandPrice= {this.state.totalLandPrice}
                      typeOfCancellationPayment = {this.state.typeOfCancellationPayment}/>:
                     <div className="form-row">

                    <div style = {{marginRight: '5px'}}>
                      {/* <button type="submit" className="btn btn-danger" onClick={this.updateCancelCancellation} >Regresar cancelacion</button> */}
                      <button
                            type="submit"
                            className="btn btn-danger btn-sm"
                            onClick={(e)=> this.confirmOperation()} >
                                 Regresar cancelacion
                            </button>

                    </div>
                      <button type="submit" className="btn btn-primary" onClick={this.handlepayCancellation} >Pagar cancelacion</button>
                     </div>
                    }
                    </div>
          </div>
          </div>
     </div>
</div>
            </main>


         );
}
}
export default AddCancellation;