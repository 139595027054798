import axios from 'axios';
import constants from '../../config/constants';

class PermissionsHelper {
    async makeCall(method, url, data, token = null) {
        const response = await axios({
            method,
            url,
            data,
            headers: {
                'x-access-token': token
            }
        });
        return response
    }

    async getUserPermissions(token, userId) {

        const method = 'GET';
        const url = `${constants.BACKEND_URL}/permissions/userPermissions/${userId}`;
        return this.makeCall(method, url, null, token);

    }
    async getPermission(token, permissionId, permissionNeeded){
        const method = 'GET';
        const url =  `${constants.BACKEND_URL}/permissions/userPermission/${permissionId}/${permissionNeeded}`
        return this.makeCall(method, url, null, token)
    }
    async updatePermissionsValue(token, body){

        const method = 'PUT';    
        const url =`${constants.BACKEND_URL}/permissions/userPermissions/value`
        return this.makeCall(method,url,body, token);
 
    }

    async updatePermissionsPrint(token, body){

        const method = 'PUT';    
        const url =`${constants.BACKEND_URL}/permissions/userPermissions/print`
        return this.makeCall(method,url,body, token);
    }

}

export default PermissionsHelper;
