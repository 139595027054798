import React from 'react';
import LandHelper from '../../helpers/land/land.request';
import LandForm from './landForm';
import NotificationsController from '../NotificationsController'

const formValid = formErrors => {
	let valid = true;

	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});
	return valid;
}

class AddLands extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			code: '',
			block: 1,
			lot: 1,
			height: 0.00,
			width: 0.00,
			surfaceM2: '',
			totalPrice: '',
			pricePerM2: 0.00,
			status: 'Disponible',
			formErrors : {
				code : '',
				block:'',
				lot: '',
				height:'',
				width: '',
				pricePerM2: ''
			}
		};

		this.handleChange = this.handleChange.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
	
		var value = event.target.value
		let formErrors = this.state.formErrors;

		switch(event.target.name){
			case 'code' :
				formErrors.code = value.length < 3 ? "Introduzca codigo" : ""
			break;
			case 'block':
				formErrors.block = value === "0" ? "Seleccione una manzana" : ""
			break;
			case 'lot': 
				formErrors.lot = value === "0" ? "Seleccione un lote" : ""
			break;
			case 'pricePerM2':
				formErrors.pricePerM2 = value < 1 ? "Precio por metro cuadrado no valido" : ""
			break;
			default: 
			break;
		}
		this.setState({
			[event.target.name]: event.target.value
	});
	this.setState({formErrors, name: value})
	}

	async handleSubmit(event) {
		event.preventDefault();
			try {
		
				if (formValid(this.state.formErrors)){
				const body = {
					code: this.state.code,
					block: this.state.block,
					lot: this.state.lot,
					height: this.state.height,
					width: this.state.width,
					pricePerM2: this.state.pricePerM2,
					surfaceM2: this.state.surfaceM2,
					totalPrice: this.state.pricePerM2 * (this.state.surfaceM2),
					status: this.state.status
				};

			 const response = await new LandHelper().addLand(body, localStorage.token);
			 new NotificationsController().createNotification( response.data.message, response.status.toString());
			}
			else {
				new NotificationsController().createNotification( 'Faltan errores por corregir', "400");
			}
			}
			  catch (err) {
				new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())		 
	 	}
	}

	render() {
		return (

			<LandForm handleSubmit = {this.handleSubmit} handleChange = {this.handleChange}
				code = {this.state.code}
				block = {this.state.block}
				lot = {this.state.lot}
				height = { this.state.height}
				width = { this.state.width}
				surfaceM2 = {this.state.surfaceM2}
				totalPrice=  {this.state.totalPrice}
				pricePerM2 = {this.state.pricePerM2}
				status= {this.state.status}
				formErrors = {this.state.formErrors}> </LandForm>

		);}
}
export default AddLands;
