import axios from 'axios';
import constants from '../../config/constants';
 
class VendorHelper{
    async makeCall(method, url, data,token = null) {
		return axios({
			method,
			url,
			data,
			headers: {
				'x-access-token': token
			}
		});
	}

async getVendors(token){
    const method = 'GET';
    const url = `${constants.BACKEND_URL}/vendor`
    return this.makeCall(method, url, {}, token);
}
async addVendor(body, token){
	const method = 'POST';
	const url = `${constants.BACKEND_URL}/vendor`;
	return this.makeCall(method, url, body, token);
}
async editVendor(body, token){
	const method = 'PUT';
	const url = `${constants.BACKEND_URL}/vendor`;
	return this.makeCall(method, url, body, token)
}
async deleteVendor(token, id){
	const method = 'DELETE';
	const url = `${constants.BACKEND_URL}/vendor/${id}`
	return this.makeCall(method, url, {}, token);
}
async getVendorComissions(token, land, vendor){
	const method = 'GET';
	const url =`${constants.BACKEND_URL}/vendor/getVendorCommision/${land}/${vendor}`
	return this.makeCall(method, url, {}, token)
}
async payVendorComission(token, body){
	const method = 'POST';
	const url = `${constants.BACKEND_URL}/vendor/payVendorCommission`
	return this.makeCall(method, url, body, token)

}
}
export default VendorHelper; 