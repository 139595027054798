import React from 'react';
import constants from '../../config/constants';
import monthlyPaymentsHelper from '../../helpers/monthlyPayments/monthlyPayments.request';
import Pagination from '../Pagination'
import ReceiptOfAllPayments from '../Reports/ReceiptOfAllPayments/ReceiptOfAllPayments'
class reciptContractList extends React.Component{
    constructor(props){
          super(props);
          this.state = {
            allCountries : [],
            currentCountries: [], 
            currentPage: null, 
            totalPages: null
          }
      this.getRecipts = this.getRecipts.bind(this);
    }
    componentDidMount() {

         this.getRecipts();
      }

  componentDidUpdate(){
    
    if (this.props.inside)
    {
      this.getRecipts();
    }

  }
  
    async getRecipts() {
     
      await this.props.changeState(false);

        const response = await new monthlyPaymentsHelper().getPaginatedRecipts(localStorage.token, this.props.land, 10000,1);
    
        await this.setState({
          allCountries : response.data.recipts
        });
       await this.onPageChanged({currentPage : response.data.pageNumber, totalPages :   Math.ceil(this.state.allCountries.length / 20) , pageLimit :  20});
    };

      onPageChanged = async data => {
        const { allCountries } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;
        const currentCountries = allCountries.slice(offset, offset + pageLimit);
      
        this.setState({ currentPage, currentCountries, totalPages, totalRecords : allCountries.length });
      }


render() {

    const { allCountries, currentCountries } = this.state; // currentPage, totalPages
    const totalCountries = allCountries.length;
    const { clientAddress, client } = this.props
  

    if (totalCountries === 0) return null;
    return (
    <div className="card">
       
        <div className="card-body">
        <ReceiptOfAllPayments receipts = {allCountries}  clientAddress  = {clientAddress} client = {client} />
          <br></br>
          <div className="table-responsive text-nowrap">
        <table className = "table table-sm">
          <thead>
                <tr>
                    <th>Folio</th>
                    <th>Fecha de pago</th>
                    <th>Fecha de vencimiento</th>
                    <th>Cantidad a pagar</th>
                    <th>Cantidad en letra</th>
                    <th>Estatus</th>
                </tr>
                      </thead>
          <tbody> 
                    {currentCountries.map((payment) => (
                    <tr key={payment.id}>
                    <td>{payment.code}</td>
                    <td>{payment.paymentDate.substring(0,10)}</td>
                    <td>{payment.expirationDate.substring(0,10)}</td>
                    <td>{payment.amountQuantity}</td>
                    <td>{payment.amountWord}</td>
                    <td>
                      <span className = {payment.status === "Pagado" ? "badge bg-success" : "badge bg-warning" }> {payment.status}</span>
                     </td>
                    </tr> ))}
                    </tbody> 
            </table>
            </div>
            
              <Pagination totalRecords={totalCountries} pageLimit={20} pageNeighbours={1} onPageChanged={this.onPageChanged} />      
            </div>
            </div>
    );

  }
}

export default reciptContractList;