import React from 'react';
import ReactDOM from 'react-dom';

 import './components/docs/css/light.css';
 import 'react-notifications/lib/notifications.css';

import App from './App';

ReactDOM.render(
	<React.StrictMode>
		<App  />
	</React.StrictMode>,
	document.getElementById('root')
);
