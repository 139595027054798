import React from 'react';
import ReactToPrint from "react-to-print";
import DrawHelper from '../../../helpers/draw/draw.request'
import NotificationsController from '../../NotificationsController'
class ComponentToPrint extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            drawPayments : []
        }
       this.searchConciliationList = this.searchConciliationList.bind(this)
    }
   componentDidMount(){
    this.searchConciliationList();
   }
    async searchConciliationList() {
        try{
            const drawPaymentResponse = await new DrawHelper().getSavedLandReport(this.props.SavedLandId, localStorage.token);
            this.setState({
                drawPayments : drawPaymentResponse.data
            });
           }catch(err){
               new NotificationsController().createNotification(err.response.data.message.concat(', genere la venta para ver el reporte.'),err.response.status.toString())
           }
         }

render(){
    
    if (this.state.drawPayments.length === 0) {
       
        return null

    } ;
        
    
    const { drawPayments } = this.state
    const {processType} = this.props
    const {observation, amount,amountWord,concept, monthlyPayment,restAmount} = drawPayments
    const {address,email,firstName,lastName,phoneNumber,zipCode} =drawPayments.client
    const {lot, block,pricePerM2,surfaceM2,totalPrice } = drawPayments.land

return(   <main className="content">
<div className="container-fluid p-0">
    <div className="row">
        <div className="col-12">
            <div className="card">
                
                <div className="card-body m-sm-3 m-md-5">
                <h1 className="h3 mb-3">Recibo de apartados</h1>
                         
                    <div className="row">
                        <div className="col-md-6" >
                            <div className="text-muted"> Ave. Perales No. 16325, Edit, 11 Depto. 1-C, Fracc. La Campiña, <strong> Tijuana B.C. </strong></div>
                            <div className="text-muted">Fraccionamiento colina de los angeles</div>
                            <div className="text-muted">Teléfono 664-876-2625</div> 
                            <div className="text-muted"> colinasdelosangeles@gmail.com</div>
                           
                        </div>
                        <div className="col-md-6 text-md-right">
                            <br></br>
                            <br></br>
                            <div className="text-muted">Folio del recibo <strong>{this.props.idDrawLand}</strong></div>
                            <strong>19 de mayo, 2020 </strong>
                            </div>
                    </div>

                    <hr className="my-4" />

                    <div className="row mb-4">
                        <div className="col-md-6">
                            
                            <div className="text-muted"> <strong>Recibimos de </strong>{''.concat(firstName, ' ',lastName) }</div>
                            <div className="text-muted"> <strong>Direccion particular </strong> {''.concat(address, ' C.P', zipCode )}</div>
                            <div className="text-muted"> <strong>La cantidad de </strong> {''.concat(amount, ' pesos ','(',amountWord,')' )}</div> 
                            <strong>Correo </strong> <a href = "#"> {email}</a>
                        </div>
                        <div className="col-md-6 text-md-right">
                        <div className="text-muted"><strong> Telefono </strong> {phoneNumber}</div>
                         </div>
                         <div className="col-md-12">
                         <div className="text-muted"> <strong>Por concepto de: </strong>{concept}</div> 
                         <div> <strong>Tipo de apartado: </strong> {processType}</div>
                    </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-4">
                            </div>
                        <div className="col-md-8">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th className="text-center"> Del lote no.</th>
                                    <th className="text-center" >Manzana</th>
                                    <th className="text-right">Superficie total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">{lot} </td>
                                    <td className="text-center">{block}</td>
                                    <td className="text-right">{surfaceM2}</td>
                                </tr>
                                </tbody>
                        </table>
                        </div>
                    </div>
                    <div className="row mb-4">
                    <div className="col-md-2">
                    </div>
                            <div className="col-md-10">
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th className="text-right">Precio por M</th>
                                        <th className="text-right">Precio total sin interes</th>
                                        <th className="text-right">Enganche sin interes</th>
                                        <th className="text-right">Saldo sin interes</th>
                                        <th className="text-right">PagoMensual</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-right">{pricePerM2} </td>
                                        <td className="text-right">{totalPrice}</td>
                                        <td className="text-right">{amount}</td>
                                        <td className="text-right">{restAmount}</td>
                                        <td className="text-right">{monthlyPayment}</td>
                                    </tr>
                                    </tbody>
                            </table>
                            </div>
                    </div>             
                    <div className="col-md-12">
                    <div className="text-muted"> <strong>Observaciones: </strong> {observation}</div> 
                    </div>   
                    <br />
                    <div className="col-md-12 text-center">
                    <strong>
                    En caso de Cancelación, se descontará un 30% sobre el valor total del terreno.
                    </strong>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div className="row  text-center">
                    <div className="col-md-6" >
                        __________________________________________________
                    <br/>
                    <br/>
                    <br/>
                    firma conforme cliente 
                    </div>
                    
                    {/* <div className="col-md-6 " >
                    __________________________________________________
                    <br/>
                    <br/>
                    <br/>
                    firma de recibido
                    </div> */}
                    </div>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>
    </div>

</div>
</main>
)}}

class savedLandReport extends React.Component {
    constructor(props){
        super(props);
      
    }
  
    render() {
      return (
      
        <div className="text-center">
        <p className="text-sm">
            <strong>Nota:</strong> Presione el siguiente boton para guardar la impresion
        </p>
      
          <ReactToPrint 
          trigger={() => <button  className="btn btn-primary">Impresion de formato</button>}
          content={() => this.componentRef}
          />
          
            <div style={{display: 'none'}}>
                <ComponentToPrint processType= {this.props.processType} SavedLandId={this.props.SavedLandId} ref={(el) => (this.componentRef = el) } />
            </div>
          </div>
      
      
      );
    }
  }
  
  export default savedLandReport;
  