import React from 'react';
import ClientForm from './clientForm';
import ClientHelper from '../../helpers/client/client.request';
import NotificationsController from '../NotificationsController';

const emailRegExp = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
const phoneRegExp = RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);

const formValid = formErrors => {
	let valid = true;

	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});
  return valid;
}

class addClient extends React.Component {
	constructor(props) {
		super(props);

    this.state = {
      code: '',
      firstName: '',
			lastName: '',
			email: '',
			rfc: '',
		  zipCode: '',
      phoneNumber: '',
      address : '',
      formErrors:  {
        code: '',
        firstName: '',
        lastName: '',
        email: '',
        rfc: '',
        zipCode: '',
        phoneNumber: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    }

   async handleChange(event) {

		var value = event.target.value
    let formErrors = this.state.formErrors;

    switch(event.target.name){
      case 'code': 
      formErrors.code = value.length < 1 ? "Introduzca codigo" : ""
      break;
      case 'firstName': 
      formErrors.firstName = value.length < 3 ? "Introduzca nombre valido" : ""
      break;
      case 'lastName': 
      formErrors.lastName = value.length < 3 ? "Introduzca apellido valido" : ""
      break;
      case 'email':
      formErrors.email = !emailRegExp.test(value) ? "Direccion de correo electronico no valida" : ""
      break;
      case 'rfc': 
      formErrors.rfc = value.length < 10 ? "Introduzca un RFC valido" : ""
      break;
      case 'zipCode': 
      formErrors.zipCode = value.length !== 5 ? "Introduzca un codigo postal valido" : ""
      break;
      case 'phoneNumber':
      formErrors.phoneNumber = !phoneRegExp.test(value) ? "Introduzca un telefono valido": ""
      break; 
      default : 
      break;
    }

      this.setState({
         [event.target.name]: event.target.value
       });  
       
       this.setState({formErrors, name: value})
    }
    
   async handleSubmit(event){
    event.preventDefault();
      try {
        if (formValid(this.state.formErrors)){
          
          const body = {
            code: this.state.code,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            rfc: this.state.rfc,
            zipCode: this.state.zipCode,
            phoneNumber: this.state.phoneNumber,
            address : this.state.address
          };
          
 
      const response = await new ClientHelper().addClient(body, localStorage.token);
      new NotificationsController().createNotification( response.data.message, response.status.toString());
    }
    else {
      new NotificationsController().createNotification( 'Faltan errores por corregir', "400");
    }
  } catch (err) {
    new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	
  }
}

    render() {
		return (
    
          <ClientForm handleSubmit={this.handleSubmit} handleChange ={this.handleChange}
                      code ={this.state.code}
                      firstName  = {this.state.firstName}
                      lastName = {this.state.lastName}
                      email = {this.state.email}
                      rfc = {this.state.rfc}
                      zipCode= {this.state.zipCode}
                      phoneNumber={this.state.phoneNumber}
                      formErrors = {this.state.formErrors}
                      address = {this.state.address}
                      > </ClientForm>
                
           
        );}
}
export default addClient;
