import React from 'react';
import AuthenticateHelper from '../helpers/authenticate/authenticate.request';
import Main from './Main';
import NotificationsController from './NotificationsController'
import {NotificationContainer } from 'react-notifications';
class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			setAuth: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.logout = this.logout.bind(this);
	}

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		this.onSubmitForm();
	}

	logout() {
		this.setState({
			setAuth: false
		});
		localStorage.removeItem('token');
		localStorage.removeItem('rol');
		this.setView();
	}

	async onSubmitForm() {
		try {
			const body = {
				email: this.state.email,
				password: this.state.password
			};

			const response = await new AuthenticateHelper().loginUser(body);

			let token;
			let rol;
			if (response.data && response.data.token) {
				rol = response.data.rol;
				token = response.data.token;
				localStorage.setItem('token', token);
				localStorage.setItem('rol', rol)
				this.setState({
					setAuth: true
				});
				this.setView();
				// new NotificationsController().createNotification(response.data.message, response.status);
			}
		} catch (err) {
			new NotificationsController().createNotification("Nombre de usuario o contraseña no válidos", "400");
			this.setState({
				setAuth: false
			});
		}
	}

	setView() {
		if (!this.state.setAuth) {
			return (
				<div className="main d-flex justify-content-center w-100">
					<main className="content d-flex p-0">
						<div className="container d-flex flex-column">
							<div className="row h-100">
								<div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
									<div className="d-table-cell align-middle">
										<div className="text-center mt-4">
											<h1 className="h2">Bienvenido bienes raices</h1>
											<p className="lead">Inicie sesion en su cuenta para continuar</p>
										</div>
										<div className="card">
											<div className="card-body">
												<div className="m-sm-4">
													<form onSubmit={this.handleSubmit}>
														<div className="form-group">
															<label>Correo:</label>
															<input
																className="form-control form-control-lg"
																type="email"
																name="email"
																placeholder="Correo electronico"
																value={this.state.email}
																onChange={this.handleChange}
															/>
														</div>
														<div className="form-group">
															<label>Contraseña:</label>
															<input
																className="form-control form-control-lg"
																type="password"
																name="password"
																placeholder="Contraseña"
																value={this.state.password}
																onChange={this.handleChange}
															/>
														</div>

														<div className="text-center mt-3">
															<button type="submit" className="btn btn-lg btn-primary">
																Sign in
															</button>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</main>
					<NotificationContainer/>
				</div>
				
			);
		} else {
			return <Main></Main> 
		}
	}

	render() {
		return <div>{this.setView()}</div>;
	}
}
export default Login;
