import React from 'react';
import ReactToPrint from 'react-to-print';

class ComponentToPrint extends React.Component {
 

render(){ 
        const {code, createdAt,amount,amountWord ,discount,penalty,realAmount,previousBalance,actualBalance,currency,currencyExchange,BlockAndLot, clientName,clientAddress,paymentMethod } = this.props
return( 
     <main className="content" >
<div className="container-fluid p-0">
    <div className="row">
        <div className="col-12">
            <div className="card">
                <div className="card-body m-sm-3 m-md-5">
               
                      
                    <div className="row">
                        <div className="col-md-6" style={{ width : '60%', fontSize: 16} } >
                            <div className="text-muted"> Ave. Perales No. 16325, Edit, 11 Depto. 1-C, Fracc. La Campiña, <strong> Tijuana B.C. </strong></div>
                            <div className="text-muted">Fraccionamiento colina de los angeles</div>
                            <div className="text-muted">Teléfono 664-876-2625</div> 
                            <div className="text-muted"> colinasdelosangeles@gmail.com</div>
                           
                        </div>
                        <div style={{textAlign: 'right', alignSelf: 'stretch', width : '40%', fontSize: 16} } className="col-md-6 text-md-right">
                            <br></br>
                            <div className="text-muted"> <strong  style = {{background  : "#D0D0D0"}}> Recibo N {code}</strong> </div>
                            <div className="text-muted"> <strong >{paymentMethod}</strong> </div>
                            <strong>{new Date(createdAt).toLocaleDateString("es-ES",{timeZone: 'UTC', year: 'numeric', month: 'long', day: 'numeric' })}  </strong>
                            <br></br>
                            <br></br>
                            <div className="text-muted"><strong> Bueno por </strong> {(amount).toLocaleString('en-US', {style: 'currency',  currency: 'USD'})}</div>
                         
               
                       </div>
                       <div className="col-md-6">
                       <h1  className="h3 mb-3"style={{ fontSize: 18 } } >Recibo de pago</h1>
                    </div>
                    </div>
                    <hr className="my-4" />

                    <div className="row mb-4">
                
                <div className="col-md-10">
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th className="text"  style = {{fontSize: 14, background: '#D0D0D0' }}>Cliente: {clientName} </th> 
                            
                            </tr>
                            </thead>
                            <tbody>
                            <td className="text"  style = {{fontSize: 14, background: '#D0D0D0' }}>Dirección : {clientAddress} </td> 
                            </tbody>
                            </table>
                        </div>
                        </div>


                    <div className="row mb-2">
                        <div className="col-md-3" style={{textAlign: 'right', alignSelf: 'stretch', width : '30%'} } >
                        <table className="table table-sm"  >
                        <thead >
                                <tr > 
                                    <th className="text-center" style = {{fontSize: 14 }}> Terreno</th>
                                    <th className="text-center" ></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center"  style = {{fontSize: 14 }}>  {BlockAndLot} </td>                                   
                                </tr>
                                </tbody>
                        </table>
                        </div>
                           
                        <div className="col-md-9" style={{textAlign: 'right', alignSelf: 'stretch', width : '70%' }} >
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th className="text-center" style = {{fontSize: 14 }}>Moneda</th>
                                    <th className="text-center"  style = {{fontSize: 14 }}>Tipo cambio</th>
                                    <th className="text-right" style = {{fontSize: 14 }}>Abono cantidad</th>
                                    <th className="text" style = {{fontSize: 14 }}>Abono letra</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center" style = {{fontSize: 14 }}>{currency}</td>
                                    <td className="text-center" style = {{fontSize: 14 }}>{(currencyExchange).toLocaleString('en-US', {style: 'currency',  currency: 'USD'})}</td>
                                    <td className="text-right" style = {{fontSize: 14 }}>{(amount).toLocaleString('en-US', {style: 'currency',  currency: 'USD'})}</td>
                                    <td className="text" style = {{fontSize: 14 }}>{amountWord}</td>
                                </tr>
                                </tbody>
                        </table>
                        </div>
                    </div>
                    <div className="row mb-4">
                    <div className="col-md-2">
                    </div>
                            <div className="col-md-10">
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th className="text-right"  style = {{fontSize: 14, background: '#D0D0D0' }}>Penalizacion</th>
                                        <th className="text-right" style = {{fontSize: 14, background: '#D0D0D0' }}>Monto por mes</th>
                                        <th className="text-right" style = {{fontSize: 14, background: '#D0D0D0' }}>Descuento</th>
                                        <th className="text-right" style = {{fontSize: 14 , background: '#D0D0D0'}}>Saldo anterior</th>
                                        <th className="text-right" style = {{fontSize: 14, background: '#D0D0D0' }}>Mensualidad total</th>
                                        <th className="text-right" style = {{fontSize: 14 , background: '#D0D0D0'}}>Saldo actual</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-right" style = {{fontSize: 14 }}>{(penalty).toLocaleString('en-US', {style: 'currency',  currency: 'USD'})} </td>
                                        <td className="text-right" style = {{fontSize: 14 }}>{(amount).toLocaleString('en-US', {style: 'currency',  currency: 'USD'})}</td>
                                        <td className="text-right" style = {{fontSize: 14 }}>{(discount).toLocaleString('en-US', {style: 'currency',  currency: 'USD'})}</td>
                                        <td className="text-right" style = {{fontSize: 14 }}>{(previousBalance).toLocaleString('en-US', {style: 'currency',  currency: 'USD'})}</td>
                                        <td className="text-right" style = {{fontSize: 14 }}>{(realAmount).toLocaleString('en-US', {style: 'currency',  currency: 'USD'})}</td>
                                        <td className="text-right" style = {{fontSize: 14 }}>{(actualBalance).toLocaleString('en-US', {style: 'currency',  currency: 'USD'})}</td>
                                    </tr>
                                    </tbody>
                            </table>
                            </div>
                    </div>             
                     
                    <br />
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div className="row  text-center">
                                        <div className="col-md-6" >
                    __________________________________________________________________________________________________________
                    <br/>
                    <br/>
                    <br/>
                    <b style = {{fontSize: 17}}>
                    Firma de recibido
                    </b>
                    </div>
                    
                  
                    </div>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>
    </div>

</div>
</main>);
}}
class MonthlyPaymentReceipt extends React.Component {
    render() { 
        const {code,createdAt,amount,amountWord ,discount,penalty,realAmount,previousBalance,actualBalance,currency,currencyExchange,BlockAndLot,clientName,clientAddress, paymentMethod} = this.props
        return(
            <div>
            <div style={{ display: "none" }} >
               
            <ComponentToPrint code = {code} 
                createdAt = {createdAt} amount = {amount} 
                amountWord = {amountWord} 
                discount = {discount} 
                penalty= {penalty}
                realAmount = {realAmount}
                previousBalance = {previousBalance}
                actualBalance = {actualBalance}currency = {currency} 
                currencyExchange ={currencyExchange}
                BlockAndLot = {BlockAndLot}  
                clientName= {clientName}  
                clientAddress = {clientAddress}  
                paymentMethod = {paymentMethod}
                ref={(el) => (this.componentRef = el) } />
            </div>

            <ReactToPrint
            trigger={() => <button className="btn btn-primary">Imprimir</button>}
            content={() => this.componentRef}
            />
             
        </div>);
    }
}
export default MonthlyPaymentReceipt; 