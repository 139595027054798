import React from 'react' 

class bankReconciliationList extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            bankReconciliation : [],
            confirmed : false,
            confirmation : 0,
        }
    this.handleChange = this.handleChange.bind(this);
    }
    
async handleChange(event) {
    try {
        event.preventDefault();
    }
    catch (error){
    }
}

displaymensaje(){
    if (this.props.mensajeTabla === "Cargando . . ."){
        return(<div className="spinner-border spinner-border-sm" role="status" />);
    }
    else{
       return( <h3>{this.props.mensajeTabla}</h3>);
    }
}
setView() {
    
    const { bankReconciliation } = this.props;
    if( bankReconciliation.length === 0)
    {return (<div className="box">
                <div className="d-flex justify-content-center aling-items-center ">
                {this.displaymensaje()}
                </div>
            </div>);}
   
    
    return(<table className="table table-striped table-sm table-hover">
    <thead>
    <tr>
        <th>Terreno</th>
        <th>Fecha creacion</th>
        <th>Cliente</th>
        <th>Codigo</th>
        <th>Abono</th>
        <th>Descuento</th>
        <th>Penalizacion</th>
        <th>Precio total</th>
        <th>Observacion</th>
        <th>Tipo de pago</th>
        <th>
        <div className="text-md-center"> Confirmacion
        </div>
        </th>
    </tr>
    </thead>
    <tbody>
    {bankReconciliation.map((reconciliation) => (
        <tr key = {reconciliation.paymentId}>
            <td>{reconciliation.landCode}</td>
            <td>{reconciliation.createdAt.substring(0,10)}</td>
           <td>{''.concat(reconciliation.clientFirstName, ' ', reconciliation.clientLastName)}</td>
           <td>{reconciliation.paymentCode}</td>
            <td>{reconciliation.amount}</td>
            <td>{reconciliation.discount}</td>
            <td>{reconciliation.penalty}</td>
            <td>{reconciliation.realAmount}</td>
            <td>{reconciliation.observations}</td>
            <td>{reconciliation.enterType}</td>
            <td>
            <div className="text-md-center">
            <input name="confirmed" type="checkbox" className="form-check-input" 
             checked={reconciliation.confirmation} value ={reconciliation.confirmation} onChange ={(e) => {this.props.handleMonthlyPayment(e, reconciliation.paymentId, reconciliation.realAmount, reconciliation.confirmation, reconciliation.enterType)}} /> 
             </div>
          
            </td>

        </tr>
    ))}
    </tbody> 
    </table>)
}
render(){

 
        return(
            <div className="card">
            <div className="card-header">
            <div className="row">
            </div>
            </div>
            <div className="card-body">
            
            <div className="row">
            <div className="table-responsive text-nowrap">
                { this.setView()}
            </div>
            </div>
            </div>
            </div>
        );
    }
   
}



export default bankReconciliationList;                   