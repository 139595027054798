import React from 'react';
import NotificationsController from '../../../NotificationsController'
import LandInventoryHelper from '../../../../helpers/reports/reports.helper'
import TableController from '../../../reports.ReactTableController'
import ReactExport from 'react-export-excel'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class LandInventoryReport extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            LandInventoryData: [],
            mensajeTabla : ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.searchLandInventoryData = this.searchLandInventoryData.bind(this); 
    }
    componentDidMount(){
        this.searchLandInventoryData();
    }
    async handleChange(event) {
        event.preventDefault();
        this.setState({
           [event.target.name]: event.target.value
         });
      }

      displaymensaje(){
        if (this.state.mensajeTabla === "Cargando . . ."  ){
            return(<div className="spinner-border spinner-border-sm" role="status" />);
        }
        else{
           return( <h3>{this.state.mensajeTabla}</h3>);
        }
    }
    async searchLandInventory(event){
        event.preventDefault();
        this.searchLandInventoryData();
    }
      async searchLandInventoryData(){
        try{
            this.setState({
                mensajeTabla : 'Cargando . . .'
            });
            const LandInventoryResponse = await new LandInventoryHelper().getLandsInventory(localStorage.token);
            if(LandInventoryResponse.data.length === 0)
            {
                this.setState({
                    mensajeTabla : 'No hay datos para mostrar'
                });
            }
            else 
            {
                this.setState({
                    LandInventoryData : LandInventoryResponse.data,
                    mensajeTabla : ''
                });
            }
    
            new NotificationsController().createNotification(LandInventoryResponse.data.message,LandInventoryResponse.status.toString())

        }catch(err){
            new NotificationsController().createNotification(err.response.data.message,err.response.status.toString() );
        }
    
      }
      setView (){
        if( this.state.mensajeTabla.length > 0 )
        {return (
                    <div className="d-flex justify-content-center aling-items-center ">
                    {this.displaymensaje()}
                    </div>
               );}
        else
return(  <TableController  data = {this.state.LandInventoryData} 
    Header = {"Busqueda de terrenos"} 
    columns = {[
                    {
                        Header: 'Codigo',
                        accessor: 'code',
                    },
                    {
                        Header: 'Manzana',
                        accessor: 'block',
                    },
                    {
                        Header: 'Lote',
                        accessor: 'lot',
                    },
                    // {
                    //     Header: 'Largo',
                    //     accessor: 'height',
                    // },
                    // {
                    //     Header: 'Ancho',
                    //     accessor: 'width',
                    // },
                    {
                        Header: 'Superficie M2',
                        accessor: 'surfaceM2',
                        
                    },   
                    {
                        Header: 'Precio por M2',
                        accessor: 'pricePerM2',
                        
                    },            
                    {
                        Header : 'Precio total',
                        accessor : 'totalPrice' 
                    },
                    {
                        Header: 'Estatus',
                        accessor: 'status',
                    }]} 

                    ></TableController>  )
      }

      render() {
        return( <main className = "content">
        <div className="container-fluid p-0">
            
        <div className="card">
        <div className="card-header">
        <h5 className="card-title"> Inventario de terrenos</h5>
            <ExcelDownloadButton LandInventoryData= {this.state.LandInventoryData}></ExcelDownloadButton>
        </div>
        <div className="card-body">
    
      {this.setView()}
                                    </div>
                                    </div>
                                  </div>
                                  </main>
            );
    }
    }
export default LandInventoryReport;

class ExcelDownloadButton extends React.Component {

    render() {
       
        return (

            <ExcelFile filename = "Inventario de terrenos" element={
            <a className="btn btn-success">
            <i className="align-middle mr-2 fas fa-file-excel" data-feather="sliders" />{' '}
					<span className="align-middle">Descargar</span></a>}>


                <ExcelSheet data={this.props.LandInventoryData} name="Terrenos">
                    <ExcelColumn label="Codigo" value="code"/>
                    <ExcelColumn label="Manzana" value="block"/>
                    <ExcelColumn label="Lote" value="lot"/>
                    <ExcelColumn label="Largo" value="height"/>
                    <ExcelColumn label="Ancho" value="width"/>
                    <ExcelColumn label="Precio por M2" value="pricePerM2"/>
                    <ExcelColumn label="Precio total" value="totalPrice"/>
                    <ExcelColumn label="Estatus" value="status"/>
                </ExcelSheet> 
            </ExcelFile>
        );
    }
}