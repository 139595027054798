import axios from 'axios';
import constants from '../../config/constants';

class monthlyPaymentsHelper{
    async makeCall(method, url, data,token = null) {
	return await axios({
			method,
			url,
			data,
			headers: {
				'x-access-token': token
			}
		});
	};
		
	async getPayments(land, token){
		const method = 'GET';
		const url = `${constants.BACKEND_URL}/monthlyPayment/payments/${land}`;
		return this.makeCall(method, url, {}, token);
	};

	async getMonthlyPayments(token){
		const method = 'GET';
		const url =`${constants.BACKEND_URL}/monthlyPayment`;
		return this.makeCall(method, url, {}, token);
	};
	async addMonthlyPayment(token, body ){
		const method = 'POST';
		const url =`${constants.BACKEND_URL}/monthlyPayment`;
		return this.makeCall(method, url, body, token);
	};
	async updateMonthlyPayment(token, body){
		const method = 'PUT';
		const url = `${constants.BACKEND_URL}/monthlyPayment`
		return this.makeCall(method, url, body, token);
	};
	async getSoldLands(token){
		const method = 'GET'
		const url =`${constants.BACKEND_URL}/monthlyPayment/lands`
		
		return this.makeCall(method, url, {}, token);

	};
	async getRecipts(token, land){
		const method = 'GET'
		const url =`${constants.BACKEND_URL}/monthlyPayment/recipts/${land}`
		return this.makeCall(method, url, {}, token);
	}
	async getPaginatedRecipts(token, land, pageSize, pageNumber ){
		const method = 'GET'
		const url =`${constants.BACKEND_URL}/recipts/land/paginated/${land}/${pageSize}/${pageNumber}`
		return await this.makeCall(method, url, {}, token);
	}

	async getBalance(token, land, code){
		const method = 'GET'
		const url =`${constants.BACKEND_URL}/monthlyPayment/balance/${land}/${code}`
		return this.makeCall(method, url, {}, token);
	};
	
    

}
export default monthlyPaymentsHelper