import React from 'react';
import bankReconciliationHelper from '../../helpers/bankReconciliation/bankReconciliation.request'
import NotificationsController from '../NotificationsController'
import BankReconciliationList from './bankReconciliationList'

class bankReconciliationForm extends React.Component{
    constructor(props){
        super(props);
        var dateObj = new Date();
        var dateString = ''.concat(dateObj.getFullYear().toString(),'-',String(dateObj.getMonth() + 1).padStart(2, '0'), '-',String(dateObj.getDate()).padStart(2, '0'));

    this.state = {
        bankReconciliation : [],
        initialDate : dateString,
        finalDate : dateString,
        realAmount: 0 , 
        observations : '', 
        paymentDate : dateString, 
        bankReference : '',
        Payments : [],
        mensajeTabla : '',

    }
    this.handleChange = this.handleChange.bind(this);
    this.searchConciliationList  = this.searchConciliationList.bind(this);
    this.handleMonthlyPayment = this.handleMonthlyPayment.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
}

async componentDidMount(){

}

async handleMonthlyPayment(event, paymentId, realAmount, confirmation , enterType){
    var totalPayment = 0 
    const value = event.target.checked 

    this.state.bankReconciliation.filter(reconciliation => reconciliation.paymentId === paymentId)[0].confirmation = value

    if (!confirmation){
         totalPayment =this.state.realAmount + realAmount
         this.state.Payments.push([paymentId, enterType]);
    }else{
        totalPayment =this.state.realAmount - realAmount
        this.state.Payments.pop([paymentId, enterType]);
    }

    this.setState({
        realAmount : totalPayment,
        confirmation : value   
    })

}

async handleSubmit(event){
	 event.preventDefault();
    try{
 
    if(this.state.realAmount === 0){
            new NotificationsController().createNotification('Debe agregar pagos a la conciliacion.', '501')
    }
    else{
        const body = {
            amount : this.state.realAmount, 
            observations : this.state.observations, 
            paymentDate : this.state.paymentDate, 
            bankReference : this.state.bankReference,
            Payments : this.state.Payments
        }
       const response = await new bankReconciliationHelper().postBankPayment(localStorage.token,  body);
       new NotificationsController().createNotification( response.data.message, response.status.toString());
    }
    }catch(error){
        new NotificationsController().createNotification('','501');
    }
    
}

async handleChange(event) {
    event.preventDefault();
    await this.setState({
       [event.target.name]: event.target.value
     });
  }


  async subSearchList(){
    const initialDate = this.state.initialDate
    const finalDate = this.state.finalDate
    try{
        this.setState({
            mensajeTabla : 'Cargando . . .',
            Payments : [],
            realAmount : 0
        });
       
        const bankReconciliationResponse  = await new bankReconciliationHelper().getBankReconciliation(localStorage.token, initialDate, finalDate);
      
        this.setState({
         initialDate : initialDate,
         finalDate  : finalDate ,
         bankReconciliation : bankReconciliationResponse.data 
        })

        if(this.state.bankReconciliation.length === 0)
        this.setState({
            mensajeTabla : 'No hay datos para mostar'
        });
        
         new NotificationsController().createNotification(bankReconciliationResponse.data.message, bankReconciliationResponse.status.toString())
        
       
    }catch(err){
         new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())
    }
  }

 async searchConciliationList(event) {
    event.preventDefault();
    this.subSearchList();
}



    render(){
        return (
            <main className = "content">
                 <div className="container-fluid p-0">
                     
                     <div className="card">
                     <div className="card-header">
                     <h5 className="card-title">Encabezados</h5>
                    </div>
                    <div className="card-body">
                     <form onSubmit={this.handleSubmit}>
                     <div className="form-row">
                     <div className="form-group col-md-3">
                        <label className="form-label">Fecha de pago</label>
                        <input name="paymentDate" 
                               type="date" 
                               className="form-control datetimepicker-input"
                               value={this.state.paymentDate} 
                               onChange = {this.handleChange}/>  
                    </div>
                    <div className="form-group col-md-3">
                        <label className="form-label">Observaciones</label>
                        <input 
                                type="text" 
                                className="form-control" 
                                name="observations" 
                                value= {this.state.observations}
                                onChange = {this.handleChange}/>
                    </div>
                    
                    <div className="form-group col-md-3">
                        <label className="form-label">Referencia bancaria</label>
                        <input 
                                type="text" 
                                className="form-control" 
                                name="bankReference" 
                                value= {this.state.bankReference}
                                onChange = {this.handleChange}/>
                    </div>
                    <div className="form-group col-md-3">
                        <label className="form-label">Pago total</label>
                        <input 
                                className="form-control" 
                                name="realAmount" 
                                type="number" 
                                value= {(this.state.realAmount).toFixed(2)}
                                onChange = {this.handleChange}
                                disabled/>
                    </div>
                         </div>
                         <div className="form-row">
                         <button type="submit" className="btn btn-primary">Agregar</button>
                        </div>
                     </form>
                     </div>
                    </div>
                 <div className="card">
                 <div className="card-header">
            <h5 className="card-title">Conciliacion de pagos</h5>
          </div>
                 <div className="card-body">
                 <form >
                    <div className="form-row">
              
                    <div className="form-group col-md-3">
                        <label className="form-label">Fecha de venta</label>
                        <input name="initialDate" type="date" className="form-control datetimepicker-input" value={this.state.initialDate} onChange = {this.handleChange}/>        
                    </div> 
                    <div className="form-group col-md-3">
                        <label className="form-label">Fecha de venta</label>
                        <input name="finalDate" type="date" className="form-control datetimepicker-input" value={this.state.finalDate} onChange = {this.handleChange}/>
                    </div> 

                    <div className="form-group col-md-1">
                    <div className = "form-group">
                    <div className="vertical-center">
                    <button  className="btn btn-primary" onClick={ this.searchConciliationList}>Buscar</button>

                    </div> 
                    </div> 
                    </div> 
                    </div> 
                </form>
                
      
                <BankReconciliationList mensajeTabla  = {this.state.mensajeTabla } bankReconciliation = {this.state.bankReconciliation} handleMonthlyPayment = {this.handleMonthlyPayment}>
                </BankReconciliationList>
                </div>
                </div>
                </div>
            </main>
         );
    }
}
export default bankReconciliationForm;
