import React, { Fragment } from 'react';
import UserHelper from '../helpers/user/user.request';
import { NotificationManager } from 'react-notifications';
import NotificationsController from '../components/NotificationsController'
const emailRegExp = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);


const formValid = formErrors => {
	let valid = true;

	//validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	// Object.values(rest).forEach(val => {
	// 	val === null && (valid = false);
	// });
	return valid;
}
class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			firstName: '',
			lastName: '',
			password: '',
			formErrors: {
				firstName: '',
				lastName: '',
				email: '',
				password: ''
			}
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		event.preventDefault();

		var name = event.target.name
		var value = event.target.value

		let formErrors = this.state.formErrors;

		switch (name) {
			case "firstName":
				formErrors.firstName = value.length < 3 ? "Falta llenar el nombre" : ""
				break;
			case "lastName":
				formErrors.lastName = value.length < 3 ? "Falta llenar apellido" : ""
				break;
			case "email":
				formErrors.email = !emailRegExp.test(value) ? "Direccion de correo electronico no valida" : ""
				break;
			case "password":
				formErrors.password = value.length < 5 ? "Contraseña de 5 caracteres minimo" : ""
				break;
			default:
				break;
		}

		this.setState({
			[event.target.name]: event.target.value
		});
		this.setState({ formErrors, name: value })
	}

	async handleSubmit(event) {
		event.preventDefault();
		try {
			if (formValid(this.state.formErrors)) {
				const body = {
					firstName: this.state.firstName,
					lastName: this.state.lastName,
					email: this.state.email,
					password: this.state.password,
					rol : "Capturista"
				};

				const response = await new UserHelper().registerUser(body, localStorage.token);
				new NotificationsController().createNotification( response.data.message, response.status.toString());
			}
			else {
				new NotificationsController().createNotification( 'Faltan errores por corregir', "400");
			}
		} catch (err) {
			new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	
		}
	}

	render() {
		const { formErrors } = this.state;
		
		return (
		
				<div className="main d-flex justify-content-center w-100">
					<main className="content d-flex p-0">
						<div className="container d-flex flex-column">
							<div className="row h-100">
								<div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
									<div className="d-table-cell align-middle">
										<div className="text-center mt-4">
											<h1 className="h2">Usuario</h1>
											<p className="lead">Agregar usuarios que tienen acceso al sistema</p>
										</div>

										<div className="card">
											<div className="card-body">
												<div className="m-sm-4">
													<form onSubmit={this.handleSubmit}>
														<div className="form-group">
															<label>Nombre:</label>
															<input
																className={formErrors.firstName.length > 0 ? "form-control is-invalid" : "form-control form-control-lg"}
																name="firstName"
																type="text"
																placeholder="Introduzca nombre"
																value={this.state.firstName}
																onChange={this.handleChange}
															/>
															{formErrors.firstName.length > 0 && (<span className="text-danger">{formErrors.firstName}</span>)}
														</div>
														<div className="form-group">
															<label>Apellido:</label>
															<input
																className={formErrors.lastName.length > 0 ? "form-control is-invalid" : "form-control form-control-lg"}
																name="lastName"
																type="text"
																placeholder="Introduzca apellido"
																value={this.state.lastName}
																onChange={this.handleChange}
															/>
															{formErrors.lastName.length > 0 && (<span className="text-danger">{formErrors.lastName}</span>)}
														</div>
														<div className="form-group">
															<label>Correo:</label>
															<input
																className={formErrors.email.length > 0 ? "form-control is-invalid" : "form-control form-control-lg"}
																name="email"
																type="text"
																placeholder="Introduzca correo"
																value={this.state.email}
																onChange={this.handleChange}
															/>
															{
																formErrors.email.length > 0 && (<span className="text-danger">{formErrors.email}</span>)}
														</div>
														<div className="form-group">
															<label>Contraseña:</label>
															<input
																className={formErrors.password.length > 0 ? "form-control is-invalid" : "form-control form-control-lg"}
																name="password"
																type="password"
																placeholder="Introduzca contraseña"
																value={this.state.password}
																onChange={this.handleChange}
															/>
															{formErrors.password.length > 0 && (<span className="text-danger">{formErrors.password}</span>)}
														</div>
														<div className="text-center mt-3">
															<input
																type="submit"
																value="Agregar"
																className="btn btn-lg btn-primary"
															/>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			
		);
	}
}

export default Register;
