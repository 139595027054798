import React from 'react';
import LandHelper from '../../helpers/land/land.request'
import EditLand from './editLand'
import NotificationsController from '../NotificationsController'
import ReactTable from '../reports.ReactTableController'
class ListLands extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            lands: [], 
            showEdit: false
        };

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    async handleDelete(id, status){
        // try{
        //     if(status === 'Disponible'){

        //         this.setState({
        //             lands : this.state.lands.filter(land => land.id !== id)
        //         });
                
        //         await new LandHelper().deleteLand(id, localStorage.token);
        //     }
        //     else {
              new NotificationsController().createNotification("No se pueden eliminar terrenos en operacion.", "401");
        //     }
        // } catch(error){
        //     new NotificationsController().createNotification(error.message, "400")
        // }
    }
    componentDidMount(){
        this.getLands();
    }

    handleEdit(lands){
    
        this.setState({
            showEdit : true,
            id : lands.id,
            code : lands.code,
            block : lands.block,
            height : lands.height,
            width : lands.width,
            surfaceM2 : lands.surfaceM2,
            totalPrice : lands.totalPrice,
            pricePerM2 : lands.pricePerM2,
            status : lands.status,
            lot : lands.lot
        });
    

        this.setView();
}

    getLands = async () => {

        try{
            const response = await new LandHelper().getLands(localStorage.token);
            this.setState({
                lands: response.data
            });
            new NotificationsController().createNotification("Registro encontrados", "201");
        }catch(error){
            new NotificationsController().createNotification(error.message, "400")
        }
    }

    setView(){
        if(this.state.showEdit){
            return(<EditLand 
                id = {this.state.id}
                          code = {this.state.code} 
                          block = {this.state.block} 
                          height = {this.state.height} 
                          width = {this.state.width} 
                          surfaceM2 = {this.state.surfaceM2} 
                          totalPrice = {this.state.totalPrice} 
                          pricePerM2 = {this.state.pricePerM2}
                          status = {this.state.status} 
                          lot = {this.state.lot}>
                    </EditLand>);
        } else{

return(	<main className="content">
			
<div className="container-fluid p-0">
    <div className="row">
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Lista de terrenos</h5>
                    <h6 className="card-subtitle text-muted">
                        En el siguiente link puede agregar terrenos nuevos{' '}
                        <a href="/agregarTerreno" rel="noopener noreferrer nofollow">
                            Aquí
                        </a>
                    </h6>
                </div>
                <div className="card-body">
                <div className="table-responsive text-nowrap">
                    		<ReactTable
                data = {this.state.lands}
                Header = {"Terrenos"}
                columns = {
                    [
                    
                        {
							Header : "Codigo",
                            accessor : "code"
                        }, 
						
                        {
							Header : "Manzana",
                            accessor : "block"
                        },
						{
							Header : "Lote",
                            accessor : "lot"
                        },
						{
							Header : "Precio por M2",
                            accessor : "pricePerM2"
                        },
						{
							Header : "Superficie M2",
                            accessor : "surfaceM2"
                        },
						{
							Header : "Costo total",
                            accessor : "totalPrice"
                        },
						{
							Header : "Estatus",
                            accessor : "status"
                        },
						{
							Header : 'Editar',
                            accessor: 'button1',
                            Cell: ({cell}) => (
                            <button className="btn btn-primary btn-sm" 
                            onClick={() =>this.handleEdit(cell.row.original)}> Editar
                            </button>
                                )
                        },
						// {
						// 	Header : 'Eliminar',
                        //     accessor: 'button2',
                        //     Cell: ({cell}) => (
                        //     <button className="btn btn-danger btn-sm" 
                        //     onClick={() =>this.handleDelete(cell.row.original.status)}> Editar
                        //     </button>
                        //         )
                        //},

					
				]
			}></ReactTable>
                    {/* <table className="table table-sm">
                        <thead>
                            <tr>
                                <th>Codigo</th>
                                <th>Manzana</th>
                                <th>Lote</th>
                                <th>Precio por M2</th>
                                <th>Superficie M2</th>
                                <th>Costo total</th>
                                <th>Status</th>
                                <th>Accion</th>
                            </tr>
                        </thead>
                        <tbody> 
                            {this.state.lands.map((land) => (
                                <tr key={land.id}>
                                    <td>{land.code}</td>
                                    <td>{land.block}</td>
                                    <td>{land.lot}</td>                                 
                                    <td>{land.pricePerM2}</td>
                                    <td>{land.surfaceM2}</td>
                                    <td>{land.totalPrice}</td>
                                    <td>{land.status}</td>
                                    <td>
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-sm"
                                            onClick={() => this.handleEdit(land)
                                            }
                                        >
                                            Editar
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-danger btn-sm"
                                            onClick={() => this.handleDelete(land.id, land.status)}
                                        >
                                            Eliminar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody> 
                    </table> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


</main>);
        }

    }
       render(){
           return <div>{this.setView()}</div>
       } 
    }
export default ListLands;


