import React from 'react';
class vendorForm extends React.Component {
    constructor(props){
        super(props);
    }
    render(){
        const { formErrors } = this.props;
        return (
            <main className="content">
				<div className="container-fluid p-0">
					<div className="row">
                <div className="col-md-12">
                <div className="card">
                <div className="card-header">
                    <h1 className="card-title">Creacion de vendedores</h1>
                    {/* <h6 className="card-subtitle text-muted">Bootstrap column layout.</h6> */}
                </div>
                <div className="card-body">
                    <form onSubmit={this.props.handleSubmit}> 
                        <div className="form-row">
                            
                            <div className="form-group col-md-2">
                            <label>Codigo</label>
                            <input 
                                type="text" 
                                className= {formErrors.code.length > 0 ? "form-control is-invalid" :"form-control" }
                                name="code" 
                                placeholder="Codigo de identificacion " 
                                value={this.props.code}
                                onChange={this.props.handleChange}/>
                                 {formErrors.code.length > 0 && (<span className="text-danger">{formErrors.code}</span>)}
                            </div>

                            <div className="form-group col-md-2">
                            <label> RFC</label>
                            <input 
                                type="text" 
                                className= {formErrors.rfc.length > 0 ? "form-control is-invalid" :"form-control" }
                                name="rfc" 
                                placeholder="RFC" 
                                value={this.props.rfc}
                                onChange={this.props.handleChange}/>
                                {formErrors.rfc.length > 0 && (<span className="text-danger">{formErrors.rfc}</span>)}
                            </div>

                            <div className="form-group col-md-4">
                            <label> Nombre</label>
                            <input 
                                type="text" 
                                className= {formErrors.firstName.length > 0 ? "form-control is-invalid" :"form-control" }
                                name="firstName" 
                                placeholder="Nombre" 
                                value={this.props.firstName}
                                onChange={this.props.handleChange}/>
                                  {formErrors.firstName.length > 0 && (<span className="text-danger">{formErrors.firstName}</span>)}
                            </div>
                            
                            <div className="form-group col-md-4">
                            <label >Apellido</label>
                            <input 
                                type="text" 
                                className= {formErrors.lastName.length > 0 ? "form-control is-invalid" :"form-control" }
                                name="lastName"
                                placeholder="Apellido" 
                                value={this.props.lastName}
                                onChange={this.props.handleChange}/>
                           {formErrors.lastName.length > 0 && (<span className="text-danger">{formErrors.lastName}</span>)}
                            </div>
                       
                        </div>
    
                        <div className="form-row">
                        <div className="form-group col-md-6">
						<label> Correo</label>
						    <input 
                            type="text" 
                            className= {formErrors.email.length > 0 ? "form-control is-invalid" :"form-control" }
                            name="email" 
                            placeholder="Email" 
                            value={this.props.email}
                            onChange={this.props.handleChange}/>
						    {formErrors.email.length > 0 && (<span className="text-danger">{formErrors.email}</span>)}
                        </div>
                        <div className="form-group col-md-2">
                        <label >Codigo postal</label>
                        <input 
                            type="text" 
                            className= {formErrors.zipCode.length > 0 ? "form-control is-invalid" :"form-control" }
                            name="zipCode"
                            value={this.props.zipCode}
                            placeholder="Codigo postal" 
                            onChange={this.props.handleChange}/> 
                            {formErrors.zipCode.length > 0 && (<span className="text-danger">{formErrors.zipCode}</span>)}
                        </div>    
                        <div className="form-group col-md-4">
                        <label >Numero telefono</label>
                        <input 
                            type="text" 
                            className= {formErrors.phoneNumber.length > 0 ? "form-control is-invalid" :"form-control" } 
                            name="phoneNumber"
                            value={this.props.phoneNumber}
                            placeholder="Numero de telefono" 
                            onChange={this.props.handleChange}/>
                            {formErrors.phoneNumber.length > 0 && (<span className="text-danger">{formErrors.phoneNumber}</span>)}
                        </div>                           
                        </div>

                    
                        <button type="submit" className="btn btn-primary">Agregar</button>
                    </form>
                 </div>
             </div>
         </div>
         </div>
         </div>
         </main>
        );}

} export default vendorForm;