import React from 'react';
import ReciptContractList from './ReciptsContractList'
import SaleContractForm from './SaleContractForm'
import CristianModal from '../CristianModal'
import NotificationsController from '../NotificationsController'
import AuthenticateHelper from '../../helpers/authenticate/authenticate.request';
import SaleContractHelper from '../../helpers/saleContract/saleContract.request'

class editSaleContract extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            // land : this.props.land,
            land : '',
            lands : [],
            client : '',
            contractNumber : '',
            creationDate : '',
            paymentMethod : 'Pago en parcialidades o diferido',
            monthlyPayment : '',
            vendor : '',
            BlockAndLot : '',
            SurfaceM2 : '',
            LandTotalCost : '',
            Advance : '',
            currentCost :'',
            numberOfMonths : '',
            clientAddress : '',

            showModal : false,
            
            inside : true
        }
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleNumberOfMonths = this.handleNumberOfMonths.bind(this);
        this.changeState = this.changeState.bind(this);
    }

    getHeaderInfo(){
        const landInfo = this.props.landInfo
        var creationDate = new Date(this.props.creationDate)
        var newCreationDate = ''.concat(creationDate.getFullYear().toString(),'-',String(creationDate.getMonth() + 1).padStart(2, '0'), '-',String(creationDate.getDate()).padStart(2, '0'));
 
        this.setState({
             lands : this.props.landInfo,
             land : landInfo[0].id,
             idContract : this.props.idContract,
             vendor : ''.concat(landInfo[0].Vendors[0].firstName , ' ', landInfo[0].Vendors[0].lastName),
             client : ''.concat(landInfo[0].Clients[0].firstName , ' ' , landInfo[0].Clients[0].lastName),
             clientAddress : ''.concat(landInfo[0].Clients[0].address, ' C.P ',landInfo[0].Clients[0].zipCode),
             BlockAndLot : 'Manzana: '.concat(landInfo[0].Lands[0]["block"],' / Lote: ',  landInfo[0].Lands[0]["lot"]),
             
             SurfaceM2 : landInfo[0].Lands[0]["surfaceM2"],
             LandTotalCost : landInfo[0].Lands[0]["totalPrice"],
             Advance : landInfo[0]["advance"],
             currentCost :landInfo[0].Lands[0]["totalPrice"] - landInfo[0]["advance"],
             numberOfMonths : this.props.numberOfMonths,
             monthlyPayment : this.props.monthlyPayment,
             creationDate : newCreationDate ,
             contractNumber: this.props.contractNumber,
             paymentMethod : this.props.paymenthMethod
 
         });
    }
   componentDidMount(){
       this.getHeaderInfo();  
   }

   async handleModal(event){
    event.preventDefault();
    try {
        if(localStorage.rol !== "Supervisor"){
            this.setState({
                showModal : true
            });
        }
        else 
        {
            this.update();
            this.setState({
                inside: true
            });
  
        }
    }catch(err){
        new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	    
    }
   }

   async handleSubmit(){
    try {
        const body = {
            email: this.state.email,
            password: this.state.password
        };
    
        const response = await new AuthenticateHelper().loginUser(body);
     
        if (response.data && response.data.token) {
            
           if (response.data.rol === "Supervisor") 
           {
                this.update();
           }
           else
           {
                new NotificationsController().createNotification("Son necesarias las credenciales del supervisor.", '401');
           };
        }
       
    }catch(err){
          new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	;	
    }
   }

   async handleChange(event){
       event.preventDefault();
        this.setState({
            [event.target.name] : event.target.value,
          
        });
   }

   async handleNumberOfMonths(event){
    this.handleChange(event);
    this.setState({
        numberOfMonths : Math.ceil(this.state.currentCost / event.target.value ),
    });
  }

 
   async update(){
     try {

         const body = {
             contractNumber : this.state.contractNumber,
             newDate : this.state.creationDate,
             paymenthMethod : this.state.paymentMethod,
             monthlyPayment : this.state.monthlyPayment,
             numberOfmonths : this.state.numberOfMonths
            }
            const response =  await new SaleContractHelper().updateSaleContract(localStorage.token, this.state.idContract, body);
            
            new NotificationsController().createNotification(response.data.message, response.status.toString());
            
            await this.changeState(true);
            this.setState({
                showModal : false
            });
        }catch(err)
        {
            new NotificationsController().createNotification(err.response.data.message,err.response.status.toString());	
        }

 
   }
    async hideModal(){
        this.setState({
            showModal : false
        });
    }
    async changeState(value){
        this.setState({
            inside : value
        });
    };

    render() {


                return (
    <div>
        <main className = "content">
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col-md-12">
                <CristianModal showModal={this.state.showModal} handleSubmit = {this.handleSubmit} hideModal={this.hideModal} handleChange = {this.handleChange} />
                
            
                    <SaleContractForm 
                        handleSubmit = {this.handleModal} 
                        handleNumberOfMonths = {this.handleNumberOfMonths}
                        handleChange = {this.handleChange}
                        
                        land = {this.state.land}
                        lands = {this.state.lands}

                        contractNumber = {this.state.contractNumber}
                        creationDate = {this.state.creationDate} 
                        paymentMethod = {this.state.paymentMethod}
                        monthlyPayment ={this.state.monthlyPayment}
                        
                        vendor =  {this.state.vendor}                           
                        client = {this.state.client}
                        BlockAndLot = {this.state.BlockAndLot}
                        SurfaceM2 = {this.state.SurfaceM2}

                        LandTotalCost = {this.state.LandTotalCost}
                        Advance = {this.state.Advance}
                        currentCost = {this.state.currentCost}
                        numberOfMonths = {this.state.numberOfMonths}
                        showButton = {false}
                        showModal = {this.state.showModal}
                         >
                            
                    </SaleContractForm >
                   
                    <ReciptContractList
                    land = {this.props.landInfo[0].land} 
                    clientAddress  ={this.state.clientAddress}
                    client = {this.state.client}
                    inside = {this.state.inside}
                    changeState = {this.changeState}></ReciptContractList>
                    
                </div>
            </div>
        </div>
        </main>
    </div>
        );
    
    }
}
export default editSaleContract