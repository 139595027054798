import React from 'react';
class Unauthorized extends React.Component{
render(){
    return(
        <body data-theme="default" data-layout="fluid" data-sidebar-position="left" data-sidebar-behavior="sticky">
        <div className="main d-flex justify-content-center w-100">
            <main className="content d-flex p-0">
                <div className="container d-flex flex-column">
                    <div className="row h-100">
                        <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">
    
                                <div className="text-center">
                                    <h1 className="display-1 font-weight-bold">401</h1>
                                    <p className="h1">No tiene acceso a esta pagina.</p>
                                    <p className="h2 font-weight-normal mt-3 mb-4">La pagina a la que se trata de accesar requiere permisos del administrador.</p>
                                    <a href="/" className="btn btn-primary btn-lg">Regresar</a>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    

    
     </body>
    );
}

} export default Unauthorized