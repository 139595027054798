import axios from 'axios';
import constants from '../../config/constants';

class cancelHelper {
    async makeCall(method, url, data,token = null) {
		return axios({
			method,
			url,
			data,
			headers: {
				'x-access-token': token
			}
		});
	}

    async landCancellation(land, client, token) {
        const method = 'POST';
        const url = `${constants.BACKEND_URL}/landCancellation/cancellLand/${land}/${client}`
     
        return this.makeCall(method, url, {}, token);
    }

    async landCancelCancellation(id, token){
        const method = 'POST';
         const url =`${constants.BACKEND_URL}/landCancellation/cancellCancelationLand/${id}`
        return this.makeCall(method, url, {}, token);
    }
    async getCancellations(token){
        const method = 'GET';
        const url = `${constants.BACKEND_URL}/landCancellation/`
        return this.makeCall(method, url, {}, token);
    }
    async payCancellations(token, data){
        const method = 'PUT';
        const url = `${constants.BACKEND_URL}/landCancellation/updateCancellationPayment/`;
        return this.makeCall(method, url, data, token)
    }
}
export default cancelHelper;