import React from 'react'
import DrawHelper from '../../helpers/draw/draw.request'

class drawForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            processTypes : ['Normal','Estudio socioeconomico']
        }
        }

    render() {
        const { formErrors } = this.props;
        return (
     
                    <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">Datos generales</h5>
                        <h6 className="card-subtitle text-muted"> Encabezado de apartado de los terrenos </h6>
                    </div>
                    <div className="card-body">
                        <form onSubmit={this.props.handleSubmit}>
                            
                        <div className="form-row">
                    
                        <div className="form-group col-md-3">
                            <label> Terreno: </label>
                                <select 
                                className={formErrors.land.length > 0 ? "form-control is-invalid" : "custom-select mb-3"} name="land" value={this.props.land} onChange={this.props.handleLand}  > 
                                <option value = {0} selected>Seleccione un terreno</option>
                                {this.props.lands.map((land) => <option value={land.id}> {land.code} </option>) }
                                </select>
                                {formErrors.land.length > 0 && (<span className="text-danger">{formErrors.land}</span>)}							
                        </div>

                        <div className="form-group col-md-3">
                            <label> Cliente: </label>
                           
                                <select className={formErrors.client.length > 0 ? "form-control is-invalid" : "custom-select mb-3"}   name="client" value={this.props.client}  onChange = {this.props.handleChange}  > 
                                <option value = {0}  selected>Seleccione un cliente</option>
                                {this.props.clients.map((client) => <option value={client.id}> {''.concat(client.firstName,' ',client.lastName)} </option>) }
                                </select>
                                {formErrors.client.length > 0 && (<span className="text-danger">{formErrors.client}</span>)}
                        </div>
                        
                        <div className="form-group col-md-3">
                            <label> Vendedor: </label>
                                <select className={formErrors.vendor.length > 0 ? "form-control is-invalid" : "custom-select mb-3"}  name="vendor" value={this.props.vendor}   onChange = {this.props.handleChange}   > 
                                <option value = {0}  selected>Seleccione un vendedor</option>
                                {this.props.vendors.map((vendor) => <option  value={vendor.id}> {''.concat(vendor.firstName, ' ' , vendor.lastName)} </option>) }
                                </select>
                                {formErrors.vendor.length > 0 && (<span className="text-danger">{formErrors.vendor}</span>)}
                        </div>
                                
                        <div className="form-group col-md-3">
                            <label className="form-label">Fecha alta</label>
                            <input name="creationDate" type="date" className="form-control datetimepicker-input" value={this.props.creationDate}  onChange = {this.props.handleChange}/>
                    </div> 

                    </div>
                    
                    <div className="form-row">
                    <div className="form-group col-md-4">
                            <label> Tipo de proceso: </label>
                                <select 
                                className={formErrors.land.length > 0 ? "form-control is-invalid" : "custom-select mb-3"} name="processType" value={this.props.processType} onChange={this.props.handleChange}  > 
                                
                                {this.state.processTypes.map((processType) => <option> {processType} </option>) }
                                </select>		
                        </div>
                            <div className="form-group col-md-4">
                                <label name="Block">Manzana / Lote: </label>
                                <input type="text" className="form-control" value = {this.props.manzanaLote} placeholder="Manzana / Lote"  disabled/>
                            </div>
                            <div className="form-group col-md-2">
                                <label>Costo total:</label>
                                <input type="text" className="form-control" name="totalPrice" placeholder="Costo total" value={this.props.totalPrice}  disabled/>
                            </div>

                            <div className="form-group col-md-2">
                                    <label>Superficie M2</label>
                                    <input type="text" className="form-control" id="surfaceM2" placeholder="SuperficieM2" value = {this.props.superficieM2} disabled/>
                        </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-12">
                            <label>Observaciones</label>
                            <textarea className="form-control" rows="1" name ="observations" placeholder="Campo de texto libre, es admisible cualquier observacion" value = {this.props.observations} onChange = {this.props.handleChange}></textarea>
                            </div> 
                        </div>   
                        
                         {this.props.showButton ? <button type="submit" className="btn btn-primary">Agregar</button> : null }          
                           
                            
                        </form>
                    </div>
                </div>
           );
        
    }
}
export default drawForm;

