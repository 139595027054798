import React from 'react';
import VendorHelper from '../../helpers/vendor/vendor.request';
import NotificationsController from '../NotificationsController'
class payVendorComissions extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            status : ''
        }
        this.handleEdit = this.handleEdit.bind(this);
    }

    async handleEdit(id){
      try{
      
          const response = await new VendorHelper().payVendorComission(localStorage.token, {id});

          this.props.comissionsInfo.filter(comission => comission.id === id)[0].status = "Pagado"
          this.setState({
              status : 'Pagado'
          })
          new NotificationsController().createNotification(response.data.message, response.status.toString())       
        
        }catch(err){
            new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	
        }

    }

    render(){
        const { saleContract } = this.props;

        return(
            
        <main className = "content">
        <div className="container-fluid p-0">
            <div className="row">
            <div className="col-md-12">
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Comisiones</h5>
                     <h6 className="card-subtitle text-muted">
                     </h6>
                </div>
            <div className="card-body">
                <form>
                    <div className="form-row">
                    <div className="form-group col-md-3">
                            <label >Codigo</label>
                            <input type="text" className="form-control" value = {saleContract.Vendors.code} disabled  />
                    </div>
                        <div className="form-group col-md-3">
                            <label >Vendedor</label>
                            <input type="text" className="form-control" value = {''.concat(saleContract.Vendors.firstName,' ',saleContract.Vendors.lastName)} disabled  />
                        </div>
                        <div className="form-group col-md-3">
                            <label >Correo</label>
                            <input type="text" className="form-control" value = {saleContract.Vendors.email} disabled  />
                        </div>
                        <div className="form-group col-md-3">
                            <label >RFC</label>
                            <input type="text" className="form-control" value = {saleContract.Vendors.rfc} disabled  />
                        </div>
                      
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-2">
                            <label >Terreno</label>
                            <input type="text" className="form-control" value = {saleContract.Lands.code} disabled  />
                        </div>
                        <div className="form-group col-md-2">
                            <label >Precio total</label>
                            <input type="text" className="form-control" value = {saleContract.Lands.totalPrice} disabled  />
                        </div>
                        <div className="form-group col-md-2">
                            <label >Comision</label>
                            <input type="text" className="form-control" value = {saleContract.Lands.totalPrice * .05} disabled  />
                        </div>
                        <div className="form-group col-md-3">
                            <label >Manzana / Lote</label>
                            <input type="text" className="form-control" value = {'Manzana: '.concat(saleContract.Lands.block,' / Lote: ',saleContract.Lands.lot)} disabled  />
                        </div>
                        <div className="form-group col-md-3">
                            <label >Dimensiones</label>
                            <input type="text" className="form-control" value = {''.concat(saleContract.Lands.height, ' * ' , saleContract.Lands.width)} disabled  />
                        </div>
                    </div>
                </form>
                </div>
                </div>
                <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Lista de comisiones</h5>
                     <h6 className="card-subtitle text-muted">
                     </h6>
                </div>
            <div className="card-body">
            <div className="table-responsive text-nowrap">
            <table className="table table-sm">
                <thead>
                <tr>
                <th scope="col">Codigo</th>
                <th scope="col">Fecha de pago</th>
                <th scope="col">Saldo anterior</th>
                <th scope="col">Comision</th>
                <th scope="col">Balance actual</th>
                <th scope="col">Pagado</th>
                </tr>
                </thead>
                <tbody> 
                {this.props.comissionsInfo.map((comissionsInfo) => (
                <tr scope="row"key={comissionsInfo.id}>
                <td>{comissionsInfo.code}</td>
                <td>{comissionsInfo.paymentDate.substring(0,10)}</td>
                <td>{comissionsInfo.previousBalance}</td>
                <td>{comissionsInfo.commissionQuantity}</td>
                <td>{comissionsInfo.actualBalance}</td>
                <td>
                    <button type="submit"
                     className="btn btn-success btn-sm" 
                     onClick={() => this.handleEdit(comissionsInfo.id, comissionsInfo)  
                    
                     }>
                         {comissionsInfo.status }
                     </button></td>

                </tr>
                ))}
                </tbody> 
            </table>
            </div>
            </div>
            </div>
        </div>
        </div>
        </div>
        </main>
        );
    }
}
export default payVendorComissions; 