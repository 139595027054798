import axios from 'axios';
import constants from '../../config/constants';

class SaleContractHelper{
    async makeCall(method, url, data,token = null) {
		return axios({
			method,
			url,
			data,
			headers: {
				'x-access-token': token
			}
		});
	}

	async addSaleContract(body, token){
		const method = 'POST';
		const url =`${constants.BACKEND_URL}/contract/saleContract`;
		return this.makeCall(method, url, body, token);
	}
	async updateSaleContract(token, id, body){
		const method = 'PUT';
		const url  = `${constants.BACKEND_URL}/contract/${id}`;
		return this.makeCall(method, url, body, token);
	}
    async getSaleContracts(token){
        const method = 'GET';
        const url = `${constants.BACKEND_URL}/contract`;
		return this.makeCall(method, url, {}, token);
    }
	async getSaleContract(token, id){
		const method = 'GET';
        const url = `${constants.BACKEND_URL}/contract/${id}`;
		return this.makeCall(method, url, {}, token);
	}

	async deleteSaleContact(id, token){
		const method = 'DELETE';
		const url = `${constants.BACKEND_URL}/contract/${id}`;
		return this.makeCall(method, url, {}, token)
	}
	
	async getLandInformationSupport(token)
	{
		const method = 'GET';
		const url = `${constants.BACKEND_URL}/contract/LandSupportInformation`
		return this.makeCall(method, url, {}, token);
	}

	async getContractRecipts(token, landId)
	{
		const method = 'GET';
		const url =`${constants.BACKEND_URL}/contract/landRecipts/${landId}`;
		return this.makeCall(method, url, {}, token);
	}
	
}export default SaleContractHelper;