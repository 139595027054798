import React from 'react';
import ReactToPrint from 'react-to-print';
class ComponentToPrint extends React.Component{
    render(){
        
       
        const  {land, clientName,clientAddress, code, amount, amountWord, bankReference, observations,  createdAt,operationalCost ,sumMonthlyPayment,totalLandPrice, typeOfCancellationPayment}= this.props

        return(   <main className="content" >
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body m-sm-3 m-md-5">
                            <div className="row">
                                <div className="col-md-6" style={{ width : '60%', fontSize: 16} } >
                                    <div className="text-muted"> Ave. Perales No. 16325, Edit, 11 Depto. 1-C, Fracc. La Campiña, <strong> Tijuana B.C. </strong></div>
                                    <div className="text-muted">Fraccionamiento colina de los angeles</div>
                                    <div className="text-muted">Teléfono 664-876-2625</div> 
                                    <div className="text-muted"> colinasdelosangeles@gmail.com</div>
                                </div>
                                <div style={{textAlign: 'right', alignSelf: 'stretch', width : '40%', fontSize: 16} } className="col-md-6 text-md-right">
                                    <br></br>
                                    <div className="text-muted"> <strong  style = {{background  : "#D0D0D0"}}> Recibo N {code}</strong> </div>
                                    <strong>{new Date(createdAt).toLocaleDateString("es-ES",{timeZone: 'UTC', year: 'numeric', month: 'long', day: 'numeric' })}  </strong>
                                    <br></br><br></br>
                                    <div className="text-muted"><strong> {typeOfCancellationPayment} por </strong> {(amount).toLocaleString('en-US', {style: 'currency',  currency: 'USD'})}</div>
                               </div>
                               <div className="col-md-6">
                               <h1  className="h3 mb-3"style={{ fontSize: 18 } } > Cancelacion de venta </h1>
                            </div>
                            </div>
                            <hr className="my-4" />
                            <div className="row mb-4">
                        <div className="col-md-10">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th className="text"  style = {{fontSize: 14, background: '#D0D0D0' }}>Cliente: {clientName} </th> 
                                    
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <td className="text"  style = {{fontSize: 14 }}>Dirección : {clientAddress} </td> 
                                    </tbody>
                                    </table>
                                </div>
                                </div>
        
        
                            <div className="row mb-2">
                                <div className="col-md-3" style={{textAlign: 'right', alignSelf: 'stretch', width : '30%'} } >
                                <table className="table table-sm"  >
                                <thead >
                                        <tr > 
                                            <th className="text-center" style = {{fontSize: 14 }}> Terreno</th>
                                            <th className="text-center" ></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center"  style = {{fontSize: 14 }}> {land} </td>                                   
                                        </tr>
                                        </tbody>
                                </table>
                                </div>
                                   
                                <div className="col-md-9" style={{textAlign: 'right', alignSelf: 'stretch', width : '70%' }} >
                                <table className="table table-sm">
                                    <thead>
                                        <tr>
                                            <th className="text" style = {{fontSize: 14 }}>Referencia bancaria</th>
                                            <th className="text-right" style = {{fontSize: 14 }}>{typeOfCancellationPayment} cantidad</th>
                                            <th className="text" style = {{fontSize: 14 }}>{typeOfCancellationPayment} letra</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                        <td className="text" style = {{fontSize: 14 }}>{bankReference}</td>
                                            <td className="text-right" style = {{fontSize: 14 }}>{(amount).toLocaleString('en-US', {style: 'currency',  currency: 'USD'})}</td>
                                            <td className="text" style = {{fontSize: 14 }}>{amountWord}</td>
                                        </tr>
                                        </tbody>
                                </table>
                                </div>
                            </div><br/>
                          <div className="row mb-4">
                            <div className="col-md-2">
                            </div>
                                    <div className="col-md-12">
                                  
                         {observations.length === 0 ? null :
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="text-left"  style = {{fontSize: 14}}>Observaciones</th>
                                    
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-left" style = {{fontSize: 14 }}>{observations}</td>
                                           </tr>
                                            </tbody>
                                    </table>
                                    }
                                    </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div className="row mb-4">
                                    <div className="col-md-12">
                                        <h1  className="h3 mb-3"style={{ fontSize: 12 } } > 
                                        {''.concat('El monto de ', (amount).toLocaleString('en-US', {style: 'currency',  currency: 'USD'}),' (', amountWord,') por concepto de ' , 
                                        typeOfCancellationPayment.toLowerCase() , 
                                        ' corresponde a la diferencia entre los costos de operacion por ', (operationalCost).toLocaleString('en-US', {style: 'currency',  currency: 'USD'}) ,
                                        ' y pago total de mensualidades por ', (sumMonthlyPayment).toLocaleString('en-US', {style: 'currency',  currency: 'USD'}) , 
                                        ' dicho costo de operacion es una tasa del 35% sobre el total del terreno (', (totalLandPrice).toLocaleString('en-US', {style: 'currency',  currency: 'USD'}),').')}</h1>
                                    </div>  
                            </div>             
                             
                            <br />
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className="row  text-center">
                                                <div className="col-md-6" >
                            __________________________________________________________________________________________________________
                            <br/>
                            <br/>
                            <br/>
                            <b style = {{fontSize: 17}}>
                            Firma de recibido
                            </b>
                            </div>
                            
                          
                            </div>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
        </main>);
    }
}

class CancellationReport extends React.Component{
    render(){

        return (<div>
             <div style={{ display: "none" }} >
                 <ComponentToPrint
                      land = {this.props.land} 
                      clientName = {this.props.clientName}
                      clientAddress = {this.props.clientAddress}
                      code=  {this.props.code.padStart(4,"0")} 
                      amount = {this.props.amount} 
                      amountWord = {this.props.amountWord}
                      bankReference ={this.props.bankReference}
                      observations ={this.props.observations}
                      createdAt = {this.props.createdAt}
                      operationalCost = {this.props.operationalCost}
                      sumMonthlyPayment  = {this.props.sumMonthlyPayment}
                      totalLandPrice= {this.props.totalLandPrice}
                      typeOfCancellationPayment = {this.props.typeOfCancellationPayment}
                      ref={(el) => (this.componentRef = el) }></ComponentToPrint>
                      </div>
           <ReactToPrint
                trigger={() => <button className="btn btn-primary">Imprimir</button>}
                content={() => this.componentRef}/>
            
        </div>)
    }
}
export default CancellationReport;