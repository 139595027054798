import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import permissionHelper from '../helpers/permissions/permissions.helper'
class PermissionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    value : this.props.permissions.value,
    print : this.props.permissions.canPrint
    }

    this.handleChange = this.handleChange.bind(this);
   
  }

  async handleChange(event, index, processId){

    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    
    this.setState({
        name : value    
    });

    
    this.props.permissions[index].value = this.state.value
    this.props.permissions[index].canPrint = this.state.print
    if (name === "print")
    {   
    const body = { 
 
      id : this.props.user.id,
      print : value,
      processId
    };
 
    new permissionHelper().updatePermissionsPrint(localStorage.token, body);
  }
    else
    {
       const  body = { 
        id : this.props.user.id,
        value : value,
        processId};
       new permissionHelper().updatePermissionsValue(localStorage.token, body);
    };
}


  render() {
   
    return (
      <Modal show={this.props.show} as="section">
        <Modal.Header as="span">
        <Modal.Title as="h4">{ "".concat(this.props.user.firstName ," ", this.props.user.lastName)} </Modal.Title>
         </Modal.Header>
        <Modal.Body as="section"> 
        <br></br>
       
     		<table className="table table-sm">
          <thead>
            <tr>
            <th>Modulo</th>
            <th>Sub modulo</th>
            <th>Permiso</th>
            <th>Imprimir</th>
            </tr>
					</thead>
					<tbody>
         
            {this.props.permissions.map((permit,j ) => (
              <tr key={permit.processId}>
              <td><p> {permit.module}</p></td>
              <td><p> {permit.subModule}</p></td>
              <td>
               
                <div>

                <select name="value" className="custom-select mb-3" value={permit.value} onChange = {(e) => {this.handleChange(e, j, permit.processId)}} >
                    <option value="0">Ninguno</option>
                    <option value="1">Solo lectura</option>
                    <option value="2">Lectura y escritura</option>
                  </select>
                </div>
              </td>
              <td>
               
                <input name="print" type="checkbox"  checked={permit.canPrint} value ={permit.canPrint}  onChange ={(e) => {this.handleChange(e, j, permit.processId)}} /> 
              </td>
             </tr>
          ))} 
          </tbody>
        </table>

          
          </Modal.Body>
        <Modal.Footer as="footer">
          <Button type="button"
          className="btn btn-primary btn-sm"
          onClick={() => this.props.handleClose()}>Cerrar</Button></Modal.Footer>
      </Modal >
    );
  }
}

export default PermissionModal;