import axios from 'axios';
import constants from '../../config/constants';

class ReportsHelper {
    async makeCall(method, url, data, token = null) {
        const response = await axios({
            method,
            url,
            data,
            headers: {
                'x-access-token': token
            }
        });
        return response
    }
    async getMonthlyCash(token, initialDate, endDate){
        const method = 'GET';
        const url= `${constants.BACKEND_URL}/report/getMonthlyCash/${initialDate}/${endDate}`

        return this.makeCall(method, url, {}, token)
    }
    async getConfirmedMonthlyPayments(token, initialDate, endDate){
        const method = 'GET';
        const url = `${constants.BACKEND_URL}/report/confirmedMonthlyPayments/${initialDate}/${endDate}`
        return this.makeCall(method, url,{}, token)
    }
    
    async getOverdueBalance(token){
        const method = 'GET';
        const url = `${constants.BACKEND_URL}/report/overdueBalance/`
        return this.makeCall(method, url,{}, token)
    }
    
    async getVendorCommisions(token, initialDate, endDate){
        const method = 'GET';
        const url = `${constants.BACKEND_URL}/report/vendorCommisions/${initialDate}/${endDate}`
        return this.makeCall(method, url,{}, token)
    }
  
	async getLandsInventory(token) {
		const method = 'GET';
		const url = `${constants.BACKEND_URL}/land`;
		return this.makeCall(method, url, {}, token);
	}
    async getLandMisingPayment(token){
        const method  ='GET';
        const url = `${constants.BACKEND_URL}/report/landMissinPayment`;
        return this.makeCall(method, url, {}, token);
    }

}
export default ReportsHelper;