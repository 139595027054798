import React from 'react'
import VendorHelper from '../../helpers/vendor/vendor.request';
import EditVendor from './editVendor'
import NotificationsController from '../NotificationsController'

class ListVendor extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            vendors : [], 
            showEdit : false
        };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);

    }
    
    async handleDelete(id){
        try{
            this.setState({
                vendors: this.state.vendors.filter(vendor => vendor.id !== id)
            });
           const response = await new VendorHelper().deleteVendor( localStorage.token, id);
            new NotificationsController().createNotification(response.data.message, response.status.toString());
        } catch(error){
            new NotificationsController().createNotification(error.message,"400");
        }

    }

    componentDidMount() {
        this.getVendors();
    }

    handleEdit(vendors){
        this.setState({showEdit: true,
        code : vendors.code,
        firstName : vendors.firstName,
        lastName : vendors.lastName,
        rfc : vendors.rfc, 
        email : vendors.email,
        zipCode : vendors.zipCode, 
        phoneNumber : vendors.phoneNumber
        });

        this.setView();
    
    }


    getVendors = async () => {
        try{
            const response= await new VendorHelper().getVendors(localStorage.token);
            this.setState ({
                vendors: response.data
            });
            new NotificationsController().createNotification("Registro encontrados", "201");
        }catch(error){
            new NotificationsController().createNotification(error.message, "400")
        }
    }

    setView() {
        if (this.state.showEdit){
            return(<EditVendor 
                code = {this.state.code}
                firstName = {this.state.firstName}
                lastName = {this.state.lastName}
                rfc = {this.state.rfc}
                email = {this.state.email}
                zipCode = {this.state.zipCode}
                phoneNumber = {this.state.phoneNumber}
                ></EditVendor>);
        }
        else 
        {
            return(
            <main className="content">
			
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Lista de vendedores</h5>
                                <h6 className="card-subtitle text-muted">
                                    En el siguiente link puede agregar vendedores nuevos{' '}
                                    <a href="/agregarVendedor" rel="noopener noreferrer nofollow">
                                        Aquí
                                    </a>
                                </h6>
                            </div>
                            <div className="card-body">
                            <div className="table-responsive text-nowrap">
                                <table className="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Apellidos</th>
                                            <th>RFC</th>
                                            <th>Correo electronico</th>
                                            <th>Codigo postal</th>
                                            <th>Numero telefonico</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        {this.state.vendors.map((vendor) => (
                                            <tr key={vendor.id}>
                                                <td>{vendor.firstName}</td>
                                                <td>{vendor.lastName}</td>
                                                <td>{vendor.rfc}</td>
                                                <td>{vendor.email}</td>
                                                <td>{vendor.zipCode}</td>
                                                <td>{vendor.phoneNumber}</td>
                                                
                                                <td>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-sm"
                                                        onClick={() => this.handleEdit(vendor)
                                                        }
                                                    >
                                                        Editar
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => this.handleDelete(vendor.id)}
                                                    >
                                                        Eliminar
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody> 
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        
        </main>);
        }
    }
    render() {
        return <div>{this.setView()}</div>
    }

}

export default ListVendor;