import React  from 'react';
import LandTransferHelper from '../../helpers/landTransfer/landTransfer.request';
import NotificationsController from '../NotificationsController';

function LandSelect(props) {

    return(<div>
      <select 
        className= "custom-select mb-3" name={props.name} value={props.landvalue}  onChange = {props.onChange} > 
        <option value = {0} selected>Seleccione un terreno</option> 
        {props.allLands.map((land) => <option value={land.id}> {land.code} </option>) }
         </select>
       
    </div>);
}
const formValid = formErrors => {
	let valid = true;

	//validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});
    return valid;
}
class LandTransferIndex extends React.Component{
constructor(props){
    super(props);
    this.state = {
        Tolands : [],
        Fromlands : [],
        tolandId : '',
        fromlandId : '',
        formErrors:  {
            tolandId :'0',
            fromlandId : '0'
        }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit= this.handleSubmit.bind(this);
}

componentDidMount(){
    this.getInitialValues();
}

getInitialValues = async () => {
    try{

        const response = await new  LandTransferHelper().getLands(localStorage.token);

        this.setState({
            Fromlands : response.data.filter(item => item.status === 'Vendido'),
            Tolands :  response.data.filter(item => item.status === 'Disponible')
        });
        
    }catch(error){
        console.log(error);
    }
}
async handleSubmit(event){
    event.preventDefault();
    if (formValid(this.state.formErrors)) {
          try {
   
        const {tolandId, fromlandId}  = this.state;

        const body = {
            toLandId : tolandId, 
             fromLandId : fromlandId
        }
        this.state.Fromlands.push(this.state.Tolands.filter(item => item.id === tolandId)[0]);
        this.state.Tolands.push(this.state.Fromlands.filter(item => item.id === fromlandId)[0]);

        this.setState({
            Fromlands : this.state.Fromlands.filter(item => item.id !== fromlandId),
            Tolands :  this.state.Tolands.filter(item => item.id !== tolandId)
        });
        
        const response = await new LandTransferHelper().transferLand(body, localStorage.token);
        new NotificationsController().createNotification( response.data.message, response.status.toString());
    } catch (err) {
        new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	
      }
    }else {
        new NotificationsController().createNotification( 'Faltan errores por corregir', "400");
    }

   
    }
async handleChange(event){
    event.preventDefault();
    var value = event.target.value
    let formErrors = this.state.formErrors;
    
    switch(event.target.name){
        case 'tolandId': 
            formErrors.tolandId =  value === "0"  ? "Seleccione el terrenos" : ""
            break;
        case 'fromlandId': 
            formErrors.fromlandId =  value === "0"  ? "Seleccione el terrenos" : ""
            break;
        default : 
        }

    this.setState({
        [event.target.name] : event.target.value    
    });
    this.setState({formErrors, name: value});
}

    render(){
    
        return(
<main className = "content">
            <div className="container-fluid p-0">
                
            <div className="card">
            <div className="card-header">
            <h5 className="card-title">Traspaso del terreno</h5>
            </div>
            <div className="card-body">
            <form onSubmit={this.handleSubmit} >
               <div className="form-row">
               <div className="form-group col-md-3">
               <label> Del terreno Terreno:   <span className = "badge bg-success" > Vendidos </span>		</label>      
                    < LandSelect name = {"fromlandId"} landvalue = {this.state.fromlandId}  onChange = {this.handleChange}  allLands = {this.state.Fromlands} />
             </div>
                        <div className="form-group col-md-3">
                            <label> Al terreno Terreno: <span className = "badge bg-warning" > Disponibles</span>		 </label>
                           <LandSelect name = {"tolandId"} landvalue = {this.state.tolandId} onChange = {this.handleChange} allLands = {this.state.Tolands}/>
                              
                        </div>
                        <div className="form-group col-md-6">
                            <div className = "form-group">
                        <div className="vertical-center">
                   
                        <button  type="submit" className="btn btn-primary">Cambiar</button>
                    <br/><br/>
                 
                        
                        </div>
                        </div>
                        </div>
                        
               {/* <div className="form-group col-md-6">
                   <div className = "form-group">
               <div className="vertical-center">

               
               </div>
               </div> 
               </div>  */}
               </div> 
           </form>
            </div>
            
                                        </div>
                        
        
                                      </div>
</main>
        );
    }
}
export default LandTransferIndex;