import React from 'react'
import NotificationsController from '../NotificationsController'
import cancelHelper  from '../../helpers/cancellation/cancel.request'
import ReactTable from '../reports.ReactTableController'
import AddCancellation from './addCancellation'
class cancellations extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            sourceCancellations : [],
            showEdit : false
        }   
    this.handleEdit =  this.handleEdit.bind(this);
    };
    
    componentDidMount(){
        this.getSourceCancellations();
    }
    async handleEdit(cell){
        const row = cell.row.original
  
        this.setState({
          showEdit : true,
          status : row.status,
          id : row.id,
          land :  'Manzana: '.concat(row.Lands.block,' / Lote: ', row.Lands.lot ),
          clientName : ''.concat(row.Clients.firstName, ' ',row.Clients.lastName),
          clientAddress : row.Clients.address,
          amount  : row.amount, 
          amountWord : row.amountWord,
          landId : row.land,
          clientId : row.client,
          code : row.code,
          createdAt : row.createdAt,
          bankReference : row.bankReference,
          observations : row.observations,
          typeOfCancellationPayment : row.typeOfCancellationPayment,
          operationalCost: row.operationalCost,
          sumMonthlyPayment: row.sumMonthlyPayment,
          totalLandPrice: row.totalLandPrice
        });
      
    }

    getSourceCancellations = async() => {
        const response = await new cancelHelper().getCancellations(localStorage.token);
         response.data.sort((a,b) => a.Lands.block - b.Lands.block || a.Lands.lot- b.Lands.lot);
        this.setState({
            sourceCancellations : response.data
        });
       
        new NotificationsController().createNotification("Registro encontrados", "201")
    }

    setView(){
    if(this.state.showEdit){
        return(<AddCancellation id = {this.state.id} 
                            status = {this.state.status}
                              land = {this.state.land}
                              clientName = {this.state.clientName}
                              clientAddress = {this.state.clientAddress}
                              amount = {this.state.amount}
                              amountWord ={this.state.amountWord}
                              landId = {this.state.landId}
                              clientId  = {this.state.clientId}
                              code = {this.state.code}
                              createdAt = {this.state.createdAt}
                              bankReference = {this.state.bankReference} 
                              observations = {this.state.observations}
                              typeOfCancellationPayment = {this.state.typeOfCancellationPayment}  
                              operationalCost = {this.state.operationalCost}
                              sumMonthlyPayment  = {this.state.sumMonthlyPayment}
                              totalLandPrice= {this.state.totalLandPrice} />);
    }
    else {
        if(this.state.sourceCancellations.length === 0)
        {return (<div className="box">
                    <div className="d-flex justify-content-center aling-items-center ">
                    <div className="spinner-border" role="status" />
                    </div>
                </div>);}

    return  <main className="content">

    <div className="container-fluid p-0">
    <div className="row">
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <br></br>
                    {/* <h4 className="card-subtitle text-muted"></h4> */}
                </div>
                <div className="card-body">
                <ReactTable
                data = {this.state.sourceCancellations}
                Header = {"Busqueda de cancelaciones"}
                columns = {
                    [
                    
                        {
                            Header : "Terreno",
                            accessor : "Lands.code"
                        },
                        {
                            Header : "Cliente nombre",
                            accessor : "Clients.firstName"
                        },
                        {
                            Header : 'Cliente apellido',
                            accessor: 'Clients.lastName'
                        },
                        {
                            Header: 'Manzana',
                            accessor: 'Lands.block',
                        },
                        {
                            Header: 'Lote',
                            accessor: 'Lands.lot',
                        },
                        {
                            Header : "Monto",
                            accessor : "amount"
                        },
                        {
                            Header : "Monto en letra",
                            accessor : "amountWord"
                        },
                        {
                            Header : "Tipo",
                            accessor: "typeOfCancellationPayment"
                        },
                        {
                            Header : "Estatus",
                            accessor : "status"
                        },

                        {
                            Header : 'Accion',
                            accessor: '[row identifier to be passed to button]',
                            Cell: ({cell}) => (
                            <button className="btn btn-primary btn-sm" 
                            onClick={() =>this.handleEdit(cell)}> Editar
                            </button>
                                )
                        }

                    ]
                }></ReactTable> </div>
                </div>
                        </div>
                        </div>
                        </div>
                        </main>
                }
            }
        render(){
            return <div>{this.setView()}</div>
        }
    }
export default cancellations;