import React from 'react';
import { Button, Modal } from 'react-bootstrap';

class ConfirmModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
      
        }
      }
render(){
    return(
        <Modal show={this.props.showModal} as="section">
            <Modal.Header as="span">
            <Modal.Title as ="h4">Confirmación</Modal.Title>
            </Modal.Header>
            <Modal.Body  as="section">
            <h6>¿Desea continuar con el proceso?</h6>
            </Modal.Body>
            <Modal.Footer as="footer">
            <Button type="button" className="btn btn-primary btn-sm" onClick={() => this.props.handleSubmit()}>Confirmar</Button>
            <Button type="button" className="btn btn-danger btn-sm" onClick={() => this.props.hideModal()}>Cerrar</Button>
        
            </Modal.Footer>
        </Modal>
    );
}
}
export default ConfirmModal