import React from 'react';
import ReactToPrint from 'react-to-print';
import '../../docs/css/forReceipts.css'
class ComponentToPrint extends React.Component {
    constructor(props){
        super(props);
    }


render(){
    const ReciptsTotal = this.props.receipts.length

    return(
        < div >
                {this.props.receipts.map((receipt) => ( 
                    
                    <div>
                 
                     <div className="page-break" />
              
                <div className="card-body m-sm-3 m-md-5">
                <div className="row">
                    <div className="col-md-6" >
                  
                    
                    <h3>Pagare</h3>
                    <div className="text-muted">  Tijuana B.C., Fecha:  {receipt.paymentDate.substring(0,10)} </div>
                            
                    </div>
                    <div className="col-md-6 text-md-right">
                    <div className="text-muted"> 
                    <strong> No </strong>{receipt.code} <strong> de </strong> {'0'.concat(ReciptsTotal)}
                    </div> 
                    <div className="text-muted"> <strong> Bueno por </strong> {receipt.amountQuantity} </div> 
                   
                    </div>
                </div>
                <div className="row">
                <div className="col-md-12" >
                <strong>
                   
                Debo y pagaré incondicionalmente por este pagaré a la orden de:
                
                </strong>
                 Evangelina Moreno Guerra
                    </div>
                    <div className="col-md-12" >
                    En Ave. Perales No. 16325, Edit, 11 Depto. 1-C, Fracc. La Campiña
                </div>
                <div className="col-md-12" >
                    <strong>La cantidad de: </strong> 
                    {receipt.amountWord}
                    </div>
                <div className="col-md-12">
                 Valor recibido a mi entera satisfacción. Este pagaré forma parte de una serie numerada del 1 al  
                  {ReciptsTotal} 
                   y todos están sujetos a la condicion de que, al no pagarse cualquiera de ellos a su vencimiento, serán exigibles todos los que les sigan en número además de ya vencidos, desde la fecha de vencimiento de este documento hasta el dia de su liquidación, causara intereses moratorios al tipo de 4.5% mensual, pagadero en esta ciudad juntamente con el principal.
                </div>

                
                <div className="col-md-12">
                <strong>Nombre: </strong> {this.props.client}
                </div>
                <div className="col-md-12">
                <strong>Domicilio: </strong> {this.props.clientAddress}
                </div>
                <div className="col-md-12">
                <strong>Ciudad: </strong> Tijuana Baja California
                </div>
                </div>
              
                <div className="row  text-center">
                <div className="col-md-6" >
                       __________________________________________________
                  
                </div>
                <br></br> 
                <br></br> 
                <br></br> 
                <div className="col-md-12">
                    Firma
                </div>
                    </div>
                </div>
               
            </div>
                 ))}
        </div>
  
    )
    }
}

class ReceiptOfAllPayments extends React.Component {
    render(){
        return(
            <div>
          <ReactToPrint
            trigger={() => <button  className="btn btn-primary">Impresion de recibos</button>}
            content={() => this.componentRef}
            />
             <div style={{display: 'none'}}>
            <ComponentToPrint  clientAddress  ={this.props.clientAddress}
                    client = {this.props.client} receipts = {this.props.receipts} ref={(el) => (this.componentRef = el) } />                             
             </div>
            </div>
        )
    }
}
export default ReceiptOfAllPayments;