import React from "react";
import { useTable, useFilters, useGlobalFilter} from 'react-table'

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            className="form-control"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`${count} registros...` }
            
        />
    )
}
function Table({ columns, data }) {

    const defaultColumn = React.useMemo(() => ({Filter: DefaultColumnFilter}), [])

    const {getTableProps, getTableBodyProps, headerGroups,footerGroups, rows, prepareRow}  //,
           = useTable({columns,data,defaultColumn}, useFilters, useGlobalFilter)

    return (
        <div >
             <div className="table-responsive text-nowrap">
    
            <table  className="table table-sm" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th  {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    {/* Render the columns filter UI */}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell')
                                           
                                        }

                                    </td>
                                    );
                                })}
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    
                    {footerGroups.map(footerGroup => (
                    <tr {...footerGroup.getFooterGroupProps()}> 
                    {footerGroup.headers.map(column => (
                        <td {...column.getFooterProps()}>
                         <b>{   
                     (function(){
                        switch (column.Footer) {
                          case 'amount': return footerGroups[0].headers[3].filteredRows.map(x => x.values.amount).reduce((first, second) => first + second, 0) 
                          case 'discount': return footerGroups[0].headers[3].filteredRows.map(x => x.values.discount).reduce((first, second) => first + second, 0)
                          case 'penalty':  return footerGroups[0].headers[3].filteredRows.map(x => x.values.penalty).reduce((first, second) => first + second, 0)
                          case 'realAmount': return footerGroups[0].headers[3].filteredRows.map(x => x.values.realAmount).reduce((first, second) => first + second, 0)
                          case 'amountQuantity' :  return footerGroups[0].headers[3].filteredRows.map(x => x.values.amountQuantity).reduce((first, second) => first + second, 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }); 
                          default : return ''
                        }
                      }())
                            }
                         
                         </b>
                        </td>
                        ))}
                    </tr>
                    ))}
                </tfoot>
            </table>
            </div>
            <br />
            <div > {rows.length} filas </div>
            <div>
            </div>
        </div>
    )
}



function FilterTableComponent(props) {

    const columns = React.useMemo(
        () => [
            {
                Header: props.Header,
                columns: props.columns,
            },
        ],
        []
    )
  

    return (
        <Table columns={columns} data={props.data} />
             
    )
}

export default FilterTableComponent;