import React from 'react';
import NotificationsController from '../../../NotificationsController'
import ClientWBalanceHelper from '../../../../helpers/reports/reports.helper'
import TableController from '../../../reports.ReactTableController'
import ReactExport from 'react-export-excel'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class clientsWBalanceDueReport extends React.Component{
    constructor(props){
    super(props);
    this.state = {
        BalanceDueData : [],
        mensajeTabla : ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.searchBalanceDueData = this.searchBalanceDueData.bind(this); 
}

componentDidMount(){
    this.searchBalanceDue();
}

async handleChange(event) {
    event.preventDefault();
    this.setState({
       [event.target.name]: event.target.value
     });
  }
  
  async searchBalanceDue(){
    try{
        this.setState({
            mensajeTabla : 'Cargando . . .'
        });
        const BalanceDueResponse = await new ClientWBalanceHelper().getOverdueBalance(localStorage.token);

        if(BalanceDueResponse.data.length === 0){
            this.setState({
                mensajeTabla : 'No hay datos para mostrar'
            });
        }
        else{
            this.setState({
                BalanceDueData : BalanceDueResponse.data,
                mensajeTabla : ''
            });
        }
        new NotificationsController().createNotification(BalanceDueResponse.data.message,BalanceDueResponse.status.toString())
    }catch(err){
        new NotificationsController().createNotification(err.response.data.message,err.response.status.toString() );
    }

  }

async searchBalanceDueData(event){
     event.preventDefault();
     this.searchBalanceDue();
  
}
displaymensaje(){
    if (this.state.mensajeTabla === "Cargando . . ."  ){
        return(<div className="spinner-border spinner-border-sm" role="status" />);
    }
    else{
       return( <h3>{this.state.mensajeTabla}</h3>);
    }
}
setView(){
    if( this.state.mensajeTabla.length > 0 )
    {return (
                <div className="d-flex justify-content-center aling-items-center ">
                {this.displaymensaje()}
                </div>
           );}
    else
    return(<TableController  data = {this.state.BalanceDueData} 
        Header = {"Busqueda de vencidos"} 
        columns = {
                    [
                        {
                            Header: 'Folio',
                            accessor: 'code',
                        },
                        {
                            Header: 'Cliente nombre',
                            accessor: 'client.firstName',
                        },
                        {
                            Header: 'Cliente apellido',
                            accessor: 'client.lastName',
                        },
                        {
                            Header: 'Terreno',
                            accessor: 'landCode',
                        },
                        {
                            Header: 'Fecha de expiracion',
                            accessor: 'expirationDate',
                        },
                        {
                            Header: 'Cantidad',
                            accessor: 'amountQuantity',
                            Footer : 'amountQuantity'
                        },
                        {
                            Header: 'Cantidad en letra',
                            accessor: 'amountWord',
                        }                 
                        ] } 

                        ></TableController>  )

}
render() {
    return( <main className = "content">
    <div className="container-fluid p-0">
        
    <div className="card">
    <div className="card-header">
<h3 className="card-title"> Cobros vencidos</h3>q
    <ExcelDownloadButton BalanceDueData= {this.state.BalanceDueData}></ExcelDownloadButton>
</div>
    <div className="card-body">

    {this.setView()}
                

    </div>
                                </div>
                              </div>
                              </main>
        );
}
}
export default clientsWBalanceDueReport;


class ExcelDownloadButton extends React.Component {

    render() {
        return (

            <ExcelFile filename = "Cobros vencidos" element={
            <a className="btn btn-success">
            <i className="align-middle mr-2 fas fa-file-excel" data-feather="sliders" />{' '}
					<span className="align-middle">Descargar</span></a>}>


                <ExcelSheet data={this.props.BalanceDueData} name="Vencidos">
                    <ExcelColumn label="Folio" value="code"/>
                    <ExcelColumn label="Terreno" value="landCode"/>
                    <ExcelColumn label= "Cliente nombre" value = "clientfirstName" />
                    <ExcelColumn label= "Cliente apellido" value = "clientlastName" />
                    <ExcelColumn label="Fecha de expiracion" value="expirationDate"/>
                    <ExcelColumn label="Cantidad" value="amountQuantity"/>
                    <ExcelColumn label="Cantidad en letra" value="amountWord"/>

                </ExcelSheet> 
            </ExcelFile>
        );
    }
}