import React from 'react'
import NotificationsController from '../NotificationsController'
import SaleContractHelper from '../../helpers/saleContract/saleContract.request'
import VendorHelper from '../../helpers/vendor/vendor.request'
 import PayVendorComissions from './payVendorComissions'
 import ComissionTableController from '../reports.ReactTableController'

class vendorCommissionLists extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          saleContracts : [],
          saleContract : [],
          showEdit : false
        }
         this.handleEdit = this.handleEdit.bind(this);
    };
    componentDidMount(){
      this.getVendorForCommisions();
    }

    async handleEdit(saleContract){

        const response = await new VendorHelper().getVendorComissions(localStorage.token,saleContract.land,saleContract.vendor);

      this.setState({
        showEdit : true,
        comissionsInfo : response.data,
        saleContract : saleContract
      })
    }

    getVendorForCommisions = async () => {
      try {
          const response = await new SaleContractHelper().getSaleContracts(localStorage.token);
          this.setState({
              saleContracts : response.data
          });
      
          new NotificationsController().createNotification("Registro encontrados", "201");
        
      }
  catch(error)
  {
      new NotificationsController().createNotification(error.message, "400")
  }
}   
  
    setView(){
      if (this.state.showEdit) {
            return(<PayVendorComissions comissionsInfo = {this.state.comissionsInfo} saleContract = {this.state.saleContract}></PayVendorComissions>);
      }
      else 
      {
        if(this.state.saleContracts.length === 0)
        {return (<div className="box">
                    <div className="d-flex justify-content-center aling-items-center ">
                    <div className="spinner-border" role="status" />
                    </div>
                </div>);}
        return(	<main className="content">
			
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Lista de comisiones</h5>
                            <h6 className="card-subtitle text-muted">
                            </h6>
                        </div>
                        <div className="card-body">
                        <div className="table-responsive text-nowrap">
                        <ComissionTableController 
                        data = {this.state.saleContracts} 
                        Header = {"Busqueda de comisiones"} 
                        columns = {
                                    [
                                    
                                        {
                                            Header: 'Terreno',
                                            accessor: 'Lands.code',
                                        },
                                        {
                                            Header: 'Cliente',
                                            accessor: 'Clients.firstName',
                                        },
                                        {
                                            Header: 'Cliente',
                                            accessor: 'Clients.lastName',
                                        },
                                        {
                                            Header: 'Vendedor',
                                            accessor: 'Vendors.firstName',
                                        },
                                        {
                                            Header: 'Vendedor',
                                            accessor: 'Vendors.lastName',
                                        },
                                        {
                                            Header: 'Numero de meses',
                                            accessor: 'numberOfmonths',
                                        },
                                        {
                                            Header: 'Pago mensual',
                                            accessor: 'monthlyPayment',
                                        },
                                        {
                                            Header: 'Costo actual',
                                            accessor: 'currentCost',
                                        },
                                        // {
                                        //     Header: 'Fecha alta',
                                        //     accessor: 'creationDate',
                                        // },
                                        {
                                            Header: 'Contract',
                                            accessor: 'contractNumber',
                                        },
                                        {
                                            Header : 'Accion',
                                            accessor: '[row identifier to be passed to button]',
                                            Cell: ({cell}) => (
                                            <button className="btn btn-primary btn-sm" 
                                            onClick={() =>this.handleEdit(cell.row.original)}>   Ver comisiones
                                            </button>
                                                )
                                        }
            
                                    ]
                                }
            ></ComissionTableController>
                            {/* <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Terreno</th>
                                        <th>Cliente</th>
                                        <th>Vendedor</th>
                                        <th>Numero de meses</th>
                                        <th>Pago mensual</th>
                                        <th>Costo actual</th>
                                        <th>Fecha de alta</th>
                                        <th>Contrato</th>
                                        <th>Accion</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    {this.state.saleContracts.map((saleContract) => (
                                        <tr key={saleContract.id}>
                                            <td>{saleContract.Lands.code}</td>
                                            <td>{ ''.concat(saleContract.Clients.firstName,' ',saleContract.Clients.lastName)}</td>
                                            <td>{''.concat(saleContract.Vendors.firstName, ' ',saleContract.Vendors.lastName )}</td>                                 
                                            <td>{saleContract.numberOfmonths}</td>
                                            <td>{saleContract.monthlyPayment}</td>
                                            <td>{saleContract.currentCost}</td>
                                            <td>{saleContract.creationDate.substring(0,10)}</td>
                                            <td>{saleContract.contractNumber}</td>
                                            <td>
                                               <button
                                                    type="submit"
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => this.handleEdit(saleContract)
                                                    }
                                                >
                                                Ver comisiones
                                                </button>
                                               
                                          </td>
                                        </tr>
                                    ))}
                                </tbody> 
                            </table> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
        </main>
        );
      }
    }
    render() {
      return <div>{this.setView()}</div>
  }
}
export default vendorCommissionLists;