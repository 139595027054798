import axios from 'axios';
import constants from '../../config/constants';
class landtransferHelper {
    async makeCall(method, url, data, token = null) {
		return axios({
			method,
			url,
			data, 
			headers: {
				'x-access-token': token
			}
		});
	}
    async getLands(token){
        const method = 'GET'
        const url = `${constants.BACKEND_URL}/land/landtransfer`
        return this.makeCall(method, url, {}, token);
    }
	async transferLand(body, token) {
		const method = 'POST';
		const url = `${constants.BACKEND_URL}/landTransfer`;
		return this.makeCall(method, url, body, token);
	}

}
export default landtransferHelper;