import React from 'react';
import NotificationsController from '../../../NotificationsController'
import LandMisingPaymentHelper from '../../../../helpers/reports/reports.helper'
import TableController from '../../../reports.ReactTableController'
import ReactExport from 'react-export-excel'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class LandMisingPayment extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            LandMisingPaymentData: [],
            mensajeTabla : ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.searchLandMisingPaymentData = this.searchLandMisingPaymentData.bind(this); 
    }
    componentDidMount(){
        this.searchLandMisingPaymentData();
    }
    async handleChange(event) {
        event.preventDefault();
        this.setState({
           [event.target.name]: event.target.value
         });
      }
    async searchLandMisingPaymentData(){
        try{
            this.setState({
                mensajeTabla : 'Cargando . . .'
            });
            const LandMisingPaymentResponse = await new LandMisingPaymentHelper().getLandMisingPayment(localStorage.token);
            if(LandMisingPaymentResponse.data.length === 0)
            {
                this.setState({
                    mensajeTabla : 'No hay datos para mostrar'
                });
            }
            else 
            {
            this.setState({
                LandMisingPaymentData : LandMisingPaymentResponse.data,
                mensajeTabla : ''
            });
            }
            new NotificationsController().createNotification(LandMisingPaymentResponse.data.message,LandMisingPaymentResponse.status.toString())

        }catch(err){
            new NotificationsController().createNotification(err.response.data.message,err.response.status.toString() );
        }
    
      }
      
displaymensaje(){
    if (this.state.mensajeTabla === "Cargando . . ."  ){
        return(<div className="spinner-border spinner-border-sm" role="status" />);
    }
    else{
       return( <h3>{this.state.mensajeTabla}</h3>);
    }
}
setView (){
    if( this.state.mensajeTabla.length > 0 )
    {return (
                <div className="d-flex justify-content-center aling-items-center ">
                {this.displaymensaje()}
                </div>
           );}
    else
return(  <TableController  data ={this.state.LandMisingPaymentData} 
    Header = {"Busqueda de terrenos"} 
    columns = {[ 
        {
            Header: 'Nombre cliente',
            accessor: 'client.firstName',
        },
        {
            Header: 'Apellido cliente',
            accessor: 'client.lastName',
        },
        
        {
            Header: 'Terreno',
            accessor: 'land.code',
        },
        
        {
            Header: 'Manzana',
            accessor: 'land.block',
        },
        {
            Header: 'Lote',
            accessor: 'land.lot',
        },
        {
            Header: 'Largo',
            accessor: 'land.height',
        },
        {
            Header: 'Ancho',
            accessor: 'land.width',
        },
        {
            Header: 'Precio por M2',
            accessor: 'land.pricePerM2',
            
        },            
        {
            Header: 'Estatus',
            accessor: 'land.status',
        },
        {
            Header : 'Precio total',
            accessor : 'land.totalPrice' 
        },
        {
            Header: 'Residuo',
            accessor: 'amountLeft'
        }
    ]} 

    ></TableController>  )}
      render() {
       
        return( <main className = "content">
        <div className="container-fluid p-0">
            
        <div className="card">
        <div className="card-header">
        <h5 className="card-title"> Residuo de pagos de terrenos vendidos</h5>
            <ExcelDownloadButton LandMisingPaymentData= {this.state.LandMisingPaymentData}></ExcelDownloadButton>
        </div>
        <div className="card-body">
    {this.setView()}
      
                    </div>
                    </div>
                  </div>
                  </main>
);
        
}}
export default LandMisingPayment
class ExcelDownloadButton extends React.Component {

    render() {
       
        return (  <ExcelFile filename = "Inventario de terrenos" element={
            <a className="btn btn-success">
            <i className="align-middle mr-2 fas fa-file-excel" data-feather="sliders" />{' '}
					<span className="align-middle">Descargar</span>
                    </a>}>


                <ExcelSheet data={this.props.LandMisingPaymentData} name="Terrenos">
                   
                    <ExcelColumn label="Nombre cliente" value="client.firstName"/>
                    <ExcelColumn label="Apellido cliente" value="client.lastName"/>
                    <ExcelColumn label="Terreno" value="land.code"/>
                    <ExcelColumn label="Manzana" value="land.block"/>
                    <ExcelColumn label="Lote" value="land.lot"/>
                    <ExcelColumn label="Largo" value="land.height"/>
                    <ExcelColumn label="Ancho" value="land.width"/>
                    <ExcelColumn label="Precio por M2" value="land.pricePerM2"/>
                    <ExcelColumn label="Estatus" value="land.status"/>
                    <ExcelColumn label="Precio total" value="land.totalPrice"/>
                    <ExcelColumn label="Residuo" value="amountLeft"/>

                </ExcelSheet>
            </ExcelFile>);
    }
}