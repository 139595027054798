import React from 'react';
import extraChargeHelper from '../../helpers/extraCharge/extraCharge.request';
import CristianModal from '../CristianModal'
import ExtraChargeHelper from '../../helpers/extraCharge/extraCharge.request';
import NotificationsController from '../NotificationsController';
import ExtraChargeDetails from './extraChargesDetail'
import AuthenticateHelper from '../../helpers/authenticate/authenticate.request';

const convertir = require('numero-a-letras');
var dateObj = new Date();
var dateString = ''.concat(dateObj.getFullYear().toString(),'-',String(dateObj.getMonth() + 1).padStart(2, '0'), '-',String(dateObj.getDate()).padStart(2, '0'));
class addExtraChargers extends React.Component{
    
    constructor(props){
        super(props);
       
        this.state = {
            extraChargeId : '',
            landId : '' ,
            landCode : '',
            methodOfPayment : 'Efectivo',
            typeOfCharge : 'Carta finiquito',
            
            amount : 0,
            amountWord : 'Cero Pesos 00/100 M.N.',
            paymentDate : dateString,
            originalClient : '',
            newClient : '',
            observations : '',
            discount  : 0 ,
            penalty : 0,
            realAmount : 0,
            confirmation : '',
            newClients : [],
            extraChargeDetails: [],
            methodOfPayments : ['Efectivo','Cheque nominativo','Transferencia electronica de fondos','Tarjeta de credito','Monedero electronico','Dinero electronico', 
                                'Vales de despensa','Tarjeta de debito'],
            typeOfCharges : ['Carta finiquito','Traspaso del terreno','Otros cobros'],
            originalClientName : '',
            originalClientAddress: '',
            showModal : false,
            message : '',
            email: '',
            password: ''  
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAmounts = this.handleAmounts.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
        this.deleteExtraCharge = this.deleteExtraCharge.bind(this);
    }

    
    componentDidMount(){
         this.getExtraCharges();
         this.getExtraChargersDetails();
    }

    clientInfo = async (clientId) =>{
        const clientResponse = await new extraChargeHelper().getClientInfo(localStorage.token, clientId);
        this.setState({
            originalClientAddress: clientResponse.data.address
        });
    }
    getExtraCharges = async () => {
    
        try {
         const newClientResponse = await new ExtraChargeHelper().getAllClients(localStorage.token);
      
         this.setState({
              land : this.props.landId,
              landCode : this.props.landCode,
              originalClient : this.props.clientId,
              originalClientName : this.props.clientName,
              newClient : this.props.clientId,
              newClients : newClientResponse.data
         });
         this.clientInfo(this.props.clientId);
    //     new NotificationsController().createNotification("Registro encontrados", "201")
    } catch(error){
        new NotificationsController().createNotification(error.message, "400")
    }
     }  

     getExtraChargersDetails = async () => {
        const response = await new ExtraChargeHelper().getExtraChargeDetails(localStorage.token, this.props.landId,  this.props.clientId);
        this.setState({
            extraChargeDetails : response.data
        })
     }

    async handleChange(event) {
        if ( event.target.name === "typeOfCharge"){
        
            this.setState({
                newClient : this.state.originalClient
            });
        }
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    async handleAmounts(event){
        this.setState({
            amount : event.target.value,
            amountWord : convertir.NumerosALetras(event.target.value)
        })

    }

    async deleteExtraCharge(event, extraChargeId){
        event.preventDefault();    
         try {
        if(localStorage.rol !== "Supervisor"){
            this.setState({
                showModal : true,
                extraChargeId
            });
        }
        else 
        {
            this.updateExtraCharge(extraChargeId);
        }

    }catch(err){
         new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	    
    }
    }   
    
    async updateExtraCharge(extraChargeId) {
        try {
       
 
         const response =   await new ExtraChargeHelper().updateExtraCharge(localStorage.token, extraChargeId);
         new NotificationsController().createNotification("Cargo extra se realizo con exito","200");
        this.setState({
            showModal : false,
            extraChargeDetails : this.state.extraChargeDetails.filter(detail => detail.id !== extraChargeId ),
            reciptId : 0,
            originalClient :response.data.id,
            originalClientName :  response.data.Name
        });
  
        const totalpage = Math.ceil(this.state.extraChargeDetails.length / 10 )            
        this.refs.form.onPageChanged({currentPage : totalpage, totalPages : totalpage , pageLimit :  10});

        }catch(err)
        {
          new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	
        }
    } 
    async hideModal(){
        this.setState({
            showModal : false
        });
    }

    async handleSignIn(){
        try {
           
            const body = {
                email: this.state.email,
                password: this.state.password
            };
            
      
            const response = await new AuthenticateHelper().loginUser(body);
            if (response.data && response.data.token) {
                
               if (response.data.rol === "Supervisor") 
               {
                    this.updateExtraCharge(this.state.extraChargeId);
               }
               else
               {
                    new NotificationsController().createNotification("Son necesarias las credenciales del supervisor.", '401');
               };
            }
        }
        catch(err){
              new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	;	
        }
    }

    async handleSubmit(event){
        try{

            event.preventDefault();
            const body = {
                land: this.state.land, //0152bc05-b4dc-4f72-86c7-261a406564fa
                methodOfPayment:  this.state.methodOfPayment, //Efectivo
                typeOfCharge: this.state.typeOfCharge, //Carta finiquito
                amount: this.state.amount, //200
                amountWord: this.state.amountWord, // doscientos pesos MXN
                paymentDate: this.state.paymentDate, // 01-01-2020
                originalClient:this.state.originalClient, // b6aa6aca-168c-48f5-ae89-e2fe5b3ae7fb
                newClient: this.state.newClient, //e7090821-6a1d-423e-8832-049f29bc284b
                observations:this.state.observations, // Fernando
                discount: this.state.discount, //0
                penalty: this.state.penalty, //0
                realAmount: ( this.state.amount - this.state.discount) + this.state.penalty  //200 
            }
            const response =  await new ExtraChargeHelper().addExtraCharge(localStorage.token, body);
            const supercode = response.data.code
            const superid = response.data.id
            new NotificationsController().createNotification(response.data.message, "201");

           
            this.state.extraChargeDetails.push({amount : this.state.amount,
                amountWord : this.state.amountWord,
                confirmation: false,
                paymentDate: this.state.paymentDate,
                penalty: this.state.penalty,
                discount:  this.state.discount,
                realAmount:  ( this.state.amount - this.state.discount) + this.state.penalty,
                methodOfPayment: this.state.methodOfPayment,
                typeOfCharge:  this.state.typeOfCharge,
                observations:this.state.observations,
                code : supercode,
                id : superid
             } );
             const totalpage = Math.ceil(this.state.extraChargeDetails.length / 10 )
             
             this.refs.form.onPageChanged({currentPage : totalpage, totalPages : totalpage , pageLimit :  10});

             var cli =  this.state.newClients.filter(client => client.id === this.state.newClient)
             this.clientInfo(this.state.newClient);
             
             this.setState({
                     methodOfPayment : 'Efectivo',
                     typeOfCharge : 'Carta finiquito',
                     paymentDate : dateString,
                     originalClient :this.state.newClient,
                     originalClientName : cli[0].Name,
                     amount: 0,
                     amountWord: '', 
                     discount: 0, 
                     penalty: 0, 
                     realAmount: 0,
                     observations: ''
             })

        }catch(error){
            new NotificationsController().createNotification(error.response.data.message, error.response.status.toString())	
        }
    }
 
    render(){
     
        return(
            <main className = "content">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-md-12">
                    <CristianModal showModal={this.state.showModal} handleSubmit = {this.handleSignIn} hideModal={this.hideModal} handleChange = {this.handleChange} />
                
            <div className="card">
            <div className="card-header">
                <h5 className="card-title">Gastos extra</h5>
            </div>
            <form >
            <div className="card-body">
            <div className="form-row">
                
                <div className="form-group col-md-2">
                    <label>Terreno</label>
                    <select className="custom-select mb-1" name="land" onChange={this.handleChange} value={this.state.landId} > 
                        {<option value={this.state.landId}> {this.props.landCode} </option> }
                    </select>
                </div>

                <div className="form-group col-md-2">
                    <label>Tipo de cobro</label>
                    <select className="custom-select mb-2" name="typeOfCharge" onChange={this.handleChange} value={this.state.typeOfCharge} > 
                        {this.state.typeOfCharges.map((typeOfCharge) => <option value={typeOfCharge}> {typeOfCharge} </option>) }
                    </select>
                </div>

                <div className="form-group col-md-2">    
                    <label className="form-label">Fecha de pago</label>
                    <input name="paymentDate" type="date" className="form-control datetimepicker-input" value={this.state.paymentDate}  onChange={this.handleChange}/>
                </div>

                <div className="form-group col-md-2">
                        <label>Metodo de pago</label>
                        <select className="custom-select mb-3" name="methodOfPayment" onChange={this.state.handleChange} value={this.state.methodOfPayment} > 
                        {this.state.methodOfPayments.map((methodOfPayment) => <option> {methodOfPayment} </option>) }
                        </select>
                    </div>

                       <div className="form-group col-md-2">
                    <label>Cliente</label>
                   
                    <select className="custom-select mb-1" name="originalClient" onChange={this.handleChange} value={this.state.originalClient} > 
                        {<option value={this.state.originalClient}> {this.state.originalClientName} </option> }
                    </select>
                </div>
                {this.state.typeOfCharge === "Traspaso del terreno" ? 
                <div className="form-group col-md-2">
                    <label>Nuevo cliente </label>
                   
                    <select className="custom-select mb-3" name="newClient" onChange={this.handleChange} value = {this.state.newClient} >  
                        {this.state.newClients.map((newClient) => <option value = {newClient.id}> {newClient.Name} </option>) }
                         </select>
                </div>: null}
            </div>
            <div className="form-row">
           
            <div className="form-group col-md-2">
                    <label>Cantidad </label>
                    <input type="number" className="form-control" name="amount" onChange={this.handleAmounts} value = {this.state.amount} placeholder="Cantidad" />
                </div>
                <div className="form-group col-md-4">
                    <label>Cantidad letra</label>
                    <input type="text" className="form-control" name="amountWord" value = {this.state.amountWord} placeholder="Cantidad en letra" />
                </div>
                <div className="form-group col-md-2">
                    <label>Descuento</label>
                    <input type="number" className="form-control" name="discount" onChange={this.handleChange} value = {this.state.discount} placeholder="Descuento" />
                </div>
                <div className="form-group col-md-2">
                    <label>Penalizacion</label>
                    <input type="number" className="form-control" name="penalty" onChange={this.handleChange} value = {this.state.penalty} placeholder="Penalizacion" />
                </div>
                <div className="form-group col-md-2">
                        <label>Pago recibido</label>
                        <input type="number" className="form-control" name="realAmount" value = {((this.state.amount - parseFloat(this.state.discount)) + parseFloat(this.state.penalty ))} placeholder="Pago escrito en el recibo" disabled />
                    </div>
       
            </div>

            <div className="form-row">
            <div className="form-group col-md-12">
                        <label>Observaciones</label>
                        <textarea className="form-control" rows="1" name ="observations" placeholder="Campo de texto libre, es admisible cualquier observacion" value = {this.state.observations}  onChange={this.handleChange} />
                    </div>
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={this.handleSubmit} >Guardar encabezado</button> 
            </div>
                    </form >
            </div>
            <div>
                <ExtraChargeDetails ref="form" extraChargeDetails = {this.state.extraChargeDetails} 
                                    clientName = {this.state.originalClientName}
                                    clientAddress = {this.state.originalClientAddress}
                                    block = {this.props.block}
                                    lot = {this.props.lot}
                                    deleteExtraCharge = {this.deleteExtraCharge}></ExtraChargeDetails>
                </div>
            </div>
            </div>
            </div>
         
            </main>

        );
    }
} 
export default addExtraChargers;