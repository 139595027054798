import axios from 'axios';
import constants from '../../config/constants';

class ClientHelper {
	async makeCall(method, url, data,token = null) {
		return axios({
			method,
			url,
			data,
			headers: {
				'x-access-token': token
			}
		});
	}

	async addClient(body, token) {

		const method = 'POST';
		const url = `${constants.BACKEND_URL}/client`;
		return this.makeCall(method, url, body, token);
	}
	
	async getClients(token) {
		const method = 'GET';
		const url = `${constants.BACKEND_URL}/client`;
		return this.makeCall(method, url, {}, token);
	}

	async editClient(body, token){

		const method = 'PUT';
		const url = `${constants.BACKEND_URL}/client`;
		 return this.makeCall(method, url, body, token);
	}
	
	async deleteClient(id, token) {
		const method = 'DELETE';
		const url = `${constants.BACKEND_URL}/client/${id}`;
		return this.makeCall(method, url, {}, token);
	}
}
export default ClientHelper;
