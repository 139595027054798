import React from 'react';
import monthlyPaymentsHelper from '../../helpers/monthlyPayments/monthlyPayments.request';
import Pagination from '../Pagination'

class reportList extends React.Component {
    constructor(props){
    super(props);
    this.state = {
        allCountries : [],
        currentCountries: [], 
        currentPage: null, 
        totalPages: null
    }
}

componentDidMount() {
  this.getRecipts();
}

getRecipts = async () => {
  const response = await new monthlyPaymentsHelper().getPaginatedRecipts(localStorage.token, '2049e466-c668-4b84-8617-4db28519d457', 10000,1);
  this.setState({
    allCountries : response.data.recipts
  })
};

onPageChanged = data => {
  const { allCountries } = this.state;
  const { currentPage, totalPages, pageLimit } = data;
  const offset = (currentPage - 1) * pageLimit;
  const currentCountries = allCountries.slice(offset, offset + pageLimit);

  this.setState({ currentPage, currentCountries, totalPages });
}

  render() {
    const { allCountries, currentCountries, currentPage, totalPages } = this.state;
    const totalCountries = allCountries.length;

    if (totalCountries === 0) return null;

    const headerClass = ['text-dark py-2 pr-4 m-0', currentPage ? 'border-gray border-right' : ''].join(' ').trim();

    return (
      <div className="container mb-5">
        <div className="row d-flex flex-row py-5">
          <div className="w-100 px-4 py-5 d-flex flex-row flex-wrap align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center">
              <h1></h1>
              <h2 className={headerClass}>
                <strong className="text-secondary">{totalCountries}</strong> Countries
              </h2>
              { currentPage && (
                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                  Page <span className="font-weight-bold">{ currentPage }</span> / <span className="font-weight-bold">{ totalPages }</span>
                </span>
              ) }
            </div>
            <div className="d-flex flex-row py-4 align-items-center">
              <Pagination totalRecords={totalCountries} pageLimit={18} pageNeighbours={1} onPageChanged={this.onPageChanged} />
            </div>
          </div>
          <table className = "table table-sm">
          <thead>
                    <tr>
                        <th>Folio</th>
                        <th>Fecha</th>
                        </tr>
                      </thead>
          <tbody> 
                    {currentCountries.map((payment) => (
                    <tr key={payment.id}>
                    <td>{payment.code}</td>
                    <td>{payment.expirationDate}</td>
                    </tr>
                    ))}
                    </tbody> 
            </table>
        </div>
      </div>
    );
  }
}
export default reportList;