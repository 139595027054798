import React from 'react'
import VendorForm from './vendorForm'
import VendorHelper from '../../helpers/vendor/vendor.request'
import NotificationsController from '../NotificationsController';

class editVendor extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            code : this.props.code, 
            firstName : this.props.firstName,
            lastName : this.props.lastName,
            email : this.props.email,
            rfc : this.props.rfc,
            zipCode : this.props.zipCode,
            phoneNumber : this.props.phoneNumber,
            formErrors:  {
                code: '',
                firstName: '',
                lastName: '',
                email: '',
                rfc: '',
                zipCode: '',
                phoneNumber: ''
              }
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    async handleChange(event){
        this.setState({
            [event.target.name] : event.target.value
        });
        // const body = {
        //     code: this.state.code,
        //     firstName : this.state.firstName,
        //     lastName : this.state.lastName,
        //     email : this.state.email,
        //     rfc : this.state.rfc,
        //     zipCode : this.state.zipCode,
        //     phoneNumber : this.state.phoneNumber
        // };
  
    }
    async handleSubmit(){
        try{
            const body = {
                code: this.state.code,
                firstName : this.state.firstName,
                lastName : this.state.lastName,
                email : this.state.email,
                rfc : this.state.rfc,
                zipCode : this.state.zipCode,
                phoneNumber : this.state.phoneNumber
            };
      
            const response = await new VendorHelper().editVendor(body, localStorage.token);
           new NotificationsController().createNotification(response.data.message,response.status.toString());
        }
        catch(error){
            new NotificationsController().createNotification(error.message, "400")
        }

    }

    render() {
      return(  <VendorForm handleSubmit = {this.handleSubmit} handleChange = {this.handleChange}
            code = {this.state.code}
            firstName = {this.state.firstName}
            lastName = {this.state.lastName}
            email = {this.state.email}
            rfc = {this.state.rfc}
            zipCode = {this.state.zipCode}
            phoneNumber = {this.state.phoneNumber}
            formErrors = {this.state.formErrors}

        ></VendorForm>
      );
    }

}
export default editVendor;