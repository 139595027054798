import React from 'react';
import PermissionsHelper from '../helpers/permissions/permissions.helper';
import UserHelper from '../helpers/user/user.request';
import PermissionModal from '../components/PermissionsModal.js';
import NotificationsController from './NotificationsController';
class ListUsers extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			users: [], 
			show: false,
			currentUser: {},
			permissions: []
		};

		this.hideModal = this.hideModal.bind(this);
	}

	componentDidMount() {
		this.getUsers();
	}

	handleEdit(user) {
		
	}

	handleDelete(id) {
		try {
		} catch (error) {
			new NotificationsController().createNotification(error.message, "400")
		}
	}

	handlePermit(popo){
	
	}

	showModal = async (user) => {

			const userPermissions = await new PermissionsHelper().getUserPermissions(localStorage.token, user.id );

			const permit = userPermissions.data.permissions;
		
		this.setState({ show: true, currentUser: user, permissions: permit });
	};

	hideModal = () => {
		this.setState({ show: false });
	};
	
	getUsers = async () => {
		try {
			const response = await new UserHelper().getUsers(localStorage.token);
			this.setState({
				users: response.data
			});
			new NotificationsController().createNotification("Registro encontrados", "201");
		} catch (error) {
			new NotificationsController().createNotification(error.message, "400")
		}
	};



	render() {
		return (
			<main className="content">
				<div className="container-fluid p-0">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h5 className="card-title">Lista de usuarios</h5>
									{localStorage.rol === "Supervisor" ? 
									<h6 className="card-subtitle text-muted">
										En el siguiente link puede agregar usuarios nuevos{' '}
										<a href="/Register" rel="noopener noreferrer nofollow">
											Aquí
										</a>
									</h6>
									: null}
								</div>
								<div className="card-body">
								<div className="table-responsive text-nowrap">
									<table className="table table-sm">
										<thead>
											<tr>
												<th>Nombre</th>
												<th>Apellidos</th>
												<th>Correo</th>
												<th>Fecha creacion</th>
												<th>Acciones</th>
											</tr>
										</thead>
										<tbody>
											{this.state.users.map((user) => (
												<tr key={user.id}>
													<td>{user.firstName}</td>
													<td>{user.lastName}</td>
													<td>{user.email}</td>
													<td>{user.createdAt}</td>
													<td>
												
														<PermissionModal 
															show={this.state.show}
															handleClose={this.hideModal} 
															user={this.state.currentUser} 
															permissions={this.state.permissions}
															currentUser = {0} >
															<p>Modal</p>
														</PermissionModal>
														{localStorage.rol === "Supervisor" ? 
														<button
															type="button"
															className="btn btn-success btn-sm"
															onClick={() => this.showModal(user)}
														>
															Permisos
														</button> : null}
														{/* <button
															type="button"
															className="btn btn-primary btn-sm"
															onClick={() => this.handleEdit(user)}
														>
															Editar
														</button> */}
														{/* <button
															type="button"
															className="btn btn-danger btn-sm"
															onClick={() => this.handleDelete(user.id)}
														>
															Eliminar
														</button> */}
													</td>
													{/* <td className="table-action">
														<a href="/">
															<i className="align-middle" data-feather="edit-2" />
														</a>
														<a href="/">
															<i className="align-middle" data-feather="trash" />
														</a>
													</td> */}
												</tr>
											))}
										</tbody>
									</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		);
	}
}

export default ListUsers;
