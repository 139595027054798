import axios from 'axios';
import constants from '../../config/constants';

class DrawHelper{

async makeCall(method, url, data, token = null){
    return axios({
        method,
        url,
        data,
        headers:{
            'x-access-token': token
        }
    });
}
    async closeSavedLand(body, token){
        const method = 'POST';
        const url  = `${constants.BACKEND_URL}/draw/closeSavedLand`
        return this.makeCall(method, url, body, token);
    }
    
    async addManualPayment(body, token){
        const method = 'POST';
        const url = `${constants.BACKEND_URL}/draw/addManualPayment`
        return this.makeCall(method, url, body, token);
    }
    async addDraw(body, token){
        const method = 'POST';
        const url =`${constants.BACKEND_URL}/draw/saveLand`;
        return this.makeCall(method, url, body, token);
    }
    
    async getDraws(token){
        const method = "GET";
        const url = `${constants.BACKEND_URL}/draw`;
        return this.makeCall(method, url, {}, token);
    }

    async editDraw(body, token){
        const method = 'PUT';
        const url = `${constants.BACKEND_URL}/draw`;
        return this.makeCall(method, url, body, token);
    }

    async deleteDraw(id, token){
        const method = 'DELETE';
        const url = `${constants.BACKEND_URL}/draw/${id}`
        return this.makeCall(method, url, {}, token);
    }

    async getLands(token){
        const method = 'GET';
        const url = `${constants.BACKEND_URL}/draw/lands`
        return this.makeCall(method, url, {}, token);
    }
    async getLandsEdit(token){
        const method = 'GET';
        const url = `${constants.BACKEND_URL}/draw/landsEdit`
        return this.makeCall(method,url,{}, token)  
    }
    
    async getClients(token){
        const method = 'GET';
        const url = `${constants.BACKEND_URL}/draw/clients`
        return this.makeCall(method, url, {}, token);
    }

    async getVendor(token){
        const method = 'GET';
        const url = `${constants.BACKEND_URL}/draw/vendors`
        return this.makeCall(method, url, {}, token);
    }
    async getDrawPayments(token, landId){
        const method = 'GET';
        const url = `${constants.BACKEND_URL}/draw/DrawPayments/${landId}`
         return this.makeCall(method, url, {}, token)
    }
    async paySavedLand(body, token)
    {
        const method = 'POST';
        const url = `${constants.BACKEND_URL}/draw/paySavedLandFee`
        return this.makeCall(method, url, body, token)
    }
    async getSavedLandReport(id, token)
    {
        const method = "GET";
        const url = `${constants.BACKEND_URL}/report/savedLand/${id}`
        return this.makeCall(method, url, {}, token)
    }
}
export default DrawHelper;