import axios from 'axios';
import constants from '../../config/constants';

class BankReconciliationHelper {
	async makeCall(method, url, data, token = null) {
		return axios({
			method,
			url,
			data,
			headers: {
				['x-access-token']: token
			}
		});
	}
	async getBankReconciliation(token, fechainicio, fechafinal) {
        // const method = 'GET';
        // const url = `${constants.BACKEND_URL}/report/unconfirmedmonthlyPayment/${fechainicio}/${fechafinal}/`;    
        // return this.makeCall(method, url, {}, token);

		const method = 'GET';
        const url = `${constants.BACKEND_URL}/extraCharge/unconfirmedExtraCharges/${fechainicio}/${fechafinal}/`;
		return this.makeCall(method, url, {}, token);
	}

	async postBankPayment( token,body ){
		const method = 'POST';
		const url = `${constants.BACKEND_URL}/monthlyPayment/bankPayment`
		return this.makeCall(method, url, body, token);
	}
}
export default BankReconciliationHelper;