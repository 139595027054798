import React from 'react'
import TableController from '../reports.ReactTableController'
import ExtraChargeHelper from '../../helpers/extraCharge/extraCharge.request'
import AddExtraChargers from './addExtraChargers';
class listExtraChargers extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            extraCharges : [], 
            lands : [],
            Block : '' ,
            Lot : ''
        }
    }
    componentDidMount(){
        this.getInitialValues();
    }
    async handleEdit(cell){
      
        this.setState({
             landCode : cell.row.original.Lands.code,
             
             block :cell.row.original.Lands.block ,
             lot: cell.row.original.Lands.lot,

             landId : cell.row.original.land,
             clientName : ''.concat(cell.row.original.Clients.firstName, ' ',cell.row.original.Clients.lastName) ,
             clientId : cell.row.original.client,
             showEdit : true
        });
        
    
    }

    getInitialValues = async () => {
        try{
            const response = await new ExtraChargeHelper().getlLndForClientRelation(localStorage.token);
            this.setState({
                extraCharges : response.data
            })
          
        }catch(error){

        }
    }

 


   render(){
    if (this.state.showEdit){
       return( <AddExtraChargers landCode = {this.state.landCode} landId = {this.state.landId} clientId  = {this.state.clientId}  
         clientName = {this.state.clientName}
         block = { this.state.block}
         lot = {this.state.lot} ></AddExtraChargers>);
    }
    else{
    if(this.state.extraCharges.length === 0)
    {return (<div className="box">
                <div className="d-flex justify-content-center aling-items-center ">
                <div className="spinner-border" role="status" />
                </div>
            </div>);}
       return (  <main className="content">

       <div className="container-fluid p-0">
       <div className="row">
           <div className="col-12">
               <div className="card">
                   {/* <div className="card-header">
                   <h5 className="card-title">Cobros extra</h5>
                                <h6 className="card-subtitle text-muted">
                                    En el siguiente link puede agregar cobros nuevos{' '}
                                    <a href="/AgregarCobroExtra" rel="noopener noreferrer nofollow">
                                        Aquí
                                    </a>
                                </h6>
                   </div> */}   
            <div className="card-body">
            <div className="row">
            <div className="table-responsive text-nowrap">
      <TableController 
      data = {this.state.extraCharges}
      Header = {"Cobros extras"}
      columns = {
          [
              {
                  Header : 'Terreno',
                  accessor: 'Lands.code'
              },
              {
                  Header: 'Nombre cliente',
                  accessor: 'Clients.firstName',
              },
              {
                Header: 'Apellido cliente',
                accessor: 'Clients.lastName',
            },
              {
                Header: 'Manzana',
                accessor: 'Lands.block',
            },
            {
                Header: 'Lote',
                accessor: 'Lands.lot',
            },
            {
                Header : 'Accion',
                accessor: '[row identifier to be passed to button]',
                Cell: ({cell}) => (
                <button className="btn btn-primary btn-sm" 
                onClick={() =>this.handleEdit(cell)}>Agregar cargo extra
                </button>
                    )
            }
          ]
      }>

      </TableController>
    </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </main>
                       );
    }

   }
}
export default listExtraChargers;