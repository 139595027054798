import React from 'react'
import PaymentsReciptsList from './paymentsReciptsList'
class monthlyPaymentsForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
           methodOfPayments : ['Efectivo','Cheque nominativo','Transferencia electronica de fondos',
           'Tarjeta de credito','Monedero electronico','Dinero electronico','Vales de despensa','Tarjeta de debito'],
           currencies : [{id:'MEX', code: '(MXN) Peso Mexicano'},{ id :'USD', code: '(USD) Dolar americano'}],
           paymentMethods : ["Pago en una sola exhibicion", "Pago en parcialidades o diferido"]
        }
    }


   async clickFormPayment() 
    { 
    
             
       await this.refs.PaymentList.onPageChanged({currentPage : 1, totalPages :  2 , pageLimit :  20});
    }
render(){
    const { formErrors } = this.props;

return( 
                <div>
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">Pago de mensualidades</h5>
                    </div>

                    <div className="card-body">
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Terreno</label>
                            <select className="custom-select mb-3" name="land" onChange={this.props.handleLand} value={this.props.land} > 
                                <option defaultValue="" disabled selected>Seleccione un terreno</option> 
                                {this.props.lands.map((land) => <option value={land.id}> {land.code} </option>) }
                            </select>
                        </div>
                        <div className="form-group col-md-3">
                            <label >Número de contrato</label>
                            <input type="text" className="form-control" name="contractNumber" value= {this.props.contractNumber} placeholder="Numero de contrato" disabled/>
                        </div>
                        <div className="form-group col-md-2">
                                <label>Costo total:</label>
                                <input type="text" className="form-control" name="totalPrice" placeholder="Costo total" value={this.props.totalPrice}  disabled/>
                            </div>
                        <div className="form-group col-md-2">
                                <label name="Block">Manzana / Lote: </label>
                                <input type="text" className="form-control" value = {this.props.BlockAndLot} placeholder="Manzana / Lote"  disabled/>
                       </div>
                        <div className="form-group col-md-2">
                                    <label>Superficie M2</label>
                                    <input type="text" className="form-control" id="surfaceM2" placeholder="SuperficieM2" value = {this.props.surfaceM2} disabled/>
                        </div>
                        </div>
           
                    </div>
                    </div>
                  

                <div className="card">
    
                <div className="card-body">

                <form >

                <div className="form-row">

                    <div className="form-group col-md-1">
                        <label>No recibo</label>
                        <select 
                        className={formErrors.reciptId.length > 0 ? "form-control is-invalid" : "custom-select mb-3"} 
                        
                        name="reciptId" onChange={this.props.handleRecipts} value={this.props.reciptId} > 
                            <option value = {0} selected>Folio</option> 
                            {this.props.codesOfRecipts.map((recipt) => <option value ={recipt.id} > {recipt.code} </option>) }
                        </select>
                        {formErrors.reciptId.length > 0 && (<span className="text-danger">{formErrors.reciptId}</span>)}
                    </div>
                    <div className="form-group col-md-2">
                        <label>Metodo de pago</label>
                        <select className="custom-select mb-3" name="methodOfPayment" onChange={this.props.handleChange} value={this.props.methodOfPayment} > 
                        {this.state.methodOfPayments.map((methodOfPayment) => <option> {methodOfPayment} </option>) }
                        </select>
                    </div>
                    <div className="form-group col-md-2">
                        <label>Moneda</label>
                        <select className="custom-select mb-3" name="currency" onChange={this.props.handleChange} value={this.props.currency} > 
                        {this.state.currencies.map((currencie) => <option value = {currencie.id}> {currencie.code} </option>) }
                        </select>
                    </div>
                    <div className="form-group col-md-1">
                        <label>Tipo de cambio</label>
                        <input type="number" className="form-control" name="currencyExchange" onChange={this.props.handleChange} value = {this.props.currencyExchange} placeholder="Tipo de cambio" />
                        {formErrors.currencyExchange.length > 0 && (<span className="text-danger">{formErrors.currencyExchange}</span>)}
                    </div>
                    <div className="form-group col-md-2">
                                <label> Forma de pago </label>
                                    <select className="custom-select mb-3" name="paymentMethod" value = {this.props.paymentMethod} onChange = {this.props.handleChange}  > 
                                    {this.state.paymentMethods.map((paymentMethod) => <option> {paymentMethod} </option>) }
                                    </select>
                    </div>
                    <div className="form-group col-md-1">
                        <label>Abono</label>
                        <input  type="number"  
                         className= {formErrors.amount.length > 0 ? "form-control is-invalid" :"form-control" }
                        name="amount" onChange={this.props.handleChange} value = {this.props.amount} placeholder="Abono mensual $"/>
                        {formErrors.amount.length > 0 && (<span className="text-danger">{formErrors.amount}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                        <label>En letra</label>
                        <input type="text" 
                        className= {formErrors.amountWord.length > 0 ? "form-control is-invalid" :"form-control" }
                        name="amountWord" onChange={this.props.handleChange} value = {this.props.amountWord} placeholder="Abono escrito con letra" />
                        {formErrors.amountWord.length > 0 && (<span className="text-danger">{formErrors.amountWord}</span>)}
                    </div>
                </div>
                
                <div className="form-row">
                   
                    <div className="form-group col-md-1">
                        <label>Descuento</label>
                        <input type="number" className="form-control" name="discount" onChange={this.props.handleChange} value = {this.props.discount} placeholder="Descuento" />
                    </div>

                    <div className="form-group col-md-1">
                        <label>Penalizacion</label>
                        <input type="number" className="form-control" name="penalty"  onChange={this.props.handleChange} value = {this.props.penalty} placeholder="Penalizacion" />
                    </div>

                    <div className="form-group col-md-2">    
                        <label className="form-label">Fecha de pago</label>
                        <input name="paymentDate" type="date" className="form-control datetimepicker-input" value={this.props.paymentDate}  onChange={this.props.handleChange}/>
                    </div>
                
                    <div className="form-group col-md-2">    
                        <label className="form-label">Fecha de vencimiento</label>
                        <input name="creationDate" type="date" className="form-control datetimepicker-input" value={this.props.expirationDate}  onChange={this.props.handleChange} disabled/>
                    </div>

                    <div className="form-group col-md-2">
                        <label>Pago recibido</label>
                        <input type="number" className="form-control" name="realAmount" value = {((this.props.amount - parseFloat(this.props.discount)) + parseFloat(this.props.penalty ))} placeholder="Pago escrito en el recibo" disabled />
                    </div>
                    <div className="form-group col-md-2">
                        <label>Saldo anterior</label>
                        <input type="text" className="form-control" name="previousBalance" value = {this.props.previousBalance} placeholder="Saldo anterior" disabled />
                    </div>
                    <div className="form-group col-md-2">
                        <label>Saldo actual</label>
                        <input type="text" className="form-control" name="actualBalance" value = {this.props.previousBalance - this.props.amount} placeholder="Saldo actual" disabled />
                    </div>
                    </div>

                    <div className="form-row">
                    <div className="form-group col-md-12">
                        <label>Observaciones</label>
                        <input type="text" className="form-control" name="observations" value = {this.props.observations} onChange={this.props.handleChange} placeholder="Observacion"/>
                    </div>
                    </div>
                    {this.props.showButton ? <button type="submit" className="btn btn-primary" onClick={this.props.handleSubmit} >Guardar encabezado</button> : null}
                    {/* {this.props.showButton ? <button type="submit" className="btn btn-primary" onClick={() => this.props.handleSubmit()} >Guardar encabezado</button> : null} */}
                </form>
                </div>
                </div>
                <PaymentsReciptsList payments = {this.props.payments} BlockAndLot ={this.props.BlockAndLot}  ref="PaymentList" modifyReceipts = {this.props.modifyReceipts}
                clientName = {this.props.clientName}
                clientAddress = {this.props.clientAddress}> </PaymentsReciptsList>
    </div>
    
);
    }
} 
export default monthlyPaymentsForm; 