import React from 'react';
import DrawHelper from '../../helpers/draw/draw.request'
import EditDraw from './EditDraw';
import NotificationsController from '../NotificationsController'
import TableController from '../reports.ReactTableController'
class ListDraw extends React.Component{
    constructor(props){
        super(props);
        this.state= {
            draws : [],
            drawPayments : [],
            mensajeTabla : ''
        };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);  

}
componentDidMount(){
    this.getDraws();
}

async handleDelete(id){
try{
    this.setState({
        draws: this.state.draws.filter(draw => draw.id !== id)
    });
    await new DrawHelper().deleteDraw(id, localStorage.token);
}
catch(error){
    new NotificationsController().createNotification(error.message, "400")
    }
}

async handleEdit(draws){

    this.setState({showEdit : true});

     this.setState(
         {
             id : draws.id,
             client : draws.client,
             land : draws.land,
             advance : draws.advance,
             vendor : draws.vendor,
             creationDate : draws.creationDate,
             observations : draws.observations,
             paid : draws.paid,
             processType : draws.processType
            }
         )
    this.setView();
}
displaymensaje(){
    if (this.state.mensajeTabla === "Cargando . . ."  ){
        return(<div className="spinner-border spinner-border-sm" role="status" />);
    }
    else{
       return( <h3>{this.state.mensajeTabla}</h3>);
    }
}

getDraws = async () => {
    try{
        this.setState({
            mensajeTabla : 'Cargando . . .'
        });
        const response = await new DrawHelper().getDraws(localStorage.token);
        response.data.sort((a,b) => a.Lands[0].block - b.Lands[0].block || a.Lands[0].lot- b.Lands[0].lot);
        if (response.data.length ===0) {
            this.setState({
                mensajeTabla : 'No hay datos para mostrar'
            })
        }
        else {

            this.setState({
                draws : response.data,
                mensajeTabla : ''
            });
        }
        new NotificationsController().createNotification("Registro encontrados", "201");
    }
    catch(error){
        new NotificationsController().createNotification(error.response.data.message, error.response.status.toString())
    }
}
setTable() {
    if( this.state.mensajeTabla.length > 0 )
    {return (
                <div className="d-flex justify-content-center aling-items-center ">
                {this.displaymensaje()}
                </div>
           );}
    else {

        return(
            <TableController 
      data = {this.state.draws}
      Header = {"Apartados"}
      columns = {
        [
            {
                Header : 'Terreno',
                accessor: 'Lands[0].code'
            },
            {
                Header : 'Manzana',
                accessor: 'Lands[0].block'
            },
            {
                Header : 'Lote',
                accessor: 'Lands[0].lot'
            },
            {
                Header : 'Cliente nombre',
                accessor: 'Clients[0].firstName'
            },
            {
                Header : 'Cliente apellido',
                accessor: 'Clients[0].lastName'
            },
            {
                Header : 'Vendedor nombre',
                accessor: 'Vendors[0].firstName'
            },
            {
                Header : 'Vendedor apellido',
                accessor: 'Vendors[0].lastName'
            },
            {
                Header : 'Anticipo',
                accessor: 'advance'
            },
            {
                Header : 'Accion',
                accessor: '[row identifier to be passed to button]',
                Cell: ({cell}) => (
                <button className="btn btn-primary btn-sm" 
                onClick={() => this.handleEdit(cell.row.original)}>Pagar anticipos
                </button>
                    )
            }
        ]
    }
      >
      </TableController>
    // <table className="table table-sm">                     
    // <thead>
    //     <tr>
    //         <th>Terreno</th>
    //         <th>Cliente</th>
    //         <th>Vendedor</th>
    //         <th>Anticipo</th>
    //         <th>Acciones</th>
    //     </tr>
    // </thead>
    // <tbody>
    //       {this.state.draws.map((draw) => (
    //          <tr key={draw.id}>
    //             <td>{draw.Lands[0].code} </td>
    //             <td>{''.concat(draw.Clients[0].firstName, ' ', draw.Clients[0].lastName) } </td>   
    //             <td>{''.concat(draw.Vendors[0].firstName, ' ', draw.Vendors[0].lastName) } </td>                                                   
    //             <td>{draw.advance}</td>
    //             <td>
    //                 <button
    //                     type="submit"
    //                     className="btn btn-primary btn-sm"
    //                     onClick={() => this.handleEdit(draw)}
    //                     >
    //                     Pagar anticipos
    //                 </button>
    //             </td>
    //         </tr>
    //     ))}  
    // </tbody> 
    // </table> 
);
}
}
    setView(){
        if (this.state.showEdit)
        {
            return(
            <div>  
                <EditDraw
                    id = {this.state.id}
                    client = {this.state.client}
                    land = {this.state.land}
                    advance = {this.state.advance}
                    vendor = {this.state.vendor}
                    creationDate = {this.state.creationDate}
                    observations = {this.state.observations}
                    paid = {this.state.paid}
                    processType = {this.state.processType} 
                ></EditDraw>
            </div>
            );
        }else{
            return(<main className="content">
			
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Lista de apartados</h5>
                                <h6 className="card-subtitle text-muted">
                                    En el siguiente link puede agregar apartados nuevos{' '}
                                    <a href="/agregarApartado" rel="noopener noreferrer nofollow">
                                        Aquí
                                    </a>
                                </h6>
                            </div>
                            <div className="card-body">
                            <div className="table-responsive text-nowrap">
                                {this.setTable()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
            </main>);
        }

    }
    render(){
        return <div> {this.setView()} </div>
    }
}

export default ListDraw;