import React from 'react'
import Pagination from '../Pagination'
import ExtraChargeReports from '../Reports/ExtraChargesReports/ExtraChargeReports'

class getExtraChargersDetail extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            currentExtraCharges : [],
            clientName :'',
            clientAddress : '',
        }
    }

    onPageChanged = data => {

        const { extraChargeDetails } = this.props;
        const { currentPage, totalPages, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;
        const currentExtraCharges = extraChargeDetails.slice(offset, offset + pageLimit);
      
        this.setState({ currentPage, currentExtraCharges, totalPages });

      }
        render(){  

            const { extraChargeDetails } = this.props;
            const { currentExtraCharges } = this.state;
            const totalpayments = extraChargeDetails.length;
            if (totalpayments === 0) return null;
            
        return(<div className="card">
        <div className="card-header">
            <h5 className="card-title">Gastos extra</h5>
        </div>
        <div className="card-body">
        <Pagination totalRecords={totalpayments} pageLimit={10} pageNeighbours={1} onPageChanged={this.onPageChanged} />
        <div className="table-responsive text-nowrap">
            <table className="table table-striped table-sm table-hover">
                <thead>
                    <th>Folio</th>
                    <th>Fecha pago</th>
                    <th>Abono</th>
                    <th>En letra</th>
                    <th>Descuento</th>
                    <th>Penalizacion</th>
                    <th>Pago total</th>
                    <th>Metodo de pago</th>
                    <th>Tipo de cargo</th>
                    <th>Observaciones</th>
                  
                    <th>Modificar</th>
                    <th>Imprimir</th>
                </thead>
                <tbody> 
        {currentExtraCharges.map((extraCharge) => (
                <tr key = {extraCharge.id}>
                <td>{extraCharge.code.toString().padStart(4, "0")}</td>
                <td>{extraCharge.paymentDate}</td>
                <td>{extraCharge.amount}</td>  
                <td>{extraCharge.amountWord}</td>
                <td>{extraCharge.discount}</td>
                <td>{extraCharge.penalty}</td>
                <td>{extraCharge.realAmount}</td>
                <td>{extraCharge.methodOfPayment}</td>
                <td>{extraCharge.typeOfCharge}</td>
                <td>{extraCharge.observations}</td>
             
                <td><button type="submit"   
                            className="btn btn-warning btn-sm"
                            onClick= {(e) => this.props.deleteExtraCharge(e, extraCharge.id)}> Modificar 
                    </button>
                </td>    
               <td>
                   <ExtraChargeReports  amount ={extraCharge.amount} 
                                        amountWord = {extraCharge.amountWord}
                                        code=  {extraCharge.code.toString()} 
                                        discount = {extraCharge.discount}
                                        methodOfPayment= {extraCharge.methodOfPayment}
                                        observations = {extraCharge.observations}
                                        paymentDate= {extraCharge.paymentDate}
                                        penalty= {extraCharge.penalty}
                                        realAmount = {extraCharge.realAmount}
                                        typeOfCharge= { extraCharge.typeOfCharge }
                                        clientName ={this.props.clientName}
                                        clientAddress = {this.props.clientAddress}
                                        block = {this.props.block}
                                        lot = {this.props.lot}></ExtraChargeReports>

                </td>

                </tr>))}
                </tbody>
        </table> 
            </div>
        </div>
   
    </div>);
    }
}
export default getExtraChargersDetail;