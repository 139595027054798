import React from 'react';
import monthlyPaymentsHelper from '../../../../helpers/reports/reports.helper';
import NotificationsController from '../../../NotificationsController';
import ReactExport from 'react-export-excel'
import TableController from '../../../reports.ReactTableController'
import _ from "lodash";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class monthlyPaymentsResume extends React.Component{
    constructor(props){
        super(props);
        var dateObj = new Date();
        var dateString = ''.concat(dateObj.getFullYear().toString(),'-',String(dateObj.getMonth() + 1).padStart(2, '0'), '-',String(dateObj.getDate()).padStart(2, '0'));
        this.state = {
            monthlyPayment : [],
            initialDate :dateString,
            finalDate : dateString,
            mensajeTabla : ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.searchData = this.searchData.bind(this);
    }
    componentDidMount(){
        this.searchMonthlyPayments();
    }
    async handleChange(event) {
        event.preventDefault();
        this.setState({
           [event.target.name]: event.target.value
         });
      }
    
    
    async searchMonthlyPayments(){
        const initialDate  = this.state.initialDate
        const finalDate = this.state.finalDate
        try{
            this.setState({
                mensajeTabla : 'Cargando . . .'
            });
            const monthlyPaymentResponse  = await new monthlyPaymentsHelper().getMonthlyCash(localStorage.token, initialDate, finalDate);
        
            if(monthlyPaymentResponse.data.length === 0)
            {
                this.setState({
                    mensajeTabla : 'No hay datos para mostrar'
                });
            }
            else 
            {
                this.setState({
                    initialDate : initialDate,
                    finalDate  : finalDate ,
                    monthlyPayment : monthlyPaymentResponse.data,
                    mensajeTabla : ''
                });  
            }

             new NotificationsController().createNotification(monthlyPaymentResponse.data.message, monthlyPaymentResponse.status.toString())
     
        }catch(err){
           new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())
   }

    }
async searchData(event){
    event.preventDefault();

   
    this.searchMonthlyPayments();
} 

displaymensaje(){
    if (this.state.mensajeTabla === "Cargando . . ."  ){
        return(<div className="spinner-border spinner-border-sm" role="status" />);
    }
    else{
       return( <h3>{this.state.mensajeTabla}</h3>);
    }
}
setView (){
    //  const total =_.sum(_.map(this.state.monthlyPayment, d => d.amount))
     //     <td><b>Monto total: </b>{_.sum(_.map(this.state.monthlyPayment, d => d.amount))}</td>
                    // <td><b>Descuento: </b>{_.sum(_.map(this.state.monthlyPayment, d => d.discount))}</td>
                    // <td><b>Penalizacion: </b>{_.sum(_.map(this.state.monthlyPayment, d => d.penalty))}</td>
                    // <td><b>Pago recibido: </b>{_.sum(_.map(this.state.monthlyPayment, d => d.realAmount))}</td>
    if( this.state.mensajeTabla.length > 0 )
    {return (
                <div className="d-flex justify-content-center aling-items-center ">
                {this.displaymensaje()}
                </div>
           );}
    else
return( 
   
     <TableController  data = {this.state.monthlyPayment} 
    Header = {"Busqueda de vencidos"} 
    columns = {
                [
                    {
                        Header: 'Folio',
                        accessor: 'paymentCode',
                    },
                    {
                        Header : 'Terreno',
                        accessor : 'landCode'
                    },
                    {
                        Header: 'Nombre cliente',
                        accessor: 'clientFirstName',
                    },
                    {
                        Header: 'Apellido cliente',
                        accessor: 'clientLastName',
                    },
                    {
                        Header : 'Entrada',
                        accessor: 'enterType'
                    },
                    { 
                   
                        Header: 'Monto',
                        Footer:'amount' ,
                        accessor: 'amount',
                    },
                    {
                        Header: 'Descuento',
                        Footer: 'discount',
                        accessor: 'discount',
                    },
                    {
                        Header: 'Penalizacion',
                        Footer: 'penalty',
                        accessor: 'penalty',
                    },
                    {
                        Header: 'Pago recibido',
                        Footer: 'realAmount',
                        accessor: 'realAmount',
                       
                    },
                    {
                        Header: 'Observaciones',
                        accessor: 'observations',
                    }
                
                 
                    ] } ></TableController> );

                    
}



render(){
   
   return (

    <main className = "content">
    <div className="container-fluid p-0">
        
    <div className="card">
    <div className="card-header">
<h5 className="card-title">Corte de caja</h5>
</div>
    <div className="card-body">
    <form >
       <div className="form-row">
       <div className="form-group col-md-3">
           <label className="form-label">Fecha inicial </label>
           <input name="initialDate" type="date" className="form-control datetimepicker-input" value={this.state.initialDate} onChange = {this.handleChange}/>        
       </div> 
       <div className="form-group col-md-3">
           <label className="form-label">Fecha final</label>
           <input name="finalDate" type="date" className="form-control datetimepicker-input" value={this.state.finalDate} onChange = {this.handleChange}/>
       </div> 

       <div className="form-group col-md-6">
           <div className = "form-group">
       <div className="vertical-center">
       <a className="btn btn-primary" onClick={ this.searchData}>	
       <i className="align-middle mr-2 fas fa-search" data-feather="sliders" />{' '}
       <span className="align-middle">Buscar</span></a>
       <ExcelDownloadButton  monthlyPayment= {this.state.monthlyPayment}></ExcelDownloadButton>
       </div>
       </div> 
       </div> 
       </div> 
   </form>
    </div>
    <div className="card-body">
    {this.setView()}

    </div>
    
                                </div>
                

                              </div>
                              </main>
        );
}
}
  export default monthlyPaymentsResume;

  
class ExcelDownloadButton extends React.Component {

    render() {
       
        return (

            <ExcelFile filename = "Mensualidades" element={
            <a className="btn btn-success">
            <i className="align-middle mr-2 fas fa-file-excel" data-feather="sliders" />{' '}
					<span className="align-middle">Descargar</span></a>}>


                <ExcelSheet data={this.props.monthlyPayment} name="Vencidos">
                    <ExcelColumn label="Folio" value="paymentCode"/>
                    <ExcelColumn label="Nombre cliente" value="clientFirstName"/>
                    <ExcelColumn label="Apellido cliente" value="clientLastName"/>
                    <ExcelColumn label="Monto" value="amount"/>
                    <ExcelColumn label="Descuento" value="discount"/>
                    <ExcelColumn label="Penalizacion" value="penalty"/>
                    <ExcelColumn label="Pago recibido" value="realAmount"/>
                    <ExcelColumn label="Observaciones" value="observations"/>
                </ExcelSheet> 
            </ExcelFile>
        );
    }
}  

