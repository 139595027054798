import React from 'react';
import NotificationsController from '../../../NotificationsController';
import MonthlyCashHelper from '../../../../helpers/reports/reports.helper';
import TableController from '../../../reports.ReactTableController';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class monthlyCashReport extends React.Component{
    constructor(props){
    super(props);
    var dateObj = new Date();
    var dateString = ''.concat(dateObj.getFullYear().toString(),'-',String(dateObj.getMonth() + 1).padStart(2, '0'), '-',String(dateObj.getDate()).padStart(2, '0'));
    this.state= {
        MonthlyCashData : [],
        InitialDate :  dateString,
        FinalDate :  dateString,
        mensajeTabla : ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.searchMonthlyCashData = this.searchMonthlyCashData.bind(this);
    }
    componentDidMount(){
         this.MonthlyCashData();
    }

    async handleChange(event) {
        event.preventDefault();
        this.setState({
           [event.target.name]: event.target.value
         });
      }

    async searchMonthlyCashData(event){
        event.preventDefault();
      await this.MonthlyCashData();
    }
    
   async MonthlyCashData(){
        const initialDate = this.state.InitialDate
        const finalDate = this.state.FinalDate
        try{
            this.setState({
                mensajeTabla : 'Cargando . . .'
            });
            const monthlyCashResponse = await new MonthlyCashHelper().getConfirmedMonthlyPayments(localStorage.token,initialDate, finalDate);

            if(monthlyCashResponse.data.length === 0)
            {
                this.setState({
                    mensajeTabla : 'No hay datos para mostrar'
                });
            }
            else 
            {
                this.setState({
                    InitialDate :initialDate,
                    FinalDate : finalDate,
                    MonthlyCashData : monthlyCashResponse.data,
                    mensajeTabla : ''
                });
            }
            new NotificationsController().createNotification(monthlyCashResponse.data.message,monthlyCashResponse.status.toString())
        }catch(err){
            new NotificationsController().createNotification(err.response.data.message,err.response.status.toString() );
        }
    }

    displaymensaje(){
        if (this.state.mensajeTabla === "Cargando . . ."  ){
            return(<div className="spinner-border spinner-border-sm" role="status" />);
        }
        else{
           return( <h3>{this.state.mensajeTabla}</h3>);
        }
    }
    setView (){
        if( this.state.mensajeTabla.length > 0 )
        {return (
                    <div className="d-flex justify-content-center aling-items-center ">
                    {this.displaymensaje()}
                    </div>
               );}
        else
    return(<TableController  data = {this.state.MonthlyCashData} 
        Header = {"Busqueda de confirmados"} 
        columns = {
                    [
                        {
                            Header: 'Folio',
                            accessor: 'paymentCode',
                        },
                        {
                            Header: 'Nombre cliente',
                            accessor: 'clientFirstName',
                        },
                        {   
                            Header: 'Metodo de pago',
                            accessor: 'methodOfPayment'
                        },
                        {
                            Header: 'Apellido cliente',
                            accessor: 'clientLastName',
                        },
                        {
                            Header : 'Entrada',
                            accessor: 'enterType'
                        },
                        {
                            Header: 'Monto',
                            Footer : 'amount',
                            accessor: 'amount',
                        },
                        {
                            Header: 'Monto en letra',
                            accessor: 'amountWord',
                        },
                        {
                            Header: 'Descuento',
                            Footer : 'discount',
                            accessor: 'discount',
                        },
                        {
                            Header: 'Penalizacion',
                            Footer : 'penalty',
                            accessor: 'penalty',
                        },
                        {
                            Header: 'Pago recibido',
                            Footer : 'realAmount',
                            accessor: 'realAmount',
                        },
                        {
                            Header: 'Observaciones',
                            accessor: 'observations',
                        }] } 

                        ></TableController>
        );
    }

    render(){

        return (
            <main className = "content">
                 <div className="container-fluid p-0">
                     
                 <div className="card">
                 <div className="card-header">
            <h5 className="card-title">Bancos</h5>
          </div>
                 <div className="card-body">
                 <form >
                    <div className="form-row">
              
                    <div className="form-group col-md-3">
                        <label className="form-label">Fecha inicial </label>
                        <input name="InitialDate" type="date" className="form-control datetimepicker-input" value={this.state.InitialDate} onChange = {this.handleChange}/>        
                    </div> 
                    <div className="form-group col-md-3">
                        <label className="form-label">Fecha final</label>
                        <input name="FinalDate" type="date" className="form-control datetimepicker-input" value={this.state.FinalDate} onChange = {this.handleChange}/>
                    </div> 

                    <div className="form-group col-md-6">
                        <div className = "form-group">
                    <div className="vertical-center">
                    {/* <button  className="btn btn-primary" onClick={ this.searchMonthlyCashData}>Buscar</button> */}
                    <a className="btn btn-primary" onClick={ this.searchMonthlyCashData}>	
                    <i className="align-middle mr-2 fas fa-search" data-feather="sliders" />{' '}
                    <span className="align-middle">Buscar</span></a>
                    <ExcelDownloadButton MonthlyCashData = {this.state.MonthlyCashData}></ExcelDownloadButton>
                    </div>
                    </div> 
                    </div> 
                    </div> 
                </form>
              
                 </div> <div className="card-body">
    {this.setView()}
    </div> </div> 
                        
                              </div>
                              </main>
                               
            );
    }
}
export default monthlyCashReport

class ExcelDownloadButton extends React.Component {

    render() {
       
        return (

            <ExcelFile filename = "Corte de caja" element={
            <a className="btn btn-success">
            <i className="align-middle mr-2 fas fa-file-excel" data-feather="sliders" />{' '}
					<span className="align-middle">Descargar</span></a>}>


                <ExcelSheet data={this.props.MonthlyCashData} name="Confirmados">
                    <ExcelColumn label="Folio" value="paymentCode"/>
                    <ExcelColumn label="Nombre cliente" value="clientFirstName"/>
                    <ExcelColumn label="Apellido cliente" value="clientLastName"/>
                    <ExcelColumn label="Monto" value="amount"/>
                    <ExcelColumn label="Monto en letra" value="amountWord"/>
                    <ExcelColumn label="Descuento" value="discount"/>
                    <ExcelColumn label="Penalizacion" value="penalty"/>
                    <ExcelColumn label="Pago recibido" value="realAmount" />
                    <ExcelColumn label="Observaciones" value="observations"/>
                </ExcelSheet> 
            </ExcelFile>
        );
    }
}
