import React, { useRef } from 'react';
import DrawForm from './DrawForm';
import DrawPaymentList from './DrawPaymentList'
import DrawHelper from '../../helpers/draw/draw.request';
import ComponentToPrint from '../Reports/SavedLands/savedLandReport';
import NotificationsController from '../NotificationsController'
class editDraw extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            lands : [],
            vendors : [],
            clients : [],
            id : this.props.id,
            client : this.props.client, 
            land : this.props.land, 
            advance: this.props.advance, 
            vendor: this.props.vendor,
            creationDate : this.props.creationDate,
            observations : this.props.observations,
            processType : this.props.processType,
            formErrors: {
                land: '',
                client : '',
                vendor: ''
            },
            lookButtons : false,
            showEdit : false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClosePayments = this.handleClosePayments.bind(this);
    }

  
    async handleSubmit(){
        try{
            const body = {
                id : this.state.id,
                client : this.state.client,
                land : this.state.land,
                advance : this.state.advance,
                vendor : this.state.vendor,

            }; 
            await new DrawHelper().editDraw(body,localStorage.token);
            
        }
        catch(err){
            throw new Error(err.message);
        }
    }
    componentDidMount(){
 
        this.getInitialValues()
    }
    async handleClosePayments(){
        try{
  
            const body = {
                land : this.state.land
              }
        
              const response = await new DrawHelper().closeSavedLand(body, localStorage.token)
              this.setState({lookButtons : !response.data.success})
              new NotificationsController().createNotification( response.data.message, response.status.toString())
       
            
          }catch(error){
              new NotificationsController().createNotification(error.response.data.message, error.response.status.toString())	
          }
          
    }
    getInitialValues = async () =>
    {
        try {
            const {paid, processType} = this.props
            const landresponse = await new DrawHelper().getLandsEdit(localStorage.token);
            const vendorresponse = await new DrawHelper().getVendor(localStorage.token);
            const clientresponse = await new DrawHelper().getClients(localStorage.token);
        
           
            this.setState({
                lands : landresponse.data,
                vendors: vendorresponse.data,
                clients : clientresponse.data,
               
            });
            const landvalue = this.state.lands.filter(land => land.id === this.props.land);
           
            this.setState({
                totalPrice : landvalue[0]["totalPrice"],
                manzanaLote: 'Manzana: '.concat(landvalue[0]["block"],' / Lote: ',  landvalue[0]["lot"]),
                superficieM2 : landvalue[0]["surfaceM2"]
            })
         
            if ( paid === false && processType === "Estudio socioeconomico")
                    this.setState({lookButtons : true })
            
       
        }catch(error){
            console.error(error.message);
        }

    }

    setView(){

            return(
                <main className="content">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <DrawForm handleSubmit = {this.handleSubmit} handleChange = {this.handleChange}
                               
                                lands = {this.state.lands}
                                clients = {this.state.clients}
                                vendors = {this.state.vendors}
                                processType = { this.state.processType}
                                manzanaLote = {this.state.manzanaLote}
                                totalPrice = {this.state.totalPrice}
                                superficieM2 = {this.state.superficieM2}
                                
                                client = {this.state.client}
                                land = {this.state.land}
                                advance = {this.state.advance}
                                vendor = {this.state.vendor}
                                creationDate = {this.state.creationDate}
                                observations = {this.state.observations} 
                                showButton = {false}
                                formErrors = {this.state.formErrors}>
                                </DrawForm>
                             
                                <DrawPaymentList
                                 land = {this.state.land} 
                                 lookButtons = {this.state.lookButtons}
                                 processType = {this.props.processType}
                                 handleClosePayments = {this.handleClosePayments}
                                ></DrawPaymentList>
                                <ComponentToPrint SavedLandId = {this.state.id} processType =  { this.state.processType}/>
                            </div>
                            </div>
                
                         
                         
                    </div>
                </main>
            
                );
    }
    render(){
    return <div> { this.setView() } </div>
    }

}
export default editDraw;