import React, { Fragment } from 'react';
import ListUsers from './ListUsers';
class Landing extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			store: '',
			 collapse : true,
			 setAuth : false };
		this.clickClass = this.clickClass.bind(this);
		this.logout = this.logout.bind(this);
	}


	 clickClass()
	{
		const coll = this.state.collapse
		
		this.setState({
			collapse : !coll
		});

	}
	logout() {
		localStorage.removeItem('token');
		localStorage.removeItem('rol');
		this.setState({
			setAuth: false
		});
		window.location.reload();
	}
	setview()
	{
		return(
		<div className="sidebar-content js-simplebar">
		<a className="sidebar-brand" href="/">
			<i className="align-middle mr-2 fas fa-fw fa-map-marked" />
			<span className="align-middle mr-3">TM Mercantil</span>
		</a>
		{localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" ? 
		<ul className="sidebar-nav">
			<li className="sidebar-item">
				
				<a href="/" className="sidebar-link">
					<i className="align-middle mr-2 fas fa-fw fa-bars" data-feather="sliders" />{' '}
					<span className="align-middle">Pagos</span>
				</a>
				<ul
					id="dashboards"
					className="sidebar-dropdown list-unstyled collapse show "
					data-parent="#sidebar"
					>
						
					<li className="sidebar-item">
						<a className="sidebar-link" href="/Apartados">
							<i className="align-middle mr-2 fas fa-fw fa-handshake" />
							Apartado
						</a>
					</li>
					<li className="sidebar-item">
						<a className="sidebar-link" href="/Contratos">
							<i className="align-middle mr-2 fas fa-fw fa-shopping-cart" />
							Venta
						</a>
					</li>
					<li className="sidebar-item">
						<a className="sidebar-link" href="/Mensualidades">
							<i className="align-middle mr-2 fas fa-fw fa-money-check" />
							Mensualidad
						</a>
					</li>
				
					 <li className = "sidebar-item">
					<a className="sidebar-link" href="/Comisiones">
					<i className="align-middle mr-2 fas fa-hand-holding-usd" />
							Comisiones
						</a>
					 </li>
					 {localStorage.rol === "Supervisor" ?
						<li className = "sidebar-item">
							<a className="sidebar-link" href="/Conciliacion">
							<i className="align-middle mr-2 fas fa-fw fa-check-square" />
									Conciliacion</a>
						</li> : null}

						<li className = "sidebar-item">
							<a className="sidebar-link" href="/CobrosExtra">
							<i className="align-middle mr-2 fas fa-file-invoice" />
									Cobros extra</a>
						</li> 
						<li className = "sidebar-item">
							<a className="sidebar-link" href="/Cancelaciones">
							<i className="align-middle mr-2 fas fa-trash" />
								Cancelaciones</a>
						</li> 
						<li className = "sidebar-item">
							<a className="sidebar-link" href="/TransferenciaTerreno">
								<i className= "align-middle mr-2 fas fa-exchange-alt" />
								Transferencia
							</a>
						</li>
						{/* <i class=""></i> */}
				</ul>
			</li>
		</ul>
		: null}

		{localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" ? 
		<ul className="sidebar-nav">
			<li className="sidebar-item">
				<a href="/" className="sidebar-link">
					<i className="align-middle mr-2 fas fa-fw fa-bars" data-feather="sliders" />{' '}
					<span className="align-middle">Catalogos</span>
				</a>
				<ul
					id="dashboards"
					className="sidebar-dropdown list-unstyled collapse show "
					data-parent="#sidebar"
					>
					<li className="sidebar-item">
						<a className="sidebar-link" href="/Clientes">
							<i className="align-middle mr-2 fas fa-fw fa-coins" />
							Clientes
						</a>
					</li>
					<li className="sidebar-item">
						<a className="sidebar-link" href="/Terrenos">
							<i className="align-middle mr-2 fas fa-fw fa-sign" />
							Terrenos
						</a>
					</li>
					<li className="sidebar-item">
						<a className="sidebar-link" href="/Vendedores">
							<i className="align-middle mr-2 fas fa-fw fa-university" />
							Vendedores
						</a>
					</li>

					<li className="sidebar-item">
						<a className="sidebar-link" href="Usuarios">
							<i className="align-middle mr-2 fas fa-fw fa-users" />
							Usuarios
						</a>
					</li>

				</ul>
			</li>
		</ul>
		:null}
	{localStorage.rol === "Supervisor" || localStorage.rol === "Capturista" || localStorage.rol === "Reporteador" ? 
		<ul className="sidebar-nav">
			<li className="sidebar-item">
				
				<a href="/" className="sidebar-link">
					<i className="align-middle mr-2 fas fa-fw fa-bars" data-feather="sliders" />{' '}
					<span className="align-middle">Reportes</span>
				</a>
				<ul
					id="dashboards"
					className="sidebar-dropdown list-unstyled collapse show "
					data-parent="#sidebar"
					>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/ReporteMensualidades">
							<i className="align-middle mr-2 fas fa-fw fa-handshake" />
								Corte de caja
							</a>
						</li>
					<li className="sidebar-item">
						<a className="sidebar-link" href="/ReporteCorteDeCaja">
							<i className="align-middle mr-2 fas fa-fw fa-donate" />
								Bancos
						</a>
					</li>
					<li className="sidebar-item">
						<a className="sidebar-link" href="/ReporteCobrosVencidos">
							<i className="align-middle mr-2 fas fa-fw fa-shopping-cart" />
							Cobros vencidos
						</a>
					</li>
					<li className="sidebar-item">
						<a className="sidebar-link" href="/ReporteInventarioDeTerrenos">
							<i className="align-middle mr-2 fas fa-fw fa-money-check" />
							Inventario de terrenos
						</a>
					</li>
				
					 <li className = "sidebar-item">
					<a className="sidebar-link" href="/ReporteComisionesConfirmadas">
					<i className="align-middle mr-2 fas fa-hand-holding-usd" />
							Comisiones confirmadas
						</a>
					 </li>
					 
					 <li className = "sidebar-item">
					<a className="sidebar-link" href="/ReporteResiduoDePago">
					<i className="align-middle mr-2 fas fa-hand-holding-usd" />
							Residuo de pagos
						</a>
					 </li>
				</ul>
			</li>
		</ul>
			: null}
			<ul className="sidebar-nav">
			<li className="sidebar-item">
				<a href="/" className="sidebar-link">
					<i className="align-middle mr-2 fas fa-fw fa-bars" data-feather="sliders" />{' '}
					<span className="align-middle">Sesion</span>
				</a>
				<ul
					id="dashboards"
					className="sidebar-dropdown list-unstyled collapse show "
					data-parent="#sidebar"
					>
					<li className="sidebar-item">
						<a className="sidebar-link" onClick={this.logout}>
							<i className="align-middle mr-2 fas fa-fw fa-power-off" />
							Cerrar sesión
						</a>
					</li>
				</ul>
			</li>
		</ul>
	</div>
	);
	}

	render() {

		if (this.state.collapse === true){

			return (
			
				     <nav id="sidebar" className="sidebar">
						{this.setview()}
					</nav>
			
		);
	}
	else{
		return(
		
				 <nav id="sidebar" className="sidebar collapse">
					{this.setview()}
				 </nav>
		
	);
	}
	}
}

export default Landing;
