import React from 'react'
import SaleContractHelper from '../../helpers/saleContract/saleContract.request'
import EditSaleContact from './editSaleContract'
import CancelHelper from '../../helpers/cancellation/cancel.request'
import NotificationsController from '../NotificationsController'
import CristianModal from '../CristianModal';
import AuthenticateHelper from '../../helpers/authenticate/authenticate.request';
import ConfirmModel from '../ConfirmModal';
import TableController from '../reports.ReactTableController'
class ListSaleContract extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            saleContracts : [],
            showEdit : false,
            mensajeTabla : '',
            showModal :  false,
            showConfirmModal : false,

            email: '',
            password: '' 
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
        this.updateCancellation = this.updateCancellation.bind(this);
        this.confirmOperation = this.confirmOperation.bind(this);
        this.hideConfirmation = this.hideConfirmation.bind(this);
    }

    componentDidMount(){
        this.getSaleContracts();
    }
    
    async handleDelete(id){
        try{
            this.setState({
                saleContracts : this.state.saleContracts.filter(salecontract => salecontract.id !== id)
            });
    
        await new SaleContractHelper().deleteSaleContact(id, localStorage.token)
        }
        catch(error)
        {
            new NotificationsController().createNotification(error.message, "400")
        }
    }
    async handleSignIn(){
        try {
            const {land, client} = this.state;
            const body = {
                email: this.state.email,
                password: this.state.password
            };
            
            const response = await new AuthenticateHelper().loginUser(body);
   
            if (response.data && response.data.token) {
                
               if (response.data.rol === "Supervisor") 
               {
                this.handleCancel(land, client);
               }
               else
               {
                    new NotificationsController().createNotification("Son necesarias las credenciales del supervisor.", '401');
               };
            }
        }
        catch(err){
              new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	;	
        }
    }
    async hideConfirmation(){
        this.setState({
            showConfirmModal : false
        });
    }
    async confirmOperation(land, client){
       
        this.setState({
            showConfirmModal : true,
            land,
            client
        });
       
    }
    
    async updateCancellation(){
        // event.preventDefault();  
        try {
       const {land, client } = this.state;
       if(localStorage.rol !== "Supervisor"){
           this.setState({
               showConfirmModal : false,
               showModal : true
           });
       }
       else 
       {
           
           this.handleCancel(land, client);
       }
    
    }catch(err){
       
        new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	    
    }
    }
    async handleChange(event) {
        event.preventDefault();
        this.setState({
            [event.target.name]: event.target.value
          });
      }



      
    async handleCancel(land, client){
        try{
            const response = await new CancelHelper().landCancellation(land, client, localStorage.token);
            this.setState({
                showModal: false,
                showConfirmModal : false,
                saleContracts : this.state.saleContracts.filter(saleContract => saleContract.land !== land )
            })
            new NotificationsController().createNotification(response.data.message, response.data.status.toString())

        }catch(error){
        
        }
    }
   async handleEdit(salecontract){
       
        const ContractResponse =  await new SaleContractHelper().getSaleContract(localStorage.token,salecontract.land);
        const Reciptresponse = await new SaleContractHelper().getContractRecipts(localStorage.token, salecontract.land);

        const contractinfo = ContractResponse.data
        const ContractRecipts = Reciptresponse.data

        this.setState({
            showEdit : true,
            landInfo : contractinfo,
            ContractRecipts : ContractRecipts,
            client : salecontract.client,
            land : salecontract.land,
            vendor : salecontract.vendor,
            idContract : salecontract.id,
            numberOfMonths : salecontract.numberOfmonths,
            monthlyPayment : salecontract.monthlyPayment,
            currentCost : salecontract.currentCost,
            creationDate : salecontract.creationDate, 
            contractNumber : salecontract.contractNumber,
            paymenthMethod : salecontract.paymenthMethod,
            
        });

        this.setView();
    
    }
    async hideModal(){
        this.setState({
            showModal : false
        });
    }
    getSaleContracts = async () => {
        try {
            this.setState({
                mensajeTabla : 'Cargando . . .'
            });
            const response = await new SaleContractHelper().getSaleContracts(localStorage.token);
            if (response.data.length ===0) {
                this.setState({
                    mensajeTabla : 'No hay datos para mostrar'
                })
            }
            else {
            this.setState({
                saleContracts : response.data,
                mensajeTabla : ''
            });}
            new NotificationsController().createNotification("Registro encontrados", "201");
            
        }
    catch(error)
    {
        new NotificationsController().createNotification(error.message, "400")
    }
}   
displaymensaje(){
    if (this.state.mensajeTabla === "Cargando . . ."  ){
        return(<div className="spinner-border spinner-border-sm" role="status" />);
    }
    else{
       return( <h3>{this.state.mensajeTabla}</h3>);
    }
}
setTable(){
    if( this.state.mensajeTabla.length > 0 )
    {return (
                <div className="d-flex justify-content-center aling-items-center ">
                {this.displaymensaje()}
                </div>
           );
    }else{
        return(
            <TableController 
            data = {this.state.saleContracts}
            Header = {"Contratos"}
            columns = {
              [
                  {
                      Header : 'Terreno',
                      accessor: 'Lands.code'
                  },
                  {
                      Header : 'Manzana',
                      accessor : 'Lands.block'
                  },
                  {
                    Header : 'Lote',
                    accessor : 'Lands.lot'
                  },
                  {
                    Header : 'Cliente nombre',
                    accessor : 'Clients.firstName'
                  },
                  {
                    Header : 'Cliente apellido',
                    accessor : 'Clients.lastName'
                  },
                //   {
                //     Header : 'Vendedor nombre',
                //     accessor : 'Vendors.firstName'
                //   },
 
                  {
                    Header : 'Numero de meses',
                    accessor : 'numberOfmonths'
                  },    
                  {
                    Header : 'Pago mensual',
                    accessor : 'monthlyPayment'
                  },
                  {
                    Header : 'Costo actual',
                    accessor : 'currentCost'
                  },
                  {
                    Header : 'Contrato',
                    accessor : 'contractNumber'
                  },
                  {
                      Header : 'Ver',
                      accessor: '[button1]',
                      Cell: ({cell}) => (
                      <button className="btn btn-primary btn-sm" 
                      onClick={() =>  this.handleEdit(cell.row.original)}>    Recibos de venta
                      </button>
                          )
                  },
                  {
                    Header : 'Cancelar',
                    accessor: '[button2]',
                    Cell: ({cell}) => (
                    <button className="btn btn-danger btn-sm" 
                    onClick={() =>  this.confirmOperation(cell.row.original.land,cell.row.original.client )}>Cancelar
                    </button>
                        )
                  }
                  
            
                ]}/>
                  
    //          <table className="table table-sm">
    //     <thead>
    //         <tr>
    //             <th>Terreno</th>
    //             <th>Cliente</th>
    //             <th>Vendedor</th>
    //             <th>Numero de meses</th>
    //             <th>Pago mensual</th>
    //             <th>Costo actual</th>
    //             <th>Fecha de alta</th>
    //             <th>Contrato</th>
    //             {/* <th>Metodo de pago</th> */}
    //             <th>Accion</th>
            
    //         </tr>
    //     </thead>
    //     <tbody> 
    //         {this.state.saleContracts.map((saleContract) => (
    //             <tr key={saleContract.id}>
    //                 <td>{saleContract.Lands.code}</td>
    //                 <td>{ ''.concat(saleContract.Clients.firstName,' ',saleContract.Clients.lastName)}</td>
    //                 <td>{''.concat(saleContract.Vendors.firstName, ' ',saleContract.Vendors.lastName )}</td>                                 
    //                 <td>{saleContract.numberOfmonths}</td>
    //                 <td>{saleContract.monthlyPayment}</td>
    //                 <td>{saleContract.currentCost}</td>
    //                 <td>{saleContract.creationDate.substring(0,10)}</td>
    //                 <td>{saleContract.contractNumber}</td>
    //                 <td>
    //                    <button
    //                         type="submit"
    //                         className="btn btn-primary btn-sm"
    //                         onClick={() => this.handleEdit(saleContract)
    //                         }
    //                     >
    //                         Recibos de venta
    //                     </button>
                        
    //                    <button
    //                         type="submit"
    //                         className="btn btn-danger btn-sm"
    //                         onClick={(e)=> this.confirmOperation(saleContract.land, saleContract.client)} >
    //                              Cancelar 
    //                         </button>
    //                 </td>
    //             </tr>
                       
    //         ))}
    //     </tbody> 
    // </table>
    );
    }
}

setView(){
            if (this.state.showEdit)
            {
                return( 
                <div>
                    <EditSaleContact 
                        idContract = {this.state.idContract}
                        landInfo = {this.state.landInfo}
                        monthlyPayment = {this.state.monthlyPayment}
                        creationDate = {this.state.creationDate}
                        contractNumber = {this.state.contractNumber}
                        numberOfMonths = {this.state.numberOfMonths}
                        paymenthMethod = {this.state.paymenthMethod} 
                        ContractRecipts = {this.state.ContractRecipts}
                  >
                        
                 </EditSaleContact></div>);
            } 
            else 
            {
                return(	<main className="content">
			
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                        <CristianModal showModal={this.state.showModal} handleSubmit = {this.handleSignIn} hideModal={this.hideModal} handleChange = {this.handleChange} />
                        < ConfirmModel showModal={this.state.showConfirmModal}  handleSubmit = {this.updateCancellation} hideModal={this.hideConfirmation} /> 
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Lista de ventas</h5>
                                    <h6 className="card-subtitle text-muted">
                                        En el siguiente link puede agregar ventas nuevas{' '}
                                        <a href="/agregarContrato" rel="noopener noreferrer nofollow">
                                            Aquí
                                        </a>
                                    </h6>
                                </div>
                                <div className="card-body">
                                <div className="table-responsive text-nowrap">
                                   {this.setTable()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                </main>
                );
            }
    }
    render() {
        return <div>{this.setView()}</div>
    }
}
export default ListSaleContract;