import React from 'react';
import LandForm from './landForm';
import LandHelper from '../../helpers/land/land.request';
import NotificationsController from '../NotificationsController';

class editLand extends React.Component {
    constructor(props){
        super(props);
        this.state = {
             id : this.props.id
            ,code :  this.props.code
            ,block : this.props.block 
            ,lot: this.props.lot
            ,height : this.props.height
            ,width : this.props.width 
            ,surfaceM2 : this.props.surfaceM2 
            ,totalPrice : this.props.totalPrice
            ,pricePerM2: this.props.pricePerM2 
            ,status: this.props.status
      
            ,formErrors : {
				code : '',
				block:'',
				lot: '',
				height:'',
				width: '',
				pricePerM2: ''
			}
        }
        
        this.handleSubmit = this.handleSubmit.bind(this);
      
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(){
         this.setState({previousStatus : this.props.status})
    }


    async handleChange(event){
        const status = ["Apartado", "Vendido", "Pagado"]
 
        if( status.includes(this.state.previousStatus)){
			new NotificationsController().createNotification("No se puede cambiar un terreno en operacion", "400");
            return
        }
        this.setState({
            [event.target.name] : event.target.value    
        });
    }
    async handleSubmit(){
        try{

        const body = {
            id :this.state.id
            ,code :  this.state.code
            ,block : this.state.block 
            ,height : this.state.height
            ,width : this.state.width 
            ,surfaceM2: this.state.surfaceM2
            ,totalPrice: this.state.pricePerM2 * (this.state.surfaceM2)
            ,pricePerM2: this.state.pricePerM2 
            ,status: this.state.status
            ,lot: this.state.lot
        };
        await new LandHelper().editLand(body, localStorage.token);
    }
    catch(err){
        throw new Error(err.message);
    }
    }
    render(){
        return(
            <LandForm handleSubmit ={this.handleSubmit} handleChange = {this.handleChange}
            code =  {this.state.code}
            block = {this.state.block} 
            height = {this.state.height}
            width = {this.state.width} 
            surfaceM2 = {this.state.surfaceM2} 
            totalPrice = {this.state.totalPrice}
            pricePerM2 = {this.state.pricePerM2} 
            status= {this.state.status}
            lot= {this.state.lot}
            formErrors = {this.state.formErrors}
            ></LandForm>);
    }
} export default editLand;