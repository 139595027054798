import React from 'react';
import SaleContractHelper from '../../helpers/saleContract/saleContract.request'
import SaleContractForm from './SaleContractForm'
import EditSaleContact from './editSaleContract'
import NotificationsController from '../NotificationsController'

class addSaleContract extends React.Component{
    constructor(props){
        super(props);

        var dateObj = new Date();
        var dateString = ''.concat(dateObj.getFullYear().toString(),'-',String(dateObj.getMonth() + 1).padStart(2, '0'), '-',String(dateObj.getDate()).padStart(2, '0'));
       
        this.state = {
            lands: [],
            landId: '',
            land : '',
            contractNumber : '' ,
            creationDate : dateString,
            paymentMethod :'Pago en parcialidades o diferido',
            monthlyPayment : 0,
            
            vendor :   '',                           
            client : '',
            BlockAndLot : '',
            SurfaceM2 : 0,
            clientId : '',
            vendorId : '',
            
            LandTotalCost : 0,
            Advance : 0,
            currentCost : 0,
            numberOfMonths : 0,
            landInfo : []

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleLand = this.handleLand.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNumberOfMonths = this.handleNumberOfMonths.bind(this);
    }

        componentDidMount(){
            this.getInitialValues();
        } 
       
        getInitialValues = async () => {
            try{
             
            const landResponse = await new SaleContractHelper().getLandInformationSupport(localStorage.token);
            this.setState({
                lands : landResponse.data
            });
            
            }catch(error){
                console.error(error.message);
            }
        }

    async handleSubmit(event){
    	event.preventDefault();
        try {
            
            const body ={ 
                land : this.state.landId, 
                client : this.state.clientId,
                vendor : this.state.vendorId,
                numberOfmonths : this.state.numberOfMonths,
                monthlyPayment : this.state.monthlyPayment,
                paymenthMethod : this.state.paymentMethod,
                contractNumber : this.state.contractNumber,
                creationDate: this.state.creationDate
            } ;
                
                const response =  await new SaleContractHelper().addSaleContract(body, localStorage.token);
                this.setState({
                    showEdit : true,
                    idContract : response.data.idContract})
             
                new NotificationsController().createNotification(response.data.receipts.message, response.status.toString());
			}
		
		 catch (err) {
			new NotificationsController().createNotification(err.response.data.message,err.response.status.toString())	
		}
	}


        async handleChange(event) {
            this.setState({
               [event.target.name]: event.target.value
             });
            
          }

          async handleNumberOfMonths(event){
            this.handleChange(event);
            this.setState({
                numberOfMonths : Math.ceil(this.state.currentCost / event.target.value )
            });
          }   


        async handleLand(event){
    
            this.handleChange(event);
          
            const value = event.target.value;
            const landvalue = this.state.lands.filter(land => land.id === value);
          
            this.setState({
                landInfo : landvalue,
                landId : landvalue[0].land,
                client : ''.concat(landvalue[0].Clients[0].firstName , ' ' , landvalue[0].Clients[0].lastName),
                vendor : ''.concat(landvalue[0].Vendors[0].firstName , ' ', landvalue[0].Vendors[0].lastName),
                BlockAndLot : 'Manzana: '.concat(landvalue[0].Lands[0]["block"],' / Lote: ',  landvalue[0].Lands[0]["lot"]),
                SurfaceM2 : landvalue[0].Lands[0]["surfaceM2"],
                LandTotalCost : landvalue[0].Lands[0]["totalPrice"],
                Advance : landvalue[0]["advance"],
                currentCost :landvalue[0].Lands[0]["totalPrice"] - landvalue[0]["advance"],
                numberOfMonths : 0,
                clientId : landvalue[0].client,
                vendorId : landvalue[0].vendor
            });
          
        }

        
setView(){
    if (this.state.showEdit)
    {
        return(      
        <EditSaleContact
            idContract = {this.state.idContract}
            landInfo = {this.state.landInfo}
            monthlyPayment = {this.state.monthlyPayment}
            creationDate = {this.state.creationDate}
            contractNumber = {this.state.contractNumber}
            numberOfMonths = {this.state.numberOfMonths}
            paymenthMethod = {this.state.paymenthMethod} 
            ContractRecipts = {this.state.ContractRecipts}>
             </EditSaleContact>     );
     
    }
        else 
        {
            return(
                <main className="content">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-md-12">
                                <SaleContractForm handleSubmit = {this.handleSubmit} handleChange = {this.handleChange}  handleNumberOfMonths = {this.handleNumberOfMonths}
                                handleLand = {this.handleLand} 
                                
                                lands ={this.state.lands} 
                            
                                land = {this.state.land}
                                contractNumber = {this.state.contractNumber}
                                creationDate = {this.state.creationDate} 
                                paymentMethod = {this.state.paymentMethod}
                                monthlyPayment ={this.state.monthlyPayment}
                                
                                vendor =  {this.state.vendor}                           
                                client = {this.state.client}
                                BlockAndLot = {this.state.BlockAndLot}
                                SurfaceM2 = {this.state.SurfaceM2}
    
                                LandTotalCost = {this.state.LandTotalCost}
                                Advance = {this.state.Advance}
                                currentCost = {this.state.currentCost}
                                numberOfMonths = {this.state.numberOfMonths}
                                showButton = {true}
                                >
    
                                </SaleContractForm>
                            </div>
                    </div>
                </div>
                </main>
            );    
        }
}
        render(){
        return(
           <div> {this.setView()}</div>
        );    
    }
}
export default addSaleContract;