import React from 'react';
import {NotificationManager} from 'react-notifications';
 
class NotificationsController extends React.Component{

  createNotification = ( message, status) => {
       
     var realStatus = status.substring(0, 1); 

      switch (realStatus) {
        case "1" :{
          NotificationManager.info(message);
          break;
        }
        case "2": {
          NotificationManager.success(message, 'Respuesta satisfactoria');
          break;
        }
        case  "3" : {

          NotificationManager.warning(message, 'Redirecciones', 15000);
          break;
        }
         case  "4" :
         {
           NotificationManager.error(message, 'Respuesta del cliente', 15000, () => {alert('callback'); });
           break;
          }
         case  "5" :
         {
           NotificationManager.error(message, 'Respuesta del servidor', 15000, () => {alert('callback'); });
           break;
          } 
          default:
            NotificationManager.info(message);
            break;
      }
  };
 
}
 
export default NotificationsController;